import { v4 } from "uuid";

import { getCookie } from "util/cookie";
import {
  MARKETING_SESSION_ID_COOKIE_KEY,
  MARKETING_SESSION_DATA_COOKIE_KEY,
} from "util/marketing_session";
import { getDeviceId } from "util/device";
import Env from "config/environment";
import { readMarketingFlowType } from "common/analytics/signer_flow/util";

import { isMobileDevice } from "./support";
import { browserReport as browserReportImported } from "./browser_report";

type BrowserReport = {
  browser: {
    name: null | string;
    version: null | string;
  };
  os: {
    name: null | string;
    version: null | string;
  };
  userAgent: string;
  marketingSession: {
    id: null | undefined | string;
    data: null | undefined | string;
  };
  // TODO: GRW-792 remove transaction_type. See comment in common/analytics/signer_flow
  flow_type: null | undefined | string;
  transaction_type: null | undefined | string;
};

export type MarketingSessionData = {
  uca?: null | string;
  uco?: null | string;
  ui?: null | string;
  um?: null | string;
  usrc?: null | string;
  ut?: null | string;
};
type UtmKey = keyof MarketingSessionData;
type UtmParameter = "content" | "id" | "medium" | "name" | "source" | "term";

// We create a "application instance" or tab ID for logs so we can see which browser tabs generated events
const BROWSER_TAB_ID = v4();
const { releaseName } = Env;

export function browserReport(): BrowserReport {
  const { userAgent } = window.navigator;
  const report: BrowserReport = {
    browser: {
      name: null,
      version: null,
    },
    os: {
      name: null,
      version: null,
    },
    userAgent,
    marketingSession: {
      id: null,
      data: null,
    },
    // TODO: GRW-792 remove transaction_type. See comment in common/analytics/signer_flow
    flow_type: null,
    transaction_type: null,
  };

  // extract browser name from user agent
  if (userAgent.includes("Trident") || userAgent.includes("MSIE")) {
    if (userAgent.includes("Mobile")) {
      report.browser.name = "IE Mobile";
    } else {
      report.browser.name = "Internet Explorer";
    }
  }

  if (userAgent.includes("Firefox") && !userAgent.includes("Seamonkey")) {
    if (userAgent.includes("Android")) {
      report.browser.name = "Firefox for Android";
    } else {
      report.browser.name = "Firefox";
    }
  }

  if (
    userAgent.includes("Safari") &&
    !userAgent.includes("Chrome") &&
    !userAgent.includes("Chromium") &&
    !userAgent.includes("Android")
  ) {
    if (userAgent.includes("CriOS")) {
      report.browser.name = "Chrome for iOS";
    } else if (userAgent.includes("FxiOS")) {
      report.browser.name = "Firefox for iOS";
    } else {
      report.browser.name = "Safari";
    }
  }

  if (userAgent.includes("Chrome")) {
    if (userAgent.match(/\bChrome\/[.0-9]* Mobile\b/)) {
      if (userAgent.match(/\bVersion\/\d+\.\d+\b/) || userAgent.match(/\bwv\b/)) {
        report.browser.name = "WebView on Android";
      } else {
        report.browser.name = "Chrome for Android";
      }
    } else {
      report.browser.name = "Chrome";
    }
  }

  if (
    userAgent.includes("Android") &&
    !userAgent.includes("Chrome") &&
    !userAgent.includes("Chromium") &&
    !userAgent.includes("Trident") &&
    !userAgent.includes("Firefox")
  ) {
    report.browser.name = "Android Browser";
  }

  if (userAgent.includes("Edge")) {
    report.browser.name = "Edge";
  }

  if (userAgent.includes("UCBrowser")) {
    report.browser.name = "UC Browser for Android";
  }

  if (userAgent.includes("SamsungBrowser")) {
    report.browser.name = "Samsung Internet";
  }

  if (userAgent.includes("OPR") || userAgent.includes("Opera")) {
    if (userAgent.includes("Opera Mini")) {
      report.browser.name = "Opera Mini";
    } else if (
      userAgent.includes("Opera Mobi") ||
      userAgent.includes("Opera Tablet") ||
      userAgent.includes("Mobile")
    ) {
      report.browser.name = "Opera Mobile";
    } else {
      report.browser.name = "Opera";
    }
  }

  if (
    userAgent.includes("BB10") ||
    userAgent.includes("PlayBook") ||
    userAgent.includes("BlackBerry")
  ) {
    report.browser.name = "BlackBerry";
  }

  // extract browser version number from user agent
  let match: null | (undefined | string)[] = null;

  switch (report.browser.name) {
    case "Chrome":
    case "Chrome for Android":
    case "WebView on Android":
      match = userAgent.match(/Chrome\/((\d+\.)+\d+)/);
      break;
    case "Firefox":
    case "Firefox for Android":
      match = userAgent.match(/Firefox\/((\d+\.)+\d+)/);
      break;
    case "Firefox for iOS":
      match = userAgent.match(/FxiOS\/((\d+\.)+\d+)/);
      break;
    case "Edge":
    case "Internet Explorer":
    case "IE Mobile":
      if (userAgent.includes("Edge")) {
        match = userAgent.match(/Edge\/((\d+\.)+\d+)/);
      } else if (userAgent.includes("rv:11")) {
        match = userAgent.match(/rv:((\d+\.)+\d+)/);
      } else if (userAgent.includes("MSIE")) {
        match = userAgent.match(/MSIE ((\d+\.)+\d+)/);
      }

      break;
    case "Safari":
    case "Android Browser":
      match = userAgent.match(/Version\/((\d+\.)+\d+)/);
      break;
    case "UC Browser for Android":
      match = userAgent.match(/UCBrowser\/((\d+\.)+\d+)/);
      break;
    case "Samsung Internet":
      match = userAgent.match(/SamsungBrowser\/((\d+\.)+\d+)/);
      break;
    case "Opera Mini":
      match = userAgent.match(/Opera Mini\/((\d+\.)+\d+)/);
      break;
    case "Opera":
      if (userAgent.match(/OPR/)) {
        match = userAgent.match(/OPR\/((\d+\.)+\d+)/);
      } else if (userAgent.match(/Version/)) {
        match = userAgent.match(/Version\/((\d+\.)+\d+)/);
      } else {
        match = userAgent.match(/Opera\/((\d+\.)+\d+)/);
      }
      break;
    case "BlackBerry":
      match = userAgent.match(/Version\/((\d+\.)+\d+)/);
      break;
    default:
      match = userAgent.match(/\/((\d+\.)+\d+)$/);
      break;
  }

  if (match?.[1]) {
    report.browser.version = match[1];
  }

  // extract operating system name from user agent
  if (userAgent.includes("Windows")) {
    if (userAgent.includes("Windows Phone")) {
      report.os.name = "Windows Phone";
    } else {
      report.os.name = "Windows";
    }
  }

  if (userAgent.includes("OS X") && !userAgent.includes("Android")) {
    report.os.name = "OS X";
  }

  if (userAgent.includes("Linux")) {
    report.os.name = "Linux";
  }

  if (userAgent.includes("like Mac OS X")) {
    report.os.name = "iOS";
  }

  if (
    (userAgent.includes("Android") || userAgent.includes("Adr")) &&
    !userAgent.includes("Windows Phone")
  ) {
    report.os.name = "Android";
  }

  if (userAgent.includes("BB10")) {
    report.os.name = "BlackBerry";
  }

  if (userAgent.includes("RIM Tablet OS")) {
    report.os.name = "BlackBerry Tablet OS";
  }

  if (userAgent.includes("BlackBerry")) {
    report.os.name = "BlackBerryOS";
  }

  // extract operating system version from user agent
  match = null;

  switch (report.os.name) {
    case "Windows":
    case "Windows Phone":
      if (userAgent.includes("Win16")) {
        report.os.version = "3.1.1";
      } else if (userAgent.includes("Windows CE")) {
        report.os.version = "CE";
      } else if (userAgent.includes("Windows 95")) {
        report.os.version = "95";
      } else if (userAgent.includes("Windows 98")) {
        if (userAgent.includes("Windows 98; Win 9x 4.90")) {
          report.os.version = "Millennium Edition";
        } else {
          report.os.version = "98";
        }
      } else {
        match = userAgent.match(
          /Win(?:dows)?(?: Phone)?[ _]?(?:(?:NT|9x) )?((?:(\d+\.)*\d+)|XP|ME|CE)\b/,
        );

        if (match?.[1]) {
          switch (match[1]) {
            case "6.4":
              match[1] = "10.0";
              break;
            case "6.3":
              match[1] = "8.1";
              break;
            case "6.2":
              match[1] = "8";
              break;
            case "6.1":
              match[1] = "7";
              break;
            case "6.0":
              match[1] = "Vista";
              break;
            case "5.2":
              match[1] = "Server 2003";
              break;
            case "5.1":
              match[1] = "XP";
              break;
            case "5.01":
              match[1] = "2000 SP1";
              break;
            case "5.0":
              match[1] = "2000";
              break;
            case "4.0":
              match[1] = "4.0";
              break;
            default:
              // nothing
              break;
          }
        }
      }
      break;
    case "OS X":
      match = userAgent.match(/OS X ((\d+[._])+\d+)\b/);
      break;
    case "Linux":
      // linux user agent strings do not usually include the version
      report.os.version = null;
      break;
    case "iOS":
      match = userAgent.match(/OS ((\d+[._])+\d+) like Mac OS X/);
      break;
    case "Android":
      match = userAgent.match(/(?:Android|Adr) ((\d+[._])+\d+)/);
      break;
    case "BlackBerry":
    case "BlackBerryOS":
      match = userAgent.match(/Version\/((\d+\.)+\d+)/);
      break;
    case "BlackBerry Tablet OS":
      match = userAgent.match(/RIM Tablet OS ((\d+\.)+\d+)/);
      break;
    default:
      // no good default behavior
      report.os.version = null;
      break;
  }

  if (match?.[1]) {
    // replace underscores in version number with periods
    report.os.version = match[1].replace(/_/g, ".");
  }

  const flowType = readMarketingFlowType();
  // TODO: GRW-792 remove transaction_type
  report.flow_type = flowType;
  report.transaction_type = flowType;

  // marketing session id and data
  report.marketingSession.id = getCookie(MARKETING_SESSION_ID_COOKIE_KEY);
  report.marketingSession.data = getCookie(MARKETING_SESSION_DATA_COOKIE_KEY);

  return report;
}

/** For request headers */
export function browserReportHeaders(): Record<string, string | null | undefined> {
  const { os, marketingSession } = browserReport();
  return {
    "X-Notarize-Device-ID": getDeviceId(),
    "X-Notarize-OS": os.name,
    "X-Notarize-OSVersion": os.version,
    "X-Notarize-Marketing-Session-ID": marketingSession.id,
    "X-Notarize-Marketing-Session-Data": marketingSession.data,
    "X-Notarize-Client-Version": releaseName,
    "X-Notarize-Platform": isMobileDevice() ? "MOBILE_WEB" : "WEB",
  };
}

export function browserReportProperties() {
  // TODO: GRW-792 remove transaction_type
  const { browser, os, marketingSession, flow_type, transaction_type } = browserReport();
  return {
    browser: browser.name,
    browserVersion: browser.version,
    browserTabId: BROWSER_TAB_ID,
    deviceID: getDeviceId(),
    os: os.name,
    osVersion: os.version,
    marketingSessionID: marketingSession.id,
    marketingSessionData: marketingSession.data,
    flow_type,
    transaction_type,
    version: releaseName,
  };
}

// The Marketing session data cookie contains more than just the UTM parameters,
// so we want to filter non-UTM and blank values out
function formatSessionData(data: MarketingSessionData) {
  const utmMap: Record<UtmKey, UtmParameter> = {
    uco: "content",
    ui: "id",
    um: "medium",
    uca: "name",
    usrc: "source",
    ut: "term",
  };

  const campaign = {} as Record<UtmParameter, string>;
  Object.keys(utmMap).forEach((mapKey) => {
    const key = mapKey as UtmKey;
    if (data[key]) {
      campaign[utmMap[key]] = data[key];
    }
  });

  if (Object.keys(campaign).length) {
    return { campaign };
  }

  return {};
}

export function browserReportContext() {
  const { marketingSession } = browserReportImported();
  let marketingSessionData: MarketingSessionData = {};

  try {
    marketingSessionData = JSON.parse(marketingSession.data || "{}") || {};
  } catch {
    marketingSessionData = {};
  }

  return formatSessionData(marketingSessionData);
}
