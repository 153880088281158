import { FormattedMessage } from "react-intl";
import type { ReactNode } from "react";

import AlertMessage from "common/core/alert_message";
import Button from "common/core/button";
import Link from "common/core/link";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import { MERGE_DOCUMENTS_LEARN_MORE_SUPPORT_URL } from "constants/support";

import Styles from "./index.module.scss";

type MergeDocumentsModalProps = {
  firstDocumentName: string;
  topSelectedDocumentId: string;
  onClose: () => void;
  handleSubmit: () => void;
};

const MergeDocumentsModal = ({
  onClose,
  handleSubmit,
  firstDocumentName,
}: MergeDocumentsModalProps) => {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="c63996a1-120a-4390-9997-85be7313f5c5"
          defaultMessage="Confirm document merge"
        />
      }
      buttons={[
        <Button
          buttonColor="action"
          variant="tertiary"
          key="merge-documents-cancel"
          automationId="merge-documents-cancel"
          onClick={onClose}
        >
          <FormattedMessage id="2393f49e-3593-4cbf-b445-a8f596239e58" defaultMessage="Cancel" />
        </Button>,
        <Button
          buttonColor="action"
          variant="primary"
          key="cmerge-documents-submit"
          automationId="merge-documents-submit"
          onClick={handleSubmit}
        >
          <FormattedMessage
            id="c8facc2d-9549-4ae4-a53a-7c948d08e067"
            defaultMessage="Merge documents"
          />
        </Button>,
      ]}
    >
      <Paragraph>
        <FormattedMessage
          id="5cca5923-4efa-4094-9f43-2255b71e7c87"
          defaultMessage="The final merged document will take on the name and requirements of the document at the top of the list:"
        />
      </Paragraph>
      <Paragraph data-automation-id="merge-document-name-text" className={Styles.documentName}>
        {firstDocumentName}
      </Paragraph>
      <Paragraph>
        <FormattedMessage
          id="081fd609-cff4-45b9-86b8-bee4cef599b7"
          defaultMessage="They can be adjusted after the documents are merged."
        />
      </Paragraph>
      <div className={Styles.alertContainer}>
        <AlertMessage kind="info">
          <FormattedMessage
            id="25bae6b8-0974-4c43-8dbe-2e9c128fc3e7"
            defaultMessage="Need to split the documents after merging? <link>Learn more</link>"
            values={{
              link: (msg: ReactNode[]) => (
                <Link
                  target="_blank"
                  href={MERGE_DOCUMENTS_LEARN_MORE_SUPPORT_URL}
                  title="Merge Documents Learn More"
                  rel="noreferrer"
                >
                  {msg}
                </Link>
              ),
            }}
          />
        </AlertMessage>
      </div>
    </WorkflowModal>
  );
};

export default MergeDocumentsModal;
