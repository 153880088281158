import { createContext, type ReactNode } from "react";

import { useAnalyticsContext } from "common/core/manage_analytics_context";
import type { RbacRoles } from "graphql_globals";

type ProviderProps = {
  children: ReactNode;
  viewer: {
    user: null | {
      organizationMembership: null | {
        roles:
          | null
          | {
              id: string;
              name: string;
            }[];
      };
    };
  };
};
type Context = null | RbacRoles;

const OrgRoleContext = createContext<Context>(null);

function getValue({ user }: ProviderProps["viewer"]): Context {
  const currentRole = user?.organizationMembership?.roles
    ?.find((role) => !role.name.includes("command_center"))
    ?.name.toUpperCase();
  return currentRole as RbacRoles;
}

function OrganizationRoleProvider({ children, viewer }: ProviderProps) {
  return <OrgRoleContext.Provider value={getValue(viewer)}>{children}</OrgRoleContext.Provider>;
}

export default function OrganizationRoleProviderWithAnalytics(props: ProviderProps) {
  useAnalyticsContext(() => ({
    business_role: getValue(props.viewer),
  }));
  return <OrganizationRoleProvider {...props} />;
}
