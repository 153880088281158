import { useMutation } from "util/graphql";
import { captureException } from "util/exception";
import { getCookie, setCookie } from "util/cookie";

import StoreMarketingSessionMutation from "./store_marketing_session_mutation.graphql";

// Defined again in the ./__tests__/util.tsx. If you update this, make sure to update there as well.
enum FlowType {
  Retail = "retail",
  Referral = "retail_referral",
  Business = "business",
  Mortgage = "mortgage",
}

const MARKETING_FLOW_COOKIE_KEY = "proof-mflow";

export function writeMarketingFlowType(flowType: FlowType) {
  // cookie expires after 24 hours
  const options = { expires: 1 };
  setCookie(MARKETING_FLOW_COOKIE_KEY, flowType, options);
}

export function readMarketingFlowType() {
  return getCookie(MARKETING_FLOW_COOKIE_KEY);
}

export type FlowTypeArgs = {
  isReferral?: boolean;
  isEasylink?: boolean;
  isRetail?: boolean;
  isMortgage?: boolean;
};

export function getFlowType({ isReferral, isEasylink, isRetail, isMortgage }: FlowTypeArgs) {
  if (isReferral && !isEasylink) {
    return FlowType.Referral;
  }

  if (isRetail) {
    return FlowType.Retail;
  }

  if (isMortgage) {
    return FlowType.Mortgage;
  }

  return FlowType.Business;
}

export function useSignerMarketingSessionMutation() {
  const storeMarketingSessionFn = useMutation(StoreMarketingSessionMutation);
  return (isAuthenticated: boolean) => {
    // The backend stores the marketing session data using a key that involves the user GID, so we never want
    // to call this without an authenticated user.
    if (isAuthenticated) {
      storeMarketingSessionFn({ variables: { input: {} } }).catch((error) => {
        captureException(error);
      });
    }
  };
}
