import { FormattedMessage } from "react-intl";
import { useEffect } from "react";

import AlertMessage from "common/core/alert_message";
import { useMatchScreenClass } from "common/core/responsive";
import Link from "common/core/link";
import { DOCUMENT_SUPPORT_URL } from "constants/support";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";

import Styles from "./index.module.scss";

type Props = {
  /** Displays an "X" to the right of the banner and allows users to customize the onClose behavior */
  dismissBanner?: () => void;
  documents: {
    classification?: {
      category: string | null;
      languages: string[];
    } | null;
  }[];
  trackingContext?: string;
};

const supportedLanguages = ["EN", "ES"];

const INELIGIBLE_REASONS = {
  DOCUMENT_CATEGORY: "DOCUMENT_CATEGORY",
  DOCUMENT_LANGUAGE: "DOCUMENT_LANGUAGE",
};

export const getDocumentIneligibleReason = (documents: Props["documents"]) => {
  /** leaving in for future potential categories we want to note as ineligible */
  const isIneligibleCategory = false;
  const isIneligibleLanguage = documents.some((d) => {
    const languages = d.classification?.languages.map((l) => l.toUpperCase());
    if (!languages || languages.length === 0) {
      return false;
    }
    return !languages.some((l) => supportedLanguages.includes(l));
  });

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return isIneligibleCategory
    ? INELIGIBLE_REASONS.DOCUMENT_CATEGORY
    : isIneligibleLanguage
      ? INELIGIBLE_REASONS.DOCUMENT_LANGUAGE
      : null;
};

export const ineligibleReasonMessages = {
  [INELIGIBLE_REASONS.DOCUMENT_CATEGORY]: (
    <FormattedMessage
      id="aee48734-945b-47c4-92b0-58c456048cb8"
      defaultMessage={
        "Document may be ineligible for online notarization. <learnMoreLink>Learn more</learnMoreLink>"
      }
      values={{ learnMoreLink: (msg) => <Link href={DOCUMENT_SUPPORT_URL}>{msg}</Link> }}
    />
  ),
  [INELIGIBLE_REASONS.DOCUMENT_LANGUAGE]: (
    <FormattedMessage
      id="e5f64d62-b43c-446f-b82c-12685b2152df"
      defaultMessage={
        "Document must be in English or Spanish to be eligible for online notarization."
      }
    />
  ),
};

function getTrackingMessage(event: string, context?: string) {
  return `${event}${context ? `- ${context}` : ""}`;
}

export function IneligibleNotarizationBanner({ dismissBanner, documents, trackingContext }: Props) {
  const isSmall = useMatchScreenClass("xs", "sm");

  const ineligibleReason = getDocumentIneligibleReason(documents);

  useEffect(() => {
    if (!ineligibleReason) {
      return;
    }
    if (ineligibleReason === INELIGIBLE_REASONS.DOCUMENT_CATEGORY) {
      segmentTrack(
        getTrackingMessage(
          SEGMENT_EVENTS.DISMISSED_INELIGIBLE_DOC_CATEGORY_BANNER,
          trackingContext,
        ),
      );
    } else if (ineligibleReason === INELIGIBLE_REASONS.DOCUMENT_LANGUAGE) {
      segmentTrack(
        getTrackingMessage(
          SEGMENT_EVENTS.DISMISSED_INELIGIBLE_DOC_LANGUAGE_BANNER,
          trackingContext,
        ),
      );
    }
  }, [ineligibleReason]);

  const onDismiss = () => {
    if (dismissBanner) {
      if (ineligibleReason === INELIGIBLE_REASONS.DOCUMENT_CATEGORY) {
        segmentTrack(
          getTrackingMessage(
            SEGMENT_EVENTS.DISMISSED_INELIGIBLE_DOC_CATEGORY_BANNER,
            trackingContext,
          ),
        );
      } else if (ineligibleReason === INELIGIBLE_REASONS.DOCUMENT_LANGUAGE) {
        segmentTrack(
          getTrackingMessage(
            SEGMENT_EVENTS.DISMISSED_INELIGIBLE_DOC_LANGUAGE_BANNER,
            trackingContext,
          ),
        );
      }
      dismissBanner();
    }
  };

  if (!ineligibleReason) {
    return null;
  }

  return (
    <AlertMessage
      className={Styles.uploadPreview}
      banner
      kind="warning"
      centerText={!isSmall}
      onClose={dismissBanner && onDismiss}
    >
      <div>{ineligibleReasonMessages[ineligibleReason]}</div>
    </AlertMessage>
  );
}
