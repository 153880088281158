import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type Props = {
  overrideReason: string;
};

function OverrideReason({ overrideReason }: Props) {
  return (
    <NotaryItemWrapper
      term={
        <FormattedMessage
          id="48230b83-cf70-499d-9450-4ce7067977d2"
          defaultMessage="Missing Requirements Override Reason"
        />
      }
      definition={overrideReason}
    />
  );
}

export default memo(OverrideReason);
