import { FormattedMessage } from "react-intl";
import { useState } from "react";

import Button from "common/core/button";
import WorkflowModal from "common/modals/workflow_modal";
import { Paragraph } from "common/core/typography";
import { useMutation } from "util/graphql";
import { pushNotification } from "common/core/notification_center/actions";
import AlertMessage from "common/core/alert_message";
import { b } from "util/html";

import Styles from "./index.module.scss";
import RemoveDocuments from "./remove_documents_mutation.graphql";

type DocumentSourceId =
  | { templateId: string; transactionId?: never }
  | { transactionId: string; templateId?: never };

type Props = {
  documentsToRemove: string[];
  onClose: () => void;
  documentsToRemoveNameList: (string | null)[];
} & DocumentSourceId;

export function BulkDeleteDocumentsModal({
  documentsToRemove,
  documentsToRemoveNameList,
  onClose,
  transactionId,
  templateId,
}: Props) {
  const [status, setStatus] = useState<"loading" | "error" | null>(null);
  const removeDocuments = useMutation(RemoveDocuments);

  const idInput = templateId ? { templateId } : { organizationTransactionId: transactionId };

  const save = async () => {
    try {
      setStatus("loading");
      await removeDocuments({
        variables: {
          input: {
            ...idInput,
            documentIds: documentsToRemove,
          },
        },
      });
      pushNotification({
        position: "topCenter",
        message: (
          <FormattedMessage
            id="8515d50b-b357-4f87-959c-371173890099"
            defaultMessage="{count, plural, one {Document} other {Documents}} deleted"
            values={{ count: documentsToRemove.length }}
          />
        ),
      });
      setStatus(null);
      onClose();
    } catch {
      setStatus("error");
    }
  };
  return (
    <WorkflowModal
      footerSeparator={false}
      title={
        <FormattedMessage
          id="794e0acd-4e3d-419e-b3f1-fbfe64f01a7a"
          defaultMessage="Confirm document deletion"
        />
      }
      buttons={[
        <Button
          buttonColor="dark"
          variant="tertiary"
          key="bulk-delete-documents-cancel"
          automationId="bulk-delete-documents-cancel"
          onClick={onClose}
        >
          <FormattedMessage id="da4ece1a-d400-40ff-b9cf-9730e3e29b58" defaultMessage="Cancel" />
        </Button>,
        <Button
          isLoading={status === "loading"}
          buttonColor="danger"
          variant="primary"
          key="bulk-delete-documents-submit"
          automationId="bulk-delete-documents-submit"
          onClick={save}
        >
          <FormattedMessage id="7e3a8580-e096-4207-8ed2-ce39cf268e99" defaultMessage="Delete" />
        </Button>,
      ]}
    >
      <Paragraph>
        <FormattedMessage
          id="97987829-1f99-461a-a288-c29f8c3f59fa"
          defaultMessage={
            "Are you sure you want to delete {documents, plural, one {<b>{firstDocument}</b>} other {{documents} documents}}? This action cannot be reversed."
          }
          values={{
            b,
            documents: documentsToRemove.length,
            firstDocument: documentsToRemoveNameList[0],
          }}
        />
      </Paragraph>
      <ul className={Styles.docList}>
        {documentsToRemoveNameList.map((name) => (
          <li key={name} className={Styles.listItem}>
            <b>{name}</b>
          </li>
        ))}
      </ul>
      {status === "error" && (
        <AlertMessage className={Styles.alert} kind="danger">
          <FormattedMessage
            id="ef6aef67-23c8-4088-9875-c5eb6fb7b91e"
            defaultMessage="Something went wrong. Please try again."
          />
        </AlertMessage>
      )}
    </WorkflowModal>
  );
}
