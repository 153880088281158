import "./index.scss";

import { FormattedMessage } from "react-intl";

import SectionHeader from "common/form/sub_form/section/header";
import SubForm from "common/form/sub_form";
import SubFormSection from "common/form/sub_form/section";
import TabRow from "common/core/tabs/tab_button_row";
import TabButton from "common/core/tabs/tab_button_row/tab_button";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import AlertMessage from "common/core/alert_message";
import type { SmartDocsLoans } from "graphql_globals";
import type { parseFormValuesToEnoteSeedFields } from "util/enote_seed";
import type {
  OnAddEnoteToTransactionReturnType,
  OnSaveEnoteSeedReturnType,
} from "common/transaction_creation/v3/sections/promissory_note";

import WetSignOption from "./wet_sign_option";
import EsignOption from "./esign_option";

export const ENOTE_WARNING = Object.freeze({
  MISSING: "MISSING",
  SSN_MISSING: "SSN_MISSING",
  INCOMPLETE: "INCOMPLETE",
});

type MortgageBorrower = {
  firstName: string | null;
  hasSsn: boolean;
  id: string;
  lastName: string | null;
  middleName: string | null;
};

type EnoteDocument = {
  id: string;
  isEnote: boolean;
  mortgageBorrowers?: {
    edges: {
      node: MortgageBorrower;
    }[];
  };
  name: string | null;
};

type EnoteSeed = {
  id: string;
  smartDocType: SmartDocsLoans;
  externallyManaged: boolean;
  source: string;
  enoteSeedFields: EnoteSeedField[];
};

type EnoteSeedField = {
  label: string;
  valid: boolean;
  options: string[];
  format: string;
  inputType: string;
  validationErrors: string[];
  fieldName: string;
  fieldValue: string | null;
};

type Props = {
  paperNoteConsent: boolean;
  onUpdatePaperNoteConsent: (paperNoteExists: boolean) => void;
  enoteMutationLoading?: boolean;
  onAddEnoteToTransaction: (s3FileHandle: string) => Promise<OnAddEnoteToTransactionReturnType>;
  onRemoveDocumentFromTransaction: (documentId: string) => void;
  onUpdateMortgageBorrower: (id: string, ssn?: string) => void;
  enoteWarning?: string | null;
  onSaveEnoteSeed: (
    enoteSeedFields: ReturnType<typeof parseFormValuesToEnoteSeedFields>,
    finalize: boolean,
  ) => Promise<OnSaveEnoteSeedReturnType>;
  onRemoveEnoteSeed: () => Promise<unknown>;
  onInitializeEnoteSeed: (smartDocType: SmartDocsLoans) => Promise<unknown>;
  enoteDocument?: EnoteDocument;
  enoteSeed?: EnoteSeed;
  checkCanAddEnote: () => Promise<{ valid: boolean }>;
  isRequired: boolean;
};

function EnoteSection({
  onUpdatePaperNoteConsent,
  paperNoteConsent,
  onAddEnoteToTransaction,
  enoteMutationLoading,
  onRemoveDocumentFromTransaction,
  onUpdateMortgageBorrower,
  onRemoveEnoteSeed,
  onSaveEnoteSeed,
  onInitializeEnoteSeed,
  enoteDocument,
  enoteWarning = null,
  enoteSeed,
  checkCanAddEnote,
  isRequired,
}: Props) {
  const trackEnoteToggle = (value: number) => {
    segmentTrack(EVENT.LENDER_V2_TOGGLE_PROMISSORY_NOTE, {
      enote_type: value === 0 ? "esign" : "wetsign",
    });
  };

  // enoteDocument is undefined if doesn't exist
  const initialTabIndex = paperNoteConsent && !enoteDocument?.id ? 1 : 0;
  return (
    <SubForm className="EnoteSection">
      <SubFormSection>
        <SectionHeader
          title={
            <FormattedMessage
              id="12971c77-8c31-48b3-ab7a-335da3dcc493"
              defaultMessage="How will the promissory note be signed?{isRequired, select, true {} other { (optional)}}"
              values={{ isRequired }}
            />
          }
        />

        {enoteWarning === ENOTE_WARNING.INCOMPLETE && (
          <AlertMessage className="EnoteSection--Alert">
            <FormattedMessage
              id="4e939614-9d73-4d14-b325-76bce8265505"
              defaultMessage="The eNote has not yet been saved. Edit your eNote draft and create the eNote."
            />
          </AlertMessage>
        )}

        {enoteWarning === ENOTE_WARNING.MISSING && (
          <AlertMessage className="EnoteSection--Alert">
            <FormattedMessage
              id="04de15cb-8747-461e-8553-30a3a019df86"
              defaultMessage="An eNote or paper note must be selected."
            />
          </AlertMessage>
        )}
        <TabRow marginBottom initialTabIndex={initialTabIndex} onSelect={trackEnoteToggle}>
          <TabButton
            title={
              <FormattedMessage id="81ac0509-84e8-4000-9b94-81c0417bf9d5" defaultMessage="eNote" />
            }
            content={
              <EsignOption
                onAddEnoteToTransaction={onAddEnoteToTransaction}
                enoteMutationLoading={enoteMutationLoading}
                onRemoveDocumentFromTransaction={onRemoveDocumentFromTransaction}
                onUpdateMortgageBorrower={onUpdateMortgageBorrower}
                onRemoveEnoteSeed={onRemoveEnoteSeed}
                onSaveEnoteSeed={onSaveEnoteSeed}
                onInitializeEnoteSeed={onInitializeEnoteSeed}
                enoteDocument={enoteDocument}
                enoteSeed={enoteSeed}
                checkCanAddEnote={checkCanAddEnote}
                showSsnError={enoteWarning === ENOTE_WARNING.SSN_MISSING}
              />
            }
            automationId="eSign-tab"
          />
          <TabButton
            title={
              <FormattedMessage
                id="a9a27cfe-9908-45f3-85b4-ece7b608fc88"
                defaultMessage="Wet sign"
              />
            }
            content={
              <WetSignOption
                onUpdatePaperNoteConsent={onUpdatePaperNoteConsent}
                paperNoteConsent={paperNoteConsent}
                enoteMutationLoading={enoteMutationLoading}
              />
            }
            automationId="wet-sign-tab"
          />
        </TabRow>
      </SubFormSection>
    </SubForm>
  );
}

export default EnoteSection;
