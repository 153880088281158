import { useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

import { useMutation } from "util/graphql";
import {
  CredentialAnalysisRequirement,
  SigningRequirementEnum,
  StepState,
  StepType,
} from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";
import { segmentTrack } from "util/segment";
import { SEGMENT_EVENTS } from "constants/analytics";
import { captureException } from "util/exception";
import { useViewer } from "util/viewer_wrapper";
import { isFeatureEnabled } from "util/feature_detection";
import { encodeSearchParams } from "util/location";

import type {
  SignerStepsViewer_signerStepsV2 as Step,
  SignerStepsViewer_signerStepsV2_CredentialAnalysisStep as CredentialAnalysisStep,
} from "./index.viewer.graphql";
import type { SignerStepsBundle as DocumentBundle } from "./index.bundle.graphql";
import UpdateSignerStepV2Mutation from "./update_signer_step_v2_mutation.graphql";

export type MapStepToRouteParams = {
  step?: Step;
  documentBundle: DocumentBundle;
};

export const CURRENT_STEP_SEARCH_PARAM = "cstep";

/**
 * @deprecated use useGetCurrentStep instead
 */
export const useCurrentStepIndex = (trackErrors = true) => {
  const [searchParams] = useSearchParams();
  const currentStepIndex = searchParams.get(CURRENT_STEP_SEARCH_PARAM);
  if (!currentStepIndex && trackErrors) {
    segmentTrack(SEGMENT_EVENTS.CURRENT_STEP_NOT_SET);
  }
  return Number(currentStepIndex);
};

const useCurrentStepIndexV2 = (trackErrors = true) => {
  const [searchParams] = useSearchParams();
  return () => {
    const currentStepIndex = searchParams.get(CURRENT_STEP_SEARCH_PARAM);
    if (!currentStepIndex && trackErrors) {
      segmentTrack(SEGMENT_EVENTS.CURRENT_STEP_NOT_SET);
    }
    return Number(currentStepIndex);
  };
};

const getValidSteps = (steps: Step[]) => {
  // TODO: See if we can stop generating this step on the backend
  return steps.filter(({ stepType }) => stepType !== StepType.EMAIL);
};

/**
 * @deprecated use useGetCurrentStep instead
 */
export const getCurrentStep = ({
  steps,
  currentStepIndex,
}: {
  steps: Step[];
  currentStepIndex: number;
}) => {
  const validSteps = getValidSteps(steps);

  return validSteps[currentStepIndex];
};

export function useGetCurrentStep() {
  const currentStepIndexV2Fn = useCurrentStepIndexV2();
  return ({ steps }: { steps: Step[] }) => {
    return getCurrentStep({ steps, currentStepIndex: currentStepIndexV2Fn() });
  };
}

export const getNextStep = (currentStep: Step, steps: Step[]) => {
  const validSteps = getValidSteps(steps);
  const nextStepIndex =
    validSteps.findIndex((step) => {
      return step.id === currentStep.id;
    }) + 1;
  return nextStepIndex < validSteps.length
    ? ([validSteps[nextStepIndex], nextStepIndex] as const)
    : ([undefined, undefined] as const);
};

export const getNextStepByType = (currentStep: Step, steps: Step[], type: StepType) => {
  const validSteps = getValidSteps(steps);
  const currentStepIndex = validSteps.findIndex((step) => {
    return step.id === currentStep.id;
  });
  const nextStepIndex = validSteps.findIndex((step, i) => {
    return currentStepIndex < i && step.stepType === type;
  });
  return nextStepIndex + 1
    ? ([validSteps[nextStepIndex], nextStepIndex] as const)
    : ([undefined, undefined] as const);
};

const isCredentialAnalysisStep = (step: Step): step is CredentialAnalysisStep => {
  return (
    step.__typename === "CredentialAnalysisStep" && step.stepType === StepType.CREDENTIAL_ANALYSIS
  );
};

export const isTrustedRefereeMeeting = (step?: Step) => {
  return (
    step?.__typename === "MeetingStepV2" &&
    step.stepType === StepType.MEETING &&
    step.isTrustedRefereeMeeting
  );
};

export const stepRequiresSelfie = (step?: Step) => {
  if (!step) {
    return false;
  }
  return isCredentialAnalysisStep(step)
    ? [
        CredentialAnalysisRequirement.BIOMETRIC,
        CredentialAnalysisRequirement.BIOMETRIC_PS1583,
      ].includes(step.credentialAnalysisRequirement)
    : false;
};

export const hasMeetingStep = (steps: Step[]) => {
  return steps.some((step) => step.stepType === StepType.MEETING);
};

// this map does not include the EMAIL step
const mapStepToRoute = ({
  step,
  documentBundle,
  viewerUserId,
  consolidatedRonTemplateEnabled,
  personaPremeetingCaptureEnabled,
  techCheckExperimentForSignerEnabled,
}: {
  step?: Step;
  documentBundle: DocumentBundle;
  viewerUserId: string | undefined;
  consolidatedRonTemplateEnabled?: boolean;
  personaPremeetingCaptureEnabled?: boolean;
  techCheckExperimentForSignerEnabled: boolean;
}) => {
  const { id, isMortgage, signAhead, participants } = documentBundle;
  const viewerParticipant = viewerUserId
    ? participants?.find((p) => p?.userId === viewerUserId)
    : undefined;
  const techCheckExpermientEnabled =
    techCheckExperimentForSignerEnabled &&
    isFeatureEnabled(
      documentBundle.organizationTransaction.publicOrganization,
      "experiment-tech-check-join-now",
    );
  // for mortgage transactions except if the signingRequirement = ESIGN
  const isSignRoute =
    isMortgage &&
    signAhead &&
    viewerParticipant?.signingRequirement !== SigningRequirementEnum.ESIGN;
  switch (step?.stepType) {
    case StepType.TECH_CHECK:
      return techCheckExpermientEnabled
        ? `/bundle/${id}/meeting_request`
        : `/bundle/${id}/twilio-tech-check`;
    case StepType.KBA:
      return `/bundle/${id}/kba-quiz`;
    case StepType.CREDENTIAL_ANALYSIS: {
      const CAPath =
        stepRequiresSelfie(step) || consolidatedRonTemplateEnabled
          ? "identity-verification"
          : !personaPremeetingCaptureEnabled
            ? "id_check/primary_front"
            : "credential-analysis";
      return `/bundle/${id}/${CAPath}`;
    }
    case StepType.MEETING:
      return `/bundle/${id}/meeting_request`;
    case StepType.DOCUMENT:
      return isSignRoute ? `/bundle/${id}/sign` : `/bundle/${id}/prepare`;
    case StepType.PERSONAL_DETAILS:
      return `/bundle/${id}/customer-details`;
    case StepType.PROOF_CERTIFICATE:
      return `/bundle/${id}/proof-id`;
    default:
      segmentTrack(SEGMENT_EVENTS.UNKNOWN_NEXT_STEP, { step });
      captureException(new Error(`Unknown next step: ${step?.stepType}`));
      return "/";
  }
};

export function getPreviousStep(steps: Step[], currentStepIndex: number) {
  const validSteps = getValidSteps(steps);
  return validSteps[currentStepIndex - 1];
}

export const useMapStepToRoute = () => {
  const personaPremeetingCaptureEnabled = useFeatureFlag("persona-premeeting-capture");
  const consolidatedRonTemplateEnabled = useFeatureFlag("persona-ron-consolidated-template");
  const techCheckExperimentForSignerEnabled = useFeatureFlag("experiment-tech-check-join-now");
  const { viewer } = useViewer();

  return (props: MapStepToRouteParams) =>
    mapStepToRoute({
      ...props,
      viewerUserId: viewer.user?.id,
      personaPremeetingCaptureEnabled,
      consolidatedRonTemplateEnabled,
      techCheckExperimentForSignerEnabled,
    });
};

export function useUpdateSignerStepMutation() {
  const updateSignerStepV2MutationFn = useMutation(UpdateSignerStepV2Mutation);
  return ({
    step,
    documentBundleId,
    stepState,
  }: {
    step: Step;
    documentBundleId: string | undefined;
    stepState: StepState;
  }) => {
    return updateSignerStepV2MutationFn({
      variables: { input: { stepId: step.id, state: stepState } },
    }).catch((error: Error) => {
      segmentTrack(SEGMENT_EVENTS.STEP_FAILED_TO_UPDATE, {
        error,
        bundle_id: documentBundleId,
        current_step: step,
      });
      return Promise.reject(error);
    });
  };
}

export function useGetNextStepRoute() {
  const mapStepToRouteFn = useMapStepToRoute();
  const [searchParams] = useSearchParams();
  return ({
    currentStep,
    documentBundle,
    signerSteps,
  }: {
    currentStep: Step;
    documentBundle: DocumentBundle;
    signerSteps: Step[];
  }) => {
    const [nextStep, nextStepIndex] = getNextStep(currentStep, signerSteps);
    if (nextStep) {
      const newSearchParams = new URLSearchParams(searchParams);
      newSearchParams.delete("subStep");
      newSearchParams.set("currentSignerIdentityId", nextStep.subjectId);
      newSearchParams.set(CURRENT_STEP_SEARCH_PARAM, String(nextStepIndex));
      const searchParmsEncoded = encodeSearchParams(newSearchParams);
      const route = mapStepToRouteFn({ step: nextStep, documentBundle });
      return `${route}?${searchParmsEncoded}`;
    }
    return "/";
  };
}

export function useAssertCurrentStep() {
  const [searchParams] = useSearchParams();
  const getCurrentStepFn = useGetCurrentStep();
  const currentStepIndexV2Fn = useCurrentStepIndexV2();

  return ({ stepType, steps }: { stepType: StepType; steps: Step[] }) => {
    const currentStep = getCurrentStepFn({ steps });
    if (currentStep.stepType !== stepType) {
      segmentTrack(SEGMENT_EVENTS.CURRENT_STEP_NOT_FOUND, {
        currentStep,
        all_steps: steps.map(({ id, state }) => `${id}__${state}`),
        currentStepIndex: currentStepIndexV2Fn(),
      });
      const signerIdentityId = searchParams.get("currentSignerIdentityId")!;
      throw new Error(`Step ${stepType} not found for signer identity: ${signerIdentityId}`);
    }
  };
}

export const useWaitForStepProcessing = ({
  maxRetries = 15,
  refetchFn,
  currentStep,
  onComplete,
  onFail,
  onTimeout,
}: {
  maxRetries?: number;
  refetchFn: () => void;
  currentStep?: Step;
  onComplete: () => void;
  onFail: () => void;
  onTimeout: () => void;
}) => {
  const [stepProcessing, setStepProcessing] = useState(false);
  const retryAttempts = useRef(0);

  useEffect(() => {
    if (retryAttempts.current >= maxRetries) {
      captureException(new Error(`Timed out waiting for step to finish processing`), {
        currentStep,
      });
      onTimeout();
    }
    if (stepProcessing) {
      if (currentStep?.state === StepState.PROCESSING) {
        const timeoutId = setTimeout(() => {
          refetchFn();
          retryAttempts.current += 1;
        }, 1_000);
        return () => {
          clearTimeout(timeoutId);
        };
      } else if (currentStep?.state === StepState.COMPLETED) {
        onComplete();
      } else if (currentStep?.state === StepState.FAILED) {
        onFail();
      }
    }
  }, [stepProcessing, retryAttempts.current, currentStep?.state]);

  const startProcessPolling = () => {
    setStepProcessing(true);
  };

  return { startProcessPolling, stepProcessing } as const;
};
