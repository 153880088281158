import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type Props = {
  meeting: { platform: string | null };
};

function MeetingPlatform({ meeting: { platform } }: Props) {
  return (
    <NotaryItemWrapper
      term={
        <FormattedMessage id="ce89c673-a9dd-421f-b59a-5a9286bb5bbf" defaultMessage="Platform" />
      }
      definition={platform}
    />
  );
}

export default memo(MeetingPlatform);
