import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";

import { useQuery } from "util/graphql";
import BundleNotFoundModal from "common/document_bundle/bundle_not_found_modal";
import { onlyRequiresEsign } from "util/completion_requirements/completion_requirements_text";
import LoadingIndicator from "common/core/loading_indicator";
import { OrganizationTransactionVariant, SigningRequirementEnum } from "graphql_globals";
import { useFeatureFlag } from "common/feature_gating";
import { RON_LANDING_REDESIGN } from "constants/feature_gates";
import ActiveSessionBlockingModalWrapper from "common/signer/signer_identity/active_session_blocking_modal";
import { getLinkForQrStream } from "common/account/util";
import { pendoAddParamsToUrl } from "util/pendo";

import SignerLandingQuery, {
  type SignerLanding_documentBundle_DocumentBundle as DocumentBundle,
  type SignerLanding_viewer as Viewer,
} from "./index.query.graphql";
import { BitLandingV2 } from "./content/generic";
import { RealEstateLanding } from "./content/real_estate";
import { EsignLanding } from "./content/esign";
import { ProofLanding } from "./content/proof";
import { BusinessRonLandingPage } from "./v3/landings/business_ron";
import { RealRonLandingPage } from "./v3/landings/real_ron";
import { getLandingVariant, LANDING_VARIANTS } from "./v3/util";

type Props = {
  documentBundle: DocumentBundle;
  viewer: Viewer;
  readOnly?: boolean;
};

function LandingComponent({
  documentBundle,
  viewer,
  readOnly,
  handleBlockedAction,
}: Props & { handleBlockedAction: (action: () => void) => void }) {
  const { completionRequirements } = documentBundle;

  const viewerParticipant = documentBundle.participants!.find((p) => p!.userId === viewer.user!.id);
  const isProofTransaction =
    documentBundle.organizationTransaction.transactionVariant ===
      OrganizationTransactionVariant.PROOF ||
    documentBundle.organizationTransaction.transactionVariant ===
      OrganizationTransactionVariant.IDENTIFY ||
    viewerParticipant?.proofRequirement?.ca?.selfie;
  const userParticipantIsEsign =
    viewerParticipant?.signingRequirement === SigningRequirementEnum.ESIGN;
  const isEsign =
    (userParticipantIsEsign || onlyRequiresEsign(completionRequirements)) && !isProofTransaction;
  const isRealEstate = documentBundle.isMortgage && !userParticipantIsEsign;
  const showRonLandingRedesign = useFeatureFlag(RON_LANDING_REDESIGN);

  useEffect(() => {
    const bundleOrgId = documentBundle.organizationTransaction.publicOrganization.id;
    const parentOrgId =
      documentBundle.organizationTransaction.publicOrganization.parentOrganizationId;
    pendoAddParamsToUrl({
      orgId: bundleOrgId,
      ...(parentOrgId && { parentOrgId }),
    });
  }, []);

  let Component;
  if (showRonLandingRedesign) {
    const landingPage = getLandingVariant(documentBundle, viewer);

    if (landingPage === LANDING_VARIANTS.BUSINESS_RON) {
      return (
        <BusinessRonLandingPage
          handleBlockedAction={handleBlockedAction}
          documentBundle={documentBundle}
          viewer={viewer}
          readOnly={readOnly}
        />
      );
    }
    if (landingPage === LANDING_VARIANTS.REAL_RON) {
      return (
        <RealRonLandingPage
          handleBlockedAction={handleBlockedAction}
          documentBundle={documentBundle}
          viewer={viewer}
          readOnly={readOnly}
        />
      );
    }
  }

  if (isRealEstate) {
    // Render real_estate_content.tsx for all real estate transactions(including standard RON created by lender/title)
    // except if the participant is signingRequirement = ESIGN
    Component = RealEstateLanding;
  } else if (isProofTransaction) {
    // Render proof.tsx for Proof transactions (including transactions requiring esign authentication with Persona)
    Component = ProofLanding;
  } else if (isEsign) {
    // Render esign_content.tsx for all esign transactions that have customizable landing pages enabled
    Component = EsignLanding;
  } else {
    // Render v2_content.tsx for all other organizations/transaction types
    Component = BitLandingV2;
  }

  return (
    <Component
      handleBlockedAction={handleBlockedAction}
      documentBundle={documentBundle}
      viewer={viewer}
      readOnly={readOnly}
      isEsign={isEsign}
    />
  );
}

function BitLanding({ documentBundle, viewer, readOnly }: Props) {
  return (
    <ActiveSessionBlockingModalWrapper viewer={viewer} participants={documentBundle.participants}>
      {({ handleBlockedAction }) => (
        <LandingComponent
          documentBundle={documentBundle}
          viewer={viewer}
          readOnly={readOnly}
          handleBlockedAction={handleBlockedAction}
        />
      )}
    </ActiveSessionBlockingModalWrapper>
  );
}

function BitLandingWrapper({
  previewDocumentBundle,
  previewViewer,
}: {
  previewDocumentBundle?: DocumentBundle;
  previewViewer?: Viewer;
}) {
  const [searchParams] = useSearchParams();
  const documentBundleId = searchParams.get("id") || "";

  const { data, loading } = useQuery(SignerLandingQuery, {
    variables: {
      documentBundleId,
      link: getLinkForQrStream(),
    },
    skip: !documentBundleId,
  });

  if (previewDocumentBundle && previewViewer) {
    return <BitLanding readOnly documentBundle={previewDocumentBundle} viewer={previewViewer} />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  const { documentBundle, viewer } = data!;

  if (!documentBundle) {
    // documentBundle can be null if bundle was recalled
    return <BundleNotFoundModal />;
  }

  if (documentBundle.__typename !== "DocumentBundle") {
    throw new Error(`Expected DocumentBundle, got ${documentBundle.__typename}.`);
  }

  return <BitLanding documentBundle={documentBundle} viewer={viewer} />;
}

export default BitLandingWrapper;
