import { useMatch, useNavigate } from "react-router-dom";
import { defineMessages, useIntl } from "react-intl";

import { EnvironmentIndicator } from "common/app_frame/header/dev";
import { EVENT } from "constants/analytics";
import AppSubdomains, { type ProofPortal } from "constants/app_subdomains";
import { redirectToSubdomain } from "util/application_redirect";
import { saveUserDevicePreferences } from "util/device_preferences";
import { segmentTrack } from "util/segment";
import Icon from "common/core/icon";
import { SUPPORT_PAGE } from "constants/support";
import SupportChat, { openSupportChat } from "common/support/chat";
import { Avatar } from "common/core/avatar";
import { Paragraph, Heading } from "common/core/typography";
import { useMatchScreenClass, useMobileScreenClass } from "common/core/responsive";
import { getAccessibleSubdomains } from "common/account/util";
import { userFullName, userInitials } from "util/user";
import { BASE_PATH as JOURNAL_PATH } from "common/notary/journal/routing";
import Env from "config/environment";
import { SIGN_PATH, SETTINGS_PATH } from "util/routes";
import PopoutMenuItem from "common/core/popout_menu/item";

import type { GlobalNavUser } from "./index.fragment.graphql";
import { SignerOnlyMenuContent } from "./signer_only_menu_content";
import { PortalLogo } from "./portal_logo";
import { AccountBlurb, ProofIdList } from "./proof_id_widget";
import { WaffleNav } from "./waffle_nav";
import { NavMenu } from "./nav_menu";
import Styles from "./index.module.scss";
import { TECH_CHECK_PATH } from "../path";

const MESSAGES = defineMessages({
  globalNava11yLabel: {
    id: "0e215929-d5eb-4566-9937-eae12863bae6",
    defaultMessage: "Main navigation",
  },
  helpMenuLabel: {
    id: "cd80ddc2-7f16-4a83-9dd6-781e1d0c5cc2",
    defaultMessage: "Help menu",
  },
  knowledgeBaseLabel: {
    id: "c1dd99d1-95d8-41a3-aaca-1593866fb7a7",
    defaultMessage: "Help center",
  },
  checkAvSettingsLabel: {
    id: "915e3ad4-7caa-4bf2-9ce6-2ca5ce1f92b9",
    defaultMessage: "Check A/V settings",
  },
  chatWithSupportLabel: {
    id: "27727290-4bce-47d2-83ba-3cdcffbf497e",
    defaultMessage: "Chat with support",
  },
  userProfileLabel: {
    id: "ddabd8e0-1d8b-4702-9b5c-9563820bc63a",
    defaultMessage: "User menu",
  },
  widgetTitle: {
    id: "46d1d408-314d-4e85-9ffa-9b5f73852bf9",
    defaultMessage: "My Proof account",
  },
});

type GlobalNavProps = {
  onLogout: () => void;
  user: GlobalNavUser;
  hasSignAccess: boolean;
  portal: ProofPortal;
};

export function GlobalNav({ hasSignAccess, user, portal, onLogout }: GlobalNavProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const IS_SIGNER_PORTAL = portal === "app";
  const hasNotaryProfile = Boolean(user.notaryProfile);
  const isExtraSmall = useMatchScreenClass("xs");

  const hasOrgAccount = Boolean(user.organization);

  // list of accessible subdomains minus app, since it is accessed separately from the rest.
  const accessibleSubdomains = getAccessibleSubdomains(null, user.accountTypes);

  const clearDevicePreferences = () => {
    saveUserDevicePreferences({
      webcamDeviceId: null,
      microphoneDeviceId: null,
      speakerDeviceId: null,
    });
  };

  const goToTechCheck = () => {
    clearDevicePreferences();
    if (IS_SIGNER_PORTAL) {
      navigate(TECH_CHECK_PATH);
    } else {
      redirectToSubdomain(AppSubdomains.customer, TECH_CHECK_PATH);
    }
  };

  const handleSignUrl = () => {
    if (IS_SIGNER_PORTAL) {
      navigate(SIGN_PATH);
    } else {
      segmentTrack(EVENT.CLICKED_TO_ENTER_SIGN_FLOW);
      redirectToSubdomain(AppSubdomains.customer, SIGN_PATH);
    }
  };

  const handleSettingsUrl = () => {
    if (IS_SIGNER_PORTAL) {
      navigate(SETTINGS_PATH);
    } else {
      segmentTrack(EVENT.CLICKED_TO_ENTER_SIGN_FLOW);
      redirectToSubdomain(AppSubdomains.customer, SETTINGS_PATH);
    }
  };

  const isNotaryJournalPage = Boolean(useMatch({ path: JOURNAL_PATH, end: false }));

  const handleNotaryJournalUrl = () => {
    if (IS_SIGNER_PORTAL) {
      navigate(JOURNAL_PATH, { state: { refreshData: isNotaryJournalPage } });
    } else {
      redirectToSubdomain(AppSubdomains.customer, JOURNAL_PATH);
    }
  };

  const { isCustomerFacingTestEnvironment } = Env;

  const isMobileSize = useMobileScreenClass();

  return (
    <header className={Styles.globalHeader}>
      <SupportChat viewer={{ id: user.id, user }} showSupportButton={false} />
      <nav aria-label={intl.formatMessage(MESSAGES.globalNava11yLabel)}>
        <div className={Styles.navLeft}>
          {!isExtraSmall && hasSignAccess && (
            <WaffleNav
              availablePortals={accessibleSubdomains}
              portal={portal}
              customMenuContent={hasOrgAccount ? null : <SignerOnlyMenuContent userId={user.id} />}
              user={user}
            />
          )}
          <div className={Styles.logoContainer}>
            <PortalLogo user={user} portal={portal} currentPortal={portal} />
          </div>
          {!isCustomerFacingTestEnvironment && <EnvironmentIndicator />}
        </div>
        <ul className={Styles.navItemList}>
          <li className={Styles.navItem}>
            <NavMenu
              placement="bottomRight"
              hasDropdownArrow
              targetContent={<Icon size="extraLarge" name="question" />}
              targetClassName={Styles.navItemAction}
              aria-label={intl.formatMessage(MESSAGES.helpMenuLabel)}
            >
              {({ closeMenu }) => (
                <>
                  <PopoutMenuItem
                    linkUrl={{ externalUrl: true, url: SUPPORT_PAGE }}
                    onClick={closeMenu}
                  >
                    {intl.formatMessage(MESSAGES.knowledgeBaseLabel)}
                  </PopoutMenuItem>
                  <PopoutMenuItem overrideRole onClick={goToTechCheck}>
                    {intl.formatMessage(MESSAGES.checkAvSettingsLabel)}
                  </PopoutMenuItem>
                  <PopoutMenuItem
                    overrideRole
                    onClick={() => {
                      openSupportChat();
                      closeMenu();
                    }}
                  >
                    {intl.formatMessage(MESSAGES.chatWithSupportLabel)}
                  </PopoutMenuItem>
                </>
              )}
            </NavMenu>
          </li>

          <li className={Styles.navItem}>
            <NavMenu
              placement="bottomRight"
              aria-label={intl.formatMessage(MESSAGES.userProfileLabel)}
              targetClassName={Styles.avatarButton}
              menuClassName={Styles.proofWidgetMenu}
              targetContent={
                <Avatar
                  size={isExtraSmall ? "extraSmall" : "small"}
                  userInitials={userInitials(user)}
                  kind="green"
                />
              }
              data-automation-id="open-id-widget-button"
              header={
                <div className={Styles.accountInfo}>
                  <div className={Styles.accountName}>
                    <div>
                      <Heading level="h2" textStyle="headingFive">
                        {intl.formatMessage(MESSAGES.widgetTitle)}
                      </Heading>
                      <div className={Styles.userName}>
                        <Paragraph size="large">{userFullName(user)}</Paragraph>
                      </div>
                    </div>
                    <span aria-hidden="true">
                      <Avatar
                        size={isMobileSize ? "medium" : "large"}
                        userInitials={userInitials(user)}
                        kind="green"
                      />
                    </span>
                  </div>
                  <AccountBlurb size="small" />
                </div>
              }
            >
              {({ closeMenu }) => (
                <ProofIdList
                  closeMenu={closeMenu}
                  hasNotaryProfile={hasNotaryProfile}
                  hasSignAccess={hasSignAccess}
                  signDocumentsUrl={handleSignUrl}
                  notaryJournalUrl={handleNotaryJournalUrl}
                  handleSettingsUrl={handleSettingsUrl}
                  handleLogout={onLogout}
                  showReferAFriend={portal === AppSubdomains.customer}
                />
              )}
            </NavMenu>
          </li>
        </ul>
      </nav>
    </header>
  );
}
