import type { ComponentProps } from "react";
import {
  Observable,
  Subject,
  BehaviorSubject,
  defer,
  fromEvent,
  concat,
  merge,
  of,
  from,
  EMPTY,
  catchError,
  switchMap,
  mergeAll,
  mergeMap,
  filter,
  map,
  concatMap,
  takeUntil,
  endWith,
  last,
  scan,
  distinctUntilChanged,
} from "rxjs";

import type { PageTypes } from "graphql_globals";
import { lazyBufferTime } from "util/rxjs";
import { captureException } from "util/exception";
import {
  getPspPageInfo,
  makeResizeEvent,
  makeEditTextEvent,
  makeMoveEvent,
  makePspId,
  getNotarizeIdFromPspId,
  updateImmutableAnnotationEfficently,
  encodeMoreAttributesInPspId,
  userIsInteractingWithTextAnnotation,
  type KitModule,
  type KitAnnotation,
  type KitInstance,
  type PageInformation,
  type UserUpdateDecorationEvent,
  type KitShapeAnnotation,
} from "common/pdf/pspdfkit/util";
import {
  createKitAnnotationForDesignation,
  buildDesignationCallbackPresenceForTooltips,
  type DesignationCallbacks,
  type DesignationUpdateArgs,
} from "common/pdf/pspdfkit/designation";
import { createKitAnnotationForIndicator, type Indicator } from "common/pdf/pspdfkit/indicator";
import {
  createKitAnnotationForAnnotation,
  updateKitAnnotationForAnnotation,
  adjustMoveEventForAnnotation,
  getYOffsetAdjustFnForAnnotation,
  buildAnnotationCallbackPresenceForTooltips,
  type AnnotationCallbacks,
  type AnnotationUpdateArgs,
} from "common/pdf/pspdfkit/annotation";
import { hexToRGBComponents } from "util/color";
import { scaleFontSize } from "common/pdf/util";
import { isMobileDevice } from "util/support";

export type ResizeSettings = { increment: number; minFontSize: number; minHeight: number };
type DecorationHandlers<Args> = {
  update: (args: Args) => void;
  destroy: () => void;
};
type RenderFn = (node: HTMLDivElement) => void;
type IndicatorUpdateArgs = { indicator: ComponentProps<typeof Indicator>; onNodeCreate?: RenderFn };
export type DecorationContext = {
  isEditable: (pspAnno: KitAnnotation) => boolean;
  getDeleteFn: (pspAnno: KitAnnotation) => undefined | (() => void);
  getCanEditOverride: (pspAnno: KitAnnotation) => undefined | boolean;
  getIsLockedDocument: (pspAnno: KitAnnotation) => undefined | boolean;
  getReassignFn: (pspAnno: KitAnnotation) => undefined | (() => void);
  getAddToGroupFn: (pspAnno: KitAnnotation) => undefined | (() => void);
  getAddConditionalFn: (pspAnno: KitAnnotation) => undefined | (() => void);
  getSetOptionalFn: (pspAnno: KitAnnotation) => undefined | (() => void);
  getIncrementalResizeFn: (
    pspAnno: KitAnnotation,
  ) =>
    | undefined
    | ((increment: number, isTextAnnotation: boolean, resizeSettings: ResizeSettings) => void);
  getRenderFn: (pspAnno: KitAnnotation) => undefined | RenderFn;
  setFocused: (notarizeId: string) => void;
  addIndicator: (opts: IndicatorUpdateArgs) => DecorationHandlers<IndicatorUpdateArgs>;
  addDesignation: (opts: {
    designation: DesignationUpdateArgs["designation"];
    onDelete: DesignationUpdateArgs["onDelete"];
    onAddToGroup: DesignationUpdateArgs["onAddToGroup"];
    onReassign: DesignationUpdateArgs["onReassign"];
    onAddConditional: DesignationUpdateArgs["onAddConditional"];
    onSetOptional: DesignationUpdateArgs["onSetOptional"];
    onNodeCreate: RenderFn;
    isPreview?: boolean;
  }) => DecorationHandlers<DesignationUpdateArgs>;
  addAnnotation: (opts: {
    annotation: AnnotationUpdateArgs["annotation"];
    onUpdate: AnnotationUpdateArgs["onUpdate"];
    onDelete: AnnotationUpdateArgs["onDelete"];
    isPreview?: boolean;
    isPrimaryConditional?: boolean;
    isLockedDocument?: boolean;
    canEditOverride?: boolean;
  }) => DecorationHandlers<AnnotationUpdateArgs>;
};
type FocusSubject = BehaviorSubject<string | null>;
type EnhancedDesignationCallbacks = DesignationCallbacks & { onNodeCreate: RenderFn };
type IndicatorCallbacks = { onNodeCreate?: RenderFn };
type DecorationCallbackLookup = {
  annotations: Record<string, undefined | AnnotationCallbacks>;
  designations: Record<string, undefined | EnhancedDesignationCallbacks>;
  indicators: Record<string, undefined | IndicatorCallbacks>;
};
type BatchableOperation = {
  type: "focus" | "delete" | "update";
  pspAnnotation: KitAnnotation;
};
type BoundableBox = {
  location: { point: { x: number; y: number }; page: number; pageType: PageTypes };
  size: null | { width: number; height: number };
};
type CreateSignal<Args, A extends KitAnnotation = KitAnnotation> = {
  annotation: KitAnnotation;
  updateCall$: Observable<Args>;
  reducerFn: (current: A, callArgs: Args) => A;
};
type ExpiringDecorationConfig = {
  module: KitModule;
  instance: KitInstance;
  checkmarkAttachmentId: string;
  pageInformationLookup: PageInformation;
};

export const BASE_DASH_STROKE: [number, number] = [1, 1];
const BLOCKED_CTRL_KEYS: Record<string, undefined | true> = Object.freeze({
  d: true,
  w: true,
  s: true,
  p: true,
  f: true,
  u: true,
});

function noop() {}

function isTruthy<T>(value: T | null | false | undefined): value is T {
  return Boolean(value);
}

function identityAdjustY(y: number): number {
  return y;
}

function makeBoundingBoxParams(
  config: ExpiringDecorationConfig,
  { location, size }: BoundableBox,
  pspPageIndex: number,
  yOffsetAdjustFn: (originalY: number, size: { height: number }) => number,
) {
  const pageInfo = getPspPageInfo(config.instance, pspPageIndex);
  return {
    left: location.point.x,
    top: yOffsetAdjustFn(pageInfo.height - location.point.y, size!),
    width: size!.width,
    height: size!.height,
  };
}

function makeBoundingBox(
  config: ExpiringDecorationConfig,
  box: BoundableBox,
  pspPageIndex: number,
  yOffsetAdjustFn: Parameters<typeof makeBoundingBoxParams>[3],
) {
  return new config.module.Geometry.Rect(
    makeBoundingBoxParams(config, box, pspPageIndex, yOffsetAdjustFn),
  );
}

function makeBoundingBoxParamsFlipY(
  config: ExpiringDecorationConfig,
  { location, size }: BoundableBox,
  pspPageIndex: number,
  yOffsetAdjustFn: (originalY: number, size: { height: number }) => number,
) {
  return {
    left: location.point.x,
    top: yOffsetAdjustFn(location.point.y, size!),
    width: size!.width,
    height: size!.height,
  };
}

// Make a bounding box that flips the Y coord so that it is relative to the top of the page.
// This is useful when you want to position something relative to the top of the document
function makeBoundingBoxFlipY(
  config: ExpiringDecorationConfig,
  box: BoundableBox,
  pspPageIndex: number,
  yOffsetAdjustFn: Parameters<typeof makeBoundingBoxParams>[3],
) {
  return new config.module.Geometry.Rect(
    makeBoundingBoxParamsFlipY(config, box, pspPageIndex, yOffsetAdjustFn),
  );
}

function deferWithCatch<T>(factory: () => Promise<T> | Observable<T>): Observable<T> {
  // If for any reason an instance call should fail, we try to keep going while logging the error.
  return defer(factory).pipe(
    catchError((err) => {
      captureException(err);
      return EMPTY;
    }),
  );
}

function getPspPageIndex(pageInformationLookup: PageInformation, { location }: BoundableBox) {
  const baseIndex = pageInformationLookup.getBasisPageIndex(location.pageType);
  return baseIndex + location.page;
}

/**
 * This function exist to make calls to `getPspPageIndex` since delay making instance calls protected by
 * observable subscriptions.
 */
function getSafePspPageIndex(pageInformationLookup: PageInformation, box: BoundableBox) {
  return defer(() => of(getPspPageIndex(pageInformationLookup, box)));
}

/**
 * Though the particular implementation of callbacks that add buttons in the tooltip in the UI do not affect
 * the rendered visual representation of a PSPAnnotation, we still need to diff the _presence_ of these
 * callbacks so that we can tell PSPDFKit to update when a button disappears/appears.
 */
function updateAnnotationTooltipButtons<Cbs extends Record<string, unknown>>(
  pspAnno: KitAnnotation,
  callbacks: Cbs | undefined,
  builder: (callbacks: Cbs | undefined) => Record<string, boolean>,
): KitAnnotation {
  const newPresenceCustomData = builder(callbacks);
  const customData = pspAnno.customData || {};
  for (const [key, isPresent] of Object.entries(newPresenceCustomData)) {
    if (isPresent !== customData[key]) {
      return pspAnno.set("customData", { ...customData, ...newPresenceCustomData });
    }
  }
  return pspAnno;
}

function updateAnnotationLocation(
  config: ExpiringDecorationConfig,
  currentPspAnno: KitAnnotation,
  box: BoundableBox,
  yOffsetAdjustFn: Parameters<typeof makeBoundingBoxParams>[3],
): KitAnnotation {
  const pspPageIndex = getPspPageIndex(config.pageInformationLookup, box);
  return updateImmutableAnnotationEfficently(currentPspAnno, {
    pageIndex: pspPageIndex,
    boundingBox: updateImmutableAnnotationEfficently(
      currentPspAnno.boundingBox,
      makeBoundingBoxParams(config, box, pspPageIndex, yOffsetAdjustFn),
    ),
  });
}

function updateIndicatorLocation(
  config: ExpiringDecorationConfig,
  currentPspAnno: KitAnnotation,
  box: IndicatorUpdateArgs["indicator"],
  yOffsetAdjustFn: Parameters<typeof makeBoundingBoxParams>[3],
): KitAnnotation {
  const pspPageIndex = getPspPageIndex(config.pageInformationLookup, box);
  const makeBoundingBoxParamsFn = box.flipY ? makeBoundingBoxParamsFlipY : makeBoundingBoxParams;
  return updateImmutableAnnotationEfficently(currentPspAnno, {
    pageIndex: pspPageIndex,
    boundingBox: updateImmutableAnnotationEfficently(
      currentPspAnno.boundingBox,
      makeBoundingBoxParamsFn(config, box, pspPageIndex, yOffsetAdjustFn),
    ),
  });
}

function updateDesignationColor(
  config: ExpiringDecorationConfig,
  currentPspAnno: KitShapeAnnotation,
  callbacks: EnhancedDesignationCallbacks | undefined,
): KitShapeAnnotation {
  const rgbColor = hexToRGBComponents(callbacks?.color || "#666666");
  const strokeDashArray = callbacks?.dashedBorder ? BASE_DASH_STROKE : null;
  // need to have strokeWidth > 0 for annotation to render in preview sidebar
  // .01 stroke width doesn't actually render so effectively 0 while allowing preview sidebar to work
  const strokeWidth = callbacks?.isPrimaryConditional ? 2.5 : callbacks?.isHighlighted ? 1.5 : 0.01;

  if (!currentPspAnno.fillColor || !currentPspAnno.strokeColor) {
    return currentPspAnno.withMutations((annotation: KitShapeAnnotation) => {
      const pspColor = new config.module.Color(rgbColor);
      annotation.set("fillColor", pspColor);
      annotation.set("strokeColor", pspColor);
      annotation.set("strokeWidth", strokeWidth);
      annotation.set("strokeDashArray", strokeDashArray);
    });
  }

  return updateImmutableAnnotationEfficently(currentPspAnno, {
    fillColor: updateImmutableAnnotationEfficently(currentPspAnno.fillColor, rgbColor),
    strokeColor: updateImmutableAnnotationEfficently(currentPspAnno.strokeColor, rgbColor),
    strokeWidth,
    strokeDashArray,
  });
}

function makeAnnotationReducerFn(
  config: ExpiringDecorationConfig,
  callbackLookup: DecorationCallbackLookup,
) {
  return (currentPspAnno: KitAnnotation, { annotation }: AnnotationUpdateArgs) => {
    const notarizeId = getNotarizeIdFromPspId(currentPspAnno.id);
    const callbacks = callbackLookup.annotations[notarizeId];
    const movedAnnotation = updateAnnotationLocation(
      config,
      currentPspAnno,
      annotation,
      getYOffsetAdjustFnForAnnotation(annotation),
    );
    const annotationWithNewKitProps = updateKitAnnotationForAnnotation(movedAnnotation, annotation);
    return updateAnnotationTooltipButtons(
      annotationWithNewKitProps,
      { ...callbacks, annotation },
      buildAnnotationCallbackPresenceForTooltips,
    );
  };
}

function makeIndicatorReducerFn(config: ExpiringDecorationConfig) {
  return (currentPspAnno: KitAnnotation, { indicator }: IndicatorUpdateArgs) => {
    return updateIndicatorLocation(config, currentPspAnno, indicator, identityAdjustY);
  };
}

function makeDesignationReducerFn(
  config: ExpiringDecorationConfig,
  callbackLookup: DecorationCallbackLookup,
) {
  return (currentPspAnno: KitShapeAnnotation, { designation }: DesignationUpdateArgs) => {
    const notarizeId = getNotarizeIdFromPspId(currentPspAnno.id);
    const callbacks = callbackLookup.designations[notarizeId];
    const designationWithNewColor = updateDesignationColor(config, currentPspAnno, callbacks);
    const designationWithNewLocation = updateAnnotationLocation(
      config,
      designationWithNewColor,
      designation,
      identityAdjustY,
    );
    return updateAnnotationTooltipButtons<DesignationCallbacks>(
      designationWithNewLocation,
      callbacks,
      buildDesignationCallbackPresenceForTooltips,
    ) as KitShapeAnnotation;
  };
}

function makeDecorationHandlers<Args, Cbs>(opts: {
  container: Record<string, Cbs | undefined>;
  key: string;
  callbacks: Cbs;
  callbackSetter: (callbacks: Cbs, args: Args) => void;
}) {
  const { container, key, callbacks, callbackSetter } = opts;
  // We use a BehaviorSubject so that we always remember the "latest" update, regardless
  // of when the subscription to this subject comes.
  const lastUpdateCall$ = new BehaviorSubject<Args | null>(null);
  container[key] = callbacks;
  return {
    updateCall$: lastUpdateCall$.pipe(filter(isTruthy)),
    handlers: {
      update: (args: Args) => {
        callbackSetter(callbacks, args);
        lastUpdateCall$.next(args);
      },
      destroy: () => {
        lastUpdateCall$.complete();
        delete container[key];
      },
    },
  };
}

function createDecorations(
  instance: KitInstance,
  signals: CreateSignal<unknown>[],
  focusCall$: FocusSubject,
  killSwitchCall$: Subject<string>,
): Observable<BatchableOperation> {
  const pspAnnotationsToCreate = signals.map((sig) => sig.annotation);
  const createPromise = instance.create(pspAnnotationsToCreate) as Promise<KitAnnotation[]>;
  return from(createPromise).pipe(
    switchMap((createdPspAnnotations) => {
      const pspAnnotationLifecycles$: Observable<BatchableOperation>[] = createdPspAnnotations.map(
        (createdAnnotation, index) => {
          const { reducerFn, updateCall$ } = signals[index];
          const pspAnnotationId = createdAnnotation.id;
          const notarizeId = getNotarizeIdFromPspId(pspAnnotationId);
          const deleteOp$ = of({ type: "delete" as const, pspAnnotation: createdAnnotation });
          const focusOp = { type: "focus" as const, pspAnnotation: createdAnnotation };
          const focusOp$ = focusCall$.pipe(
            filter((id) => id === notarizeId),
            map(() => focusOp),
            takeUntil(updateCall$.pipe(endWith(null), last())),
          );
          const updatesOp$ = updateCall$.pipe(
            scan(reducerFn, createdAnnotation),
            // We never want this scan function to repeat the original createdAnnotation, which can happen since
            // updateCall$ happens all the time. distinctUntilChanged doesn't guard us against the emited value since
            // theres nothing to compare it to yet.
            filter((pspAnnotation) => pspAnnotation !== createdAnnotation),
            distinctUntilChanged(),
            map((pspAnnotation) => ({ type: "update" as const, pspAnnotation })),
          );
          const updatesAndFocusOp$ = merge(updatesOp$, focusOp$);
          return concat(updatesAndFocusOp$, deleteOp$).pipe(
            takeUntil(killSwitchCall$.pipe(filter((killedId) => killedId === pspAnnotationId))),
          );
        },
      );
      return merge(...pspAnnotationLifecycles$);
    }),
  );
}

function getUpdateCallback(callbackLookup: DecorationCallbackLookup, pspAnnotation: KitAnnotation) {
  const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
  if (notarizeId) {
    return (
      callbackLookup.annotations[notarizeId]?.onUpdate ||
      callbackLookup.designations[notarizeId]?.onUpdate
    );
  }
}

function getDeleteCallback(callbackLookup: DecorationCallbackLookup, pspAnnotation: KitAnnotation) {
  const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
  if (notarizeId) {
    return (
      callbackLookup.annotations[notarizeId]?.onDelete ||
      callbackLookup.designations[notarizeId]?.onDelete
    );
  }
}

function getIsLockedDocumentCallback(
  callbackLookup: DecorationCallbackLookup,
  pspAnnotation: KitAnnotation,
) {
  const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
  if (notarizeId) {
    return callbackLookup.annotations[notarizeId]?.isLockedDocument;
  }
}

function getCanEditOverrideCallback(
  callbackLookup: DecorationCallbackLookup,
  pspAnnotation: KitAnnotation,
) {
  const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
  if (notarizeId) {
    return callbackLookup.annotations[notarizeId]?.canEditOverride;
  }
}

function getIncrementalResizeCallback(
  callbackLookup: DecorationCallbackLookup,
  pspAnnotation: KitAnnotation,
) {
  const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
  const handleUpdate = notarizeId && callbackLookup.annotations[notarizeId]?.onUpdate;

  return handleUpdate
    ? (increment: number, isTextAnnotation: boolean, resizeSettings: ResizeSettings) => {
        const { fontSize, text } = pspAnnotation;
        const { width, height } = pspAnnotation.boundingBox;
        const { minHeight, minFontSize } = resizeSettings;

        if (isTextAnnotation) {
          const newWidth = width + increment;
          const newFontsize = scaleFontSize({ text: text.value, width: newWidth });
          return handleUpdate({
            newHeight: height,
            newWidth: newFontsize < minFontSize ? (width * minFontSize) / fontSize : newWidth,
            type: "resize",
          });
        }
        const newHeight = height + increment;
        const isMinSize = newHeight < minHeight;
        const widthDenominator = height / (isMinSize ? minHeight : newHeight);

        return handleUpdate({
          newHeight: isMinSize ? minHeight : newHeight,
          newWidth: width / widthDenominator,
          type: "resize",
        });
      }
    : undefined;
}

function makeUpdateEventForEach(
  pspAnnotations: { forEach: (cb: (pspAnno: KitAnnotation) => void) => void },
  callbackLookup: DecorationCallbackLookup,
  makeUpdateEvent: (pspAnnotation: KitAnnotation) => UserUpdateDecorationEvent | { type: "delete" },
) {
  pspAnnotations.forEach((pspAnnotation) => {
    const event = makeUpdateEvent(pspAnnotation);
    return event.type === "delete"
      ? getDeleteCallback(callbackLookup, pspAnnotation)?.()
      : getUpdateCallback(callbackLookup, pspAnnotation)?.(event);
  });
}

function makeIsEditableCallback(
  callbackLookup: DecorationCallbackLookup,
): DecorationContext["isEditable"] {
  return (pspAnnotation) => Boolean(getUpdateCallback(callbackLookup, pspAnnotation));
}

/**
 * This is the real juice behind how "decorations" work in PSPDFKit (note that _Notarize_ annotations and designations
 * are both powered by PDF annotations). The general idea here is that we create an observable that is tied to the lifecycle
 * of a KitInstance. At a higher level, we throw away a "decoration context" when a new instance arrives because the UI switches
 * documents or the user leaves the page. This way we prevent any use of a "stale" instance; it expires with the instance.
 *
 * Additionally, this code also has some other nice properties:
 *  - It attempts to work with PSPDFKit efficiently by batching together operations (notice we use buffer operators to send many
 *    requests to PSPDFKit at once).
 *  - It also attempts to skip unnesscary updates by sampling the "latest update" to a decoration and using the immutable property
 *    of psp annoations to see if any update is even required.
 *  - It ensures we only call delete once per decoration, and no updates come after a delete.
 *
 * The basic design is that when addAnnotation or addDesignation is called by user code, we return to that caller some "handles"
 * that they can use to later update/delete that decoration. The original add call is what is emitted on the createSignal$ "driver
 * observable". Those creation emissions are tied to the handles returned to user code, and we subscribe to futher updates and a
 * single delete as emmissions. Finally those calls are batched and passed to instance create() and delete() calls.
 */
export function expiringNotarizeDecorationContext(
  config: ExpiringDecorationConfig,
): Observable<DecorationContext> {
  return new Observable((observer) => {
    const createSignal$ = new Subject<
      Observable<
        | CreateSignal<DesignationUpdateArgs, KitShapeAnnotation>
        | CreateSignal<AnnotationUpdateArgs>
        | CreateSignal<IndicatorUpdateArgs>
      >
    >();
    const focusCall$ = new BehaviorSubject<string | null>(null);
    const killSwitchCall$ = new Subject<string>();
    const callbackLookup: DecorationCallbackLookup = {
      annotations: {},
      designations: {},
      indicators: {},
    };
    const enterKeySub = fromEvent<KeyboardEvent>(
      config.instance.contentDocument,
      "keydown",
    ).subscribe((event) => {
      const { key } = event;
      const target = event.target as null | HTMLElement;
      if (key === "Enter" && userIsInteractingWithTextAnnotation(target)) {
        target!.blur();
        config.instance.contentWindow.getSelection()!.removeAllRanges();
        config.instance.setSelectedAnnotations(null);
      } else if ((event.ctrlKey || event.metaKey) && BLOCKED_CTRL_KEYS[key]) {
        // Some keyboard ctrl/cmd shortcuts mean something to PSPDFKit or the browser. We don't want that stuff to happen.
        event.preventDefault();
      }
    });

    const { AnnotationsWillChangeReason } = config.module;
    config.instance.addEventListener("annotations.willChange", (event) => {
      switch (event.reason) {
        case AnnotationsWillChangeReason.RESIZE_END:
          return makeUpdateEventForEach(event.annotations, callbackLookup, (pspAnno) => {
            return makeResizeEvent(config.instance, pspAnno);
          });
        case AnnotationsWillChangeReason.TEXT_EDIT_END:
          return makeUpdateEventForEach(event.annotations, callbackLookup, makeEditTextEvent);
        case AnnotationsWillChangeReason.MOVE_END:
          return makeUpdateEventForEach(event.annotations, callbackLookup, (pspAnno) => {
            const originalMoveEvent = makeMoveEvent(config.instance, pspAnno);
            return adjustMoveEventForAnnotation(config.module, pspAnno, originalMoveEvent);
          });
      }
    });

    config.instance.addEventListener("annotations.delete", (annotations) => {
      // Sometimes annotations are deleted via the UI in ways that are "unexpected"
      // (ie if a user focuses an annotation and hits the delete key or if its a text
      // annotation that becomes empty). We need a "kill switch" for these to cleanup
      // observable streams that might be still going, unawares of this change.
      annotations.forEach((pspAnnotation) => {
        // This event will fire for annotations we deleted "normally" too but calling
        // kill switch should be still safe for these annotations. Also, because in
        // the `destroy` callback passed to components, we delete the delete callback
        // so it won't be called after a component has unmounted.
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          killSwitchCall$.next(pspAnnotation.id);
          getDeleteCallback(callbackLookup, pspAnnotation)?.();
        }
      });
    });
    observer.next({
      isEditable: makeIsEditableCallback(callbackLookup),
      getDeleteFn: (pspAnnotation) => {
        if (pspAnnotation.customData?.disableResizeAndEditText) {
          return undefined;
        }
        return getDeleteCallback(callbackLookup, pspAnnotation);
      },
      getCanEditOverride: (pspAnnotation) =>
        getCanEditOverrideCallback(callbackLookup, pspAnnotation),
      getIsLockedDocument: (pspAnnotation) =>
        getIsLockedDocumentCallback(callbackLookup, pspAnnotation),
      getIncrementalResizeFn: (pspAnnotation) => {
        if (pspAnnotation.customData?.disableResizeAndEditText) {
          return undefined;
        }
        return getIncrementalResizeCallback(callbackLookup, pspAnnotation);
      },
      getReassignFn: (pspAnnotation) => {
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          return callbackLookup.designations[notarizeId]?.onReassign;
        }
      },
      getAddToGroupFn: (pspAnnotation) => {
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          return callbackLookup.designations[notarizeId]?.onAddToGroup;
        }
      },
      getAddConditionalFn: (pspAnnotation) => {
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          return callbackLookup.designations[notarizeId]?.onAddConditional;
        }
      },
      getSetOptionalFn: (pspAnnotation) => {
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          return callbackLookup.designations[notarizeId]?.onSetOptional;
        }
      },
      getRenderFn: (pspAnnotation) => {
        const notarizeId = getNotarizeIdFromPspId(pspAnnotation.id);
        if (notarizeId) {
          return (
            callbackLookup.designations[notarizeId]?.onNodeCreate ||
            callbackLookup.indicators[notarizeId]?.onNodeCreate
          );
        }
      },
      setFocused: (notarizeId) => focusCall$.next(notarizeId),
      addAnnotation: (options) => {
        const { annotation } = options;
        const { handlers, updateCall$ } = makeDecorationHandlers<
          AnnotationUpdateArgs,
          AnnotationCallbacks
        >({
          container: callbackLookup.annotations,
          key: annotation.id,
          callbacks: {},
          callbackSetter: (callbacks, updateArgs) => {
            callbacks.onDelete = updateArgs.onDelete;
            callbacks.onUpdate = updateArgs.onUpdate;
            callbacks.isLockedDocument = updateArgs.isLockedDocument;
            callbacks.canEditOverride = updateArgs.canEditOverride;
          },
        });
        createSignal$.next(
          getSafePspPageIndex(config.pageInformationLookup, annotation).pipe(
            switchMap((pspPageIndex) => {
              return createKitAnnotationForAnnotation({
                module: config.module,
                instance: config.instance,
                options,
                checkmarkAttachmentId: config.checkmarkAttachmentId,
                mixin: {
                  id: encodeMoreAttributesInPspId(
                    makePspId(annotation.id),
                    Boolean(options.isPreview) && "preview",
                    "notarize-annotation",
                  ),
                  pageIndex: pspPageIndex,
                  boundingBox: makeBoundingBox(
                    config,
                    annotation,
                    pspPageIndex,
                    getYOffsetAdjustFnForAnnotation(annotation),
                  ),
                },
              });
            }),
            map((pspAnnotation) => ({
              annotation: pspAnnotation,
              updateCall$,
              reducerFn: makeAnnotationReducerFn(config, callbackLookup),
            })),
          ),
        );
        return handlers;
      },
      addIndicator: (options) => {
        const { indicator } = options;
        const { handlers, updateCall$ } = makeDecorationHandlers<
          IndicatorUpdateArgs,
          IndicatorCallbacks
        >({
          container: callbackLookup.indicators,
          key: indicator.id,
          callbacks: { onNodeCreate: options.onNodeCreate },
          callbackSetter: noop,
        });
        createSignal$.next(
          getSafePspPageIndex(config.pageInformationLookup, indicator).pipe(
            map((pspPageIndex) => {
              const id = encodeMoreAttributesInPspId(makePspId(indicator.id), "indicator");
              const boundingBoxFn = indicator.flipY ? makeBoundingBoxFlipY : makeBoundingBox;
              return {
                annotation: createKitAnnotationForIndicator({
                  module: config.module,
                  mixin: {
                    id,
                    pageIndex: pspPageIndex,
                    boundingBox: boundingBoxFn(config, indicator, pspPageIndex, identityAdjustY),
                  },
                }),
                updateCall$,
                reducerFn: makeIndicatorReducerFn(config),
              };
            }),
          ),
        );
        return handlers;
      },
      addDesignation: (options) => {
        const { designation } = options;
        const { handlers, updateCall$ } = makeDecorationHandlers<
          DesignationUpdateArgs,
          EnhancedDesignationCallbacks
        >({
          container: callbackLookup.designations,
          key: designation.id,
          callbacks: { onNodeCreate: options.onNodeCreate },
          callbackSetter: (callbacks, updateArgs) => {
            callbacks.onUpdate = updateArgs.onUpdate;
            callbacks.onDelete = updateArgs.onDelete;
            callbacks.onReassign = updateArgs.onReassign;
            callbacks.onAddToGroup = updateArgs.onAddToGroup;
            callbacks.onAddConditional = updateArgs.onAddConditional;
            callbacks.onSetOptional = updateArgs.onSetOptional;
            callbacks.color = updateArgs.color;
            callbacks.isHighlighted = updateArgs.isHighlighted;
            callbacks.dashedBorder = updateArgs.dashedBorder;
            callbacks.isPrimaryConditional = updateArgs.isPrimaryConditional;
          },
        });
        createSignal$.next(
          getSafePspPageIndex(config.pageInformationLookup, designation).pipe(
            map((pspPageIndex) => {
              return {
                annotation: createKitAnnotationForDesignation({
                  module: config.module,
                  options,
                  mixin: {
                    id: encodeMoreAttributesInPspId(
                      makePspId(designation.id),
                      Boolean(options.isPreview) && "preview",
                      "notarize-designation",
                    ),
                    pageIndex: pspPageIndex,
                    boundingBox: makeBoundingBox(
                      config,
                      designation,
                      pspPageIndex,
                      identityAdjustY,
                    ),
                  },
                }),
                updateCall$,
                reducerFn: makeDesignationReducerFn(config, callbackLookup),
              };
            }),
          ),
        );
        return handlers;
      },
    });
    const operationsSub = createSignal$
      .pipe(
        mergeAll(),
        lazyBufferTime(10),
        mergeMap((createSignals) =>
          createDecorations(
            config.instance,
            createSignals as CreateSignal<unknown>[],
            focusCall$,
            killSwitchCall$,
          ),
        ),
        lazyBufferTime(10),
        concatMap((operations) => {
          const deleteOpIds = new Set(
            operations.filter((op) => op.type === "delete").map((op) => op.pspAnnotation.id),
          );
          const updateOps = operations
            .filter((op) => op.type === "update" && !deleteOpIds.has(op.pspAnnotation.id))
            .reverse()
            .reduce((accum, { pspAnnotation }) => {
              if (!accum.get(pspAnnotation.id)) {
                accum.set(pspAnnotation.id, pspAnnotation);
              }
              return accum;
            }, new Map<string, KitAnnotation>());
          const focusOps = operations.filter(
            (op) => op.type === "focus" && !deleteOpIds.has(op.pspAnnotation.id),
          );

          return concat(
            updateOps.size
              ? deferWithCatch(() => config.instance.update(Array.from(updateOps.values())))
              : EMPTY,
            deleteOpIds.size
              ? deferWithCatch(() => config.instance.delete(Array.from(deleteOpIds)))
              : EMPTY,
            focusOps.length
              ? deferWithCatch(() => {
                  const { pspAnnotation } = focusOps.at(-1)!;
                  if (isMobileDevice()) {
                    // on mobile, we want to zoom in so better see the text you're typing
                    config.instance.jumpAndZoomToRect(
                      pspAnnotation.pageIndex,
                      pspAnnotation.boundingBox.grow(20),
                    );
                  }
                  return of(config.instance.setEditingAnnotation(pspAnnotation.id));
                })
              : EMPTY,
          );
        }),
      )
      .subscribe({ complete: () => focusCall$.complete() });
    return () => {
      operationsSub.unsubscribe();
      enterKeySub.unsubscribe();
    };
  });
}
