import { memo, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { segmentTrack, segmentPage } from "util/segment";
import { SETTINGS_PATH } from "util/routes";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import Apps from "constants/applications";

// Restrict the pages we track on, so as to not overwhelm our Segment data cap
const TRACKED_PATHS = Object.freeze(
  new Set([
    "/signup/starter",
    "/signup/pro",
    "/signup/premium",
    "/signup/upload",
    "/upload",
    "/onboarding",
    SETTINGS_PATH,
  ]),
);

// To be able to add additional data to the page call, manually call on these pages
const SIGNER_ENTRY_POINT = Object.freeze(
  new Set(["/signup/upload", "/upload", "/easy-link", "/activate-transaction"]),
);

function LocationAnalytics() {
  const { pathname } = useLocation();
  useEffect(() => {
    if (TRACKED_PATHS.has(pathname)) {
      segmentTrack("Loaded An App Page");
    }

    const skipPageCall = CURRENT_PORTAL === Apps.CUSTOMER && SIGNER_ENTRY_POINT.has(pathname);
    if (!skipPageCall) {
      segmentPage();
    }
  }, [pathname]);
  return null;
}

export default memo(LocationAnalytics);
