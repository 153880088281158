import { type MutableRefObject } from "react";

import type { SigningAssets } from "common/signer/utils";
import { SignatureMethod, SignatureOptionsFont } from "graphql_globals";
import type { VectorGraphicSubtype } from "common/pdf/interaction";
import { findMaxRemSize, centerAndAlignText } from "util/canvas_text";
import { MAX_SIGNATURE_FONT_SIZE, SIGNATURE_COLOR_HEX } from "constants/globals";
import FONTS, { type FontConfig } from "util/signature_fonts";

export const ENABLE_SIGNATURE_OPTIONS = "enable-signature-options";
export const ENABLE_SIGNATURE_OPTIONS_FONTS = "enable-signature-options-fonts";
export const DEFAULT_FONT_OPTIONS = [
  SignatureOptionsFont.FROMSKYLER,
  SignatureOptionsFont.BETHELLEN,
  SignatureOptionsFont.CAVEAT,
  SignatureOptionsFont.COVEREDBYYOURGRACE,
  SignatureOptionsFont.CASTROSCRIPT,
];

type SignatureOptions = {
  allowAll: boolean;
  allowFonts: FontConfig[];
  allowHandwritten: boolean;
  allowTextbased: boolean;
  isDefault: boolean;
  isEnabled: boolean;
};

export type SignatureOptionsOrganization = {
  id: string;
  signatureOptions: {
    allowFonts: SignatureOptionsFont[];
    allowHandwritten: boolean;
    allowTextbased: boolean;
    default: boolean;
    enabled: boolean;
  };
};

const findConfig = (
  fontList: readonly FontConfig[],
  findFont: string | SignatureOptionsFont,
): FontConfig | undefined => fontList.find((c) => c.font.toUpperCase() === findFont.toUpperCase());

export function getSignatureOptions(organization?: SignatureOptionsOrganization): SignatureOptions {
  const allowFonts: FontConfig[] = [];
  if (organization?.signatureOptions.enabled) {
    organization.signatureOptions.allowFonts.forEach((f) => {
      const config = findConfig(FONTS, f);
      config && allowFonts.push(config);
    });
    const {
      allowHandwritten,
      allowTextbased,
      default: isDefault,
      enabled: isEnabled,
    } = organization.signatureOptions;
    return {
      allowAll: allowHandwritten && allowTextbased,
      allowFonts,
      allowHandwritten,
      allowTextbased,
      isDefault,
      isEnabled,
    };
  }
  FONTS.forEach((c) => allowFonts.push(c));
  return {
    allowAll: true,
    allowFonts,
    allowHandwritten: true,
    allowTextbased: true,
    isDefault: true,
    isEnabled: false,
  };
}

export function forceAssetRecreation({
  organization,
  signingAssets,
  type,
}: {
  organization?: SignatureOptionsOrganization;
  signingAssets?: SigningAssets;
  type: VectorGraphicSubtype;
}): boolean {
  const { allowAll, allowFonts, allowHandwritten, allowTextbased, isDefault, isEnabled } =
    getSignatureOptions(organization);
  // if limiting is not enabled or is the default config
  // return false as we do not want to force recreation
  if (!isEnabled || isDefault) {
    return false;
  }
  if (!allowAll) {
    // first gather the current assetMethod based on type, default is REUSED
    const assetMethod =
      (type === "INITIALS"
        ? signingAssets?.initialsAsset?.method
        : signingAssets?.signatureAsset?.method) || SignatureMethod.REUSED;
    if (
      // if we allowHandwritten then the curent assetMethod must be DRAWN, if not force recreate
      (allowHandwritten && assetMethod !== SignatureMethod.DRAWN) ||
      // if we allowTextbased then the curent assetMethod must be TYPED, if not force recreate
      (allowTextbased && assetMethod !== SignatureMethod.TYPED)
    ) {
      return true;
    }
  }
  // grab the font from initialsAsset or signatureAsset
  // if not there try signingAssets.font
  const fontUsed =
    (type === "INITIALS"
      ? signingAssets?.initialsAsset?.font
      : signingAssets?.signatureAsset?.font) ||
    signingAssets?.font ||
    "";
  // if we cannot find the config for this font from the allowed fonts return true to force recreate
  return !findConfig(allowFonts, fontUsed);
}

export function drawSignatures(
  fonts: readonly FontConfig[],
  canvasRefs: MutableRefObject<(HTMLCanvasElement | null)[]>,
  text?: string,
) {
  if (!text) {
    return;
  }

  fonts.forEach(({ font, padding }, index) => {
    const canvas = canvasRefs.current[index]!;
    const ctx = canvas.getContext("2d")!;
    const { offsetWidth, offsetHeight } = canvas;

    const ratio = Math.max(window.devicePixelRatio, 1);
    const width = offsetWidth * ratio;
    const height = offsetHeight * ratio;

    canvas.width = width;
    canvas.height = height;

    const maxFontSize = findMaxRemSize(
      text,
      font,
      canvas,
      padding * ratio,
      MAX_SIGNATURE_FONT_SIZE * ratio,
    );

    ctx.clearRect(0, 0, width, height);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `normal ${maxFontSize}rem ${font}`;
    ctx.fillStyle = SIGNATURE_COLOR_HEX;
    ctx.strokeStyle = SIGNATURE_COLOR_HEX;
    ctx.lineWidth = ratio;

    // We draw the text and stroke in the middle of the canvas before shifting it down and to the left
    // since fonts designed to mimic handwriting have strange overhangs that aren't accounted for with
    // context.measureText();
    ctx.strokeText(text, width / 2, height / 2);
    ctx.fillText(text, width / 2, height / 2);

    // We draw the canvas once with all of our desired text and strokes, but we want to make sure
    // none of the text is cut off when we left align it.
    centerAndAlignText(text, canvas, ctx);
  });
}
