import { useIntl, defineMessages } from "react-intl";

import {
  Card,
  SectionHeader,
  requiredField,
  setEditableDisplay,
} from "common/transaction_creation/v3/common";
import {
  type SectionContract,
  type SectionComponentProps,
} from "common/transaction_creation/v3/form";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";
import EnoteSection from "common/mortgage/transactions/edit/sub_forms/enote_section";
import { LENDER_HYBRID_TRANSACTION_TYPES } from "constants/transaction";
import { isFeatureEnabled } from "util/feature_detection";
import { ENABLE_ENOTES_IN_HYBRIDS } from "constants/feature_gates";
import { segmentTrack } from "util/segment";
import { EVENT } from "constants/analytics";
import { isGraphQLError } from "util/graphql/query";
import { useRawMutation } from "util/graphql/mutation";
import type { SmartDocsLoans } from "graphql_globals";
import UpdatePaperNoteConsentMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/update_paper_note_consent_mutation.graphql";
import RemoveDocumentFromTransactionMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/remove_document_from_transaction_mutation.graphql";
import UpdateMortgageBorrowerMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/update_mortgage_borrower_mutation.graphql";
import RemoveEnoteSeedMutation from "common/mortgage/transactions/edit/sub_forms/enote_section/remove_enote_seed_mutation.graphql";
import type { parseFormValuesToEnoteSeedFields } from "util/enote_seed";
import { captureException } from "util/exception";
import AddEnoteToTransactionMutation, {
  type AddEnoteToTransaction,
} from "common/mortgage/transactions/edit/sub_forms/enote_section/add_enote_to_transaction_mutation.graphql";
import { RECIPIENTS } from "common/transaction_creation/v3/sections/recipient_details";
import SaveEnoteSeedMutation, {
  type SaveEnoteSeed,
} from "common/mortgage/transactions/edit/sub_forms/enote_section/save_enote_seed_mutation.graphql";

import { scrollToFirstError } from "../../form";
import type { PromissoryNote } from "./transaction_fragment.graphql";
import type { PromissoryNoteOrg } from "./organization_fragment.graphql";

export const CONFIGS = {
  promissoryNote: "promissoryNote",
} as const;

const MESSAGES = defineMessages({
  genericError: {
    id: "90c3e17a-e520-47c8-b7c1-94f9ceb4f03f",
    defaultMessage: "Sorry, something went wrong. Please try again.",
  },
  vaultAuthenticationFailed: {
    id: "2033afd7-37e8-4ac4-9c66-482efaf8f821",
    defaultMessage:
      "We were unable to connect to your vault provider. Please contact your account manager to verify your credentials.",
  },
  unexpectedEnoteSeedError: {
    id: "f4bb202b-81c5-4814-a7c6-f8eb58a19734",
    defaultMessage: "There was an issue creating/updating the enote seed.",
  },
  duplicateMinError: {
    id: "33fe1495-f6ff-4aab-8367-2f6fa064e70d",
    defaultMessage: "This MIN has already been used.",
  },
  promissoryNote: {
    id: "84839292-ff50-43b7-9ca0-88be26e44df3",
    defaultMessage: "Promissory note",
  },
});

export type OnAddEnoteToTransactionReturnType =
  | {
      data?: AddEnoteToTransaction | null;
      error?: never;
    }
  | { data?: never; error: string };

export type OnSaveEnoteSeedReturnType =
  | {
      data?: SaveEnoteSeed | null;
      error?: never;
    }
  | { data?: never; error: string }
  | undefined;

export const PROMISSORY_NOTE_SECTION_ID = "transaction-creation-section-promissory-note";

function PromissoryNoteSection({
  form,
  formId,
  transaction,
  organization,
  config,
  onSendValidationError,
}: SectionComponentProps<PromissoryNote, PromissoryNoteOrg, unknown>) {
  const intl = useIntl();

  const documentBundle = transaction.document_bundle;
  const documents = documentBundle?.documents;
  const enoteSeed = documentBundle?.enoteSeed || undefined;

  const enoteDocument = documents?.edges.find((document) => {
    return document.node.isEnote;
  });

  const SUPPRESS_OPTIONS_ANALYTICS = { suppressAnalytics: true };

  const [updatePaperNoteConsentMutateFn, { loading: paperNoteConsentLoading }] = useRawMutation(
    UpdatePaperNoteConsentMutation,
    {},
    SUPPRESS_OPTIONS_ANALYTICS,
  );

  const [removeDocumentFromTransactionMutateFn, { loading: removeDocumentFromTransactionLoading }] =
    useRawMutation(RemoveDocumentFromTransactionMutation);

  const [addEnoteToTransactionMutateFn, { loading: addEnoteToTransactionLoading }] = useRawMutation(
    AddEnoteToTransactionMutation,
    {},
    SUPPRESS_OPTIONS_ANALYTICS,
  );

  const [updateMortgageBorrowerMutateFn, { loading: updateMortgageBorrowerLoading }] =
    useRawMutation(UpdateMortgageBorrowerMutation);

  const [removeEnoteSeedMutateFn, { loading: removeEnoteSeedLoading }] =
    useRawMutation(RemoveEnoteSeedMutation);
  const [saveEnoteSeedMutateFn, { loading: saveEnoteSeedLoading }] =
    useRawMutation(SaveEnoteSeedMutation);

  const enoteMutationLoading =
    paperNoteConsentLoading ||
    addEnoteToTransactionLoading ||
    removeDocumentFromTransactionLoading ||
    updateMortgageBorrowerLoading ||
    removeEnoteSeedLoading ||
    saveEnoteSeedLoading;

  function genericErrorToast() {
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      message: intl.formatMessage(MESSAGES.genericError),
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      position: "topCenter",
    });
  }

  function onUpdatePaperNoteConsent(paperNoteExists: boolean): void {
    updatePaperNoteConsentMutateFn({
      variables: {
        input: {
          transactionId: transaction.id,
          paperNoteConsent: paperNoteExists,
        },
      },
    })
      .then(() => {
        segmentTrack(EVENT.UPDATE_PAPER_NOTE_CONSENT, {
          paper_note_consent: paperNoteExists,
        });
      })
      .catch((error) => {
        if (isGraphQLError(error)) {
          genericErrorToast();
        } else {
          captureException(error);
        }
      });
  }

  function onAddEnoteToTransaction(
    s3FileHandle: string,
  ): Promise<OnAddEnoteToTransactionReturnType> {
    return addEnoteToTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: transaction.id,
          fileHandle: s3FileHandle,
          organizationId: organization.id,
        },
      },
    }).catch((error) => {
      const genericError = { error: intl.formatMessage(MESSAGES.genericError) };

      if (isGraphQLError(error)) {
        if (error.graphQLErrors[0].message === "authentication_failed") {
          return { error: intl.formatMessage(MESSAGES.vaultAuthenticationFailed) };
        }
        return genericError;
      }

      captureException(error);
      return genericError;
    });
  }

  function onRemoveDocumentFromTransaction(documentId: string): void {
    removeDocumentFromTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: transaction.id,
          documentId,
        },
      },
    }).catch((error) => {
      if (isGraphQLError(error)) {
        genericErrorToast();
      } else {
        captureException(error);
      }
    });
  }

  function onUpdateMortgageBorrower(id: string, ssn?: string): void {
    updateMortgageBorrowerMutateFn({
      variables: {
        input: {
          id,
          ssn,
        },
      },
    }).catch((error) => {
      if (isGraphQLError(error)) {
        genericErrorToast();
      } else {
        captureException(error);
      }
    });
  }

  function onRemoveEnoteSeed(): Promise<unknown> {
    return removeEnoteSeedMutateFn({
      variables: {
        input: {
          documentBundleId: documentBundle!.id,
        },
      },
    }).catch((error) => {
      if (isGraphQLError(error)) {
        genericErrorToast();
      } else {
        captureException(error);
      }
    });
  }

  function onSaveEnoteSeed(
    enoteSeedFields: ReturnType<typeof parseFormValuesToEnoteSeedFields>,
    finalize = false,
  ): Promise<OnSaveEnoteSeedReturnType> {
    const documentBundleId = transaction.document_bundle?.id;

    if (documentBundleId) {
      return saveEnoteSeedMutateFn({
        variables: {
          input: {
            documentBundleId,
            enoteSeedFields,
            finalize,
          },
        },
      }).catch((error) => {
        if (isGraphQLError(error)) {
          switch (error.graphQLErrors[0].message) {
            case "authentication_failed":
              return { error: intl.formatMessage(MESSAGES.vaultAuthenticationFailed) };
            case "duplicate_min":
              return { error: intl.formatMessage(MESSAGES.duplicateMinError) };
            default:
              return { error: intl.formatMessage(MESSAGES.unexpectedEnoteSeedError) };
          }
        }

        captureException(error);
      });
    }

    return Promise.resolve({ error: intl.formatMessage(MESSAGES.genericError) });
  }

  function onInitializeEnoteSeed(smartDocType: SmartDocsLoans): Promise<unknown> {
    return addEnoteToTransactionMutateFn({
      variables: {
        input: {
          organizationTransactionId: transaction.id,
          smartDocType,
          organizationId: organization.id,
        },
      },
    }).catch((error) => {
      if (isGraphQLError(error)) {
        genericErrorToast();
      } else {
        captureException(error);
      }
    });
  }

  async function validateUploadEnote() {
    await form.trigger(RECIPIENTS);

    if (form.getFieldState(RECIPIENTS).invalid) {
      scrollToFirstError(formId);
      return { valid: false };
    }

    return { valid: true };
  }

  return (
    <>
      <SectionHeader iconName="blank-doc" id={PROMISSORY_NOTE_SECTION_ID}>
        {intl.formatMessage(MESSAGES.promissoryNote)}
      </SectionHeader>

      <Card>
        <EnoteSection
          onUpdatePaperNoteConsent={onUpdatePaperNoteConsent}
          paperNoteConsent={Boolean(transaction.paperNoteConsent)}
          enoteMutationLoading={enoteMutationLoading}
          onAddEnoteToTransaction={onAddEnoteToTransaction}
          onRemoveDocumentFromTransaction={onRemoveDocumentFromTransaction}
          onUpdateMortgageBorrower={onUpdateMortgageBorrower}
          onRemoveEnoteSeed={onRemoveEnoteSeed}
          onSaveEnoteSeed={onSaveEnoteSeed}
          onInitializeEnoteSeed={onInitializeEnoteSeed}
          enoteDocument={enoteDocument?.node}
          enoteSeed={enoteSeed}
          checkCanAddEnote={validateUploadEnote}
          isRequired={requiredField(config, CONFIGS.promissoryNote)}
          enoteWarning={onSendValidationError}
        />
      </Card>
    </>
  );
}

export const PROMISSORY_NOTE_SECTION = {
  Component: PromissoryNoteSection,
  configs: CONFIGS,
  modifyConfig({ sectionConfig, transaction }) {
    const modifiedConfig = { ...sectionConfig };

    if (
      transaction.transactionType &&
      Object.values(LENDER_HYBRID_TRANSACTION_TYPES).includes(transaction.transactionType) &&
      isFeatureEnabled(transaction.organization, ENABLE_ENOTES_IN_HYBRIDS)
    ) {
      setEditableDisplay(modifiedConfig, "promissoryNote");
    }

    return modifiedConfig;
  },
} satisfies SectionContract<
  Record<never, never>,
  Record<never, never>,
  PromissoryNote,
  PromissoryNoteOrg,
  unknown,
  typeof CONFIGS
>;
