import { useEffect, type MutableRefObject } from "react";
import { fromEvent, Observable } from "rxjs";

function hasResizeObserver() {
  return typeof ResizeObserver !== "undefined";
}

function createResizeObserverObservable<T extends Element>(
  element: T,
  box?: ResizeObserverBoxOptions,
): Observable<void> {
  return new Observable<void>((subscriber) => {
    const resizeObserver = new ResizeObserver(() => {
      subscriber.next();
    });
    resizeObserver.observe(element, { box });

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  });
}

type UseResizeObserverOptionsType<T> = {
  ref: MutableRefObject<T | undefined | null>;
  box?: ResizeObserverBoxOptions;
  onResize: () => void;
};

export function useResizeObserver<T extends Element>(options: UseResizeObserverOptionsType<T>) {
  const { ref, box, onResize } = options;

  useEffect(() => {
    const element = ref.current;
    if (!element) {
      return;
    }

    let resizeObservable: Observable<void | Event>;

    if (hasResizeObserver()) {
      resizeObservable = createResizeObserverObservable(element, box);
    } else {
      resizeObservable = fromEvent(window, "resize");
    }

    const subscription = resizeObservable.subscribe(onResize);

    return () => {
      subscription.unsubscribe();
    };
  }, [onResize, ref, box]);
}
