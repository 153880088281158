import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { Heading, Paragraph } from "common/core/typography";
import { Badge } from "common/core/badge";
import Icon from "common/core/icon";
import { useFeatureFlag } from "common/feature_gating";
import { MeetingAnalysisRiskLevel } from "graphql_globals";

import type { DeepfakeRiskScoreMeetingAnalysisResult } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

type Props = {
  result: DeepfakeRiskScoreMeetingAnalysisResult;
};

function RiskBadge({
  riskLevel,
  withIcon = true,
  className,
}: {
  riskLevel: MeetingAnalysisRiskLevel;
  withIcon?: boolean;
  className?: string;
}) {
  switch (riskLevel) {
    case MeetingAnalysisRiskLevel.LOW_RISK:
      return (
        <Badge className={className} withIcon={withIcon ? "success-filled" : undefined}>
          <FormattedMessage id="afe29ca6-fb1e-4a08-86cc-ce1cd439a0e4" defaultMessage="Not fake" />
        </Badge>
      );
    case MeetingAnalysisRiskLevel.LOW_QUALITY:
      return (
        <Badge className={className} kind="warning" withIcon={withIcon ? "warning" : undefined}>
          <FormattedMessage
            id="e2ae6f0f-70a3-4882-9a63-516df98342f0"
            defaultMessage="Low quality"
          />
        </Badge>
      );
    case MeetingAnalysisRiskLevel.HIGH_RISK:
      return (
        <Badge className={className} kind="danger" withIcon={withIcon ? "doc-warning" : undefined}>
          <FormattedMessage
            id="4a7bd7a1-0496-4446-acb3-cb7430276adc"
            defaultMessage="Fake detected"
          />
        </Badge>
      );
    default:
      return null;
  }
}

export function MeetingRiskLevelBadge({
  meetingAnalysis,
  className,
}: {
  meetingAnalysis: {
    riskLevel: MeetingAnalysisRiskLevel | null;
  } | null;
  className?: string;
}) {
  const deepfakeAnalysisEnabled = useFeatureFlag("deepfake-analysis");
  if (!deepfakeAnalysisEnabled || !meetingAnalysis?.riskLevel) {
    return null;
  }
  return <RiskBadge className={className} riskLevel={meetingAnalysis.riskLevel} withIcon={false} />;
}

export function RiskScore({ result: { riskLevel } }: Props) {
  const [showDescription, setShowDescription] = useState(false);
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <Heading textStyle="headingSix" level="h3" textColor="white">
          <FormattedMessage
            id="f86c9d59-2b37-4324-a2ea-812891f30938"
            defaultMessage="Deepfake risk score"
          />
        </Heading>
        <Paragraph textColor="white">
          <FormattedMessage
            id="f86c9d59-2b37-4324-a2ea-812891f30938"
            defaultMessage="This score shows if the recipient has potentially tampered with their video feed."
          />
        </Paragraph>
        <div className={Styles.badge}>
          <RiskBadge riskLevel={riskLevel} />
        </div>
      </div>
      <div
        className={Styles.collapseTrigger}
        onClick={() => {
          setShowDescription(!showDescription);
        }}
      >
        <Paragraph size="small">
          <FormattedMessage
            id="89a10bcd-3341-4815-b868-e99c9352ac13"
            defaultMessage="The risk score is determined by a combination of measurements"
          />
        </Paragraph>
        <Icon name={showDescription ? "caret-up" : "caret-down"} />
      </div>
      {showDescription && (
        <div className={Styles.description}>
          <Heading textStyle="headingSix" level="h4">
            <FormattedMessage
              id="2082e2cd-7b7b-462a-89ce-1036e42f295c"
              defaultMessage="Low quality"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="52db36f7-c46b-4f84-88f0-4442f0afed4e"
              defaultMessage="Detects video abnormalities such as partial face visibility, low resolution, noise, and darkness."
            />
          </Paragraph>
          <Heading textStyle="headingSix" level="h4">
            <FormattedMessage
              id="781b82c3-ca3c-47aa-85d9-fe0d431c3955"
              defaultMessage="Reenactment"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="d6b1a31f-f091-48bc-a4a0-4dd1949d7108"
              defaultMessage="Measures the recipient’s facial and body movement to determine liveness."
            />
          </Paragraph>
          <Heading textStyle="headingSix" level="h4">
            <FormattedMessage
              id="cc653c3b-09f9-4595-ada3-b4cee90d8d1a"
              defaultMessage="Face swap"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="633968fd-44a9-477a-bf3f-ff9ed55bc465"
              defaultMessage="Detects if a recipient’s face has been replaced in real time."
            />
          </Paragraph>
          <Heading textStyle="headingSix" level="h4">
            <FormattedMessage
              id="deea25c1-90bf-48ea-945e-9a30e8d404e9"
              defaultMessage="Legitimacy"
            />
          </Heading>
          <Paragraph>
            <FormattedMessage
              id="44c443cc-7547-4ce7-b9de-8a118420cdc8"
              defaultMessage="Analyzes the video feed to determine whether the subject seems legitimate."
            />
          </Paragraph>
        </div>
      )}
    </div>
  );
}
