import { type Dispatch, type SetStateAction, type ReactNode } from "react";

import { scrollAndFlashError } from "../common";
import { type ValidationsConfig, type Config } from "../config";
import { validateOrgPayment } from "./org_payment";
import { validateDocuments } from "./documents";
import { validateRecipientDetails } from "./recipient_details";
import { validateDocumentSignatures } from "./document_signatures";
import { validateNSTCompliance } from "./nst_compliance";
import { validateStateEligibility } from "./state_eligibility";
import { validateENote } from "./enote";
import { validateRecall } from "./recall";

export type Validation<
  TransactionFragment = unknown,
  OrganizationFragment = unknown,
  ViewerFragment = unknown,
> = (args: {
  transaction: TransactionFragment;
  organization: OrganizationFragment;
  viewer: ViewerFragment;
  config: Config;
  setSubmitErrorModal: Dispatch<SetStateAction<ReactNode>>;
}) => Promise<
  { status: "passed"; recallReason?: string } | { status: "failed"; error?: string | null }
>;

type ValidationArgs = Parameters<typeof validateOrgPayment>[0] &
  Parameters<typeof validateDocuments>[0] &
  Parameters<typeof validateDocumentSignatures>[0] &
  Parameters<typeof validateNSTCompliance>[0] &
  Parameters<typeof validateRecipientDetails>[0] &
  Parameters<typeof validateStateEligibility>[0] &
  Parameters<typeof validateENote>[0] &
  Parameters<typeof validateRecall>[0];

export function scrollToSection(elementId: string) {
  const element = document.getElementById(elementId);
  if (element) {
    scrollAndFlashError(element);
  }
}

export function constructValidations<
  Transaction extends ValidationArgs["transaction"],
  Organization extends ValidationArgs["organization"],
  Viewer extends ValidationArgs["viewer"],
>(configValidations: ValidationsConfig): Validation<Transaction, Organization, Viewer>[] {
  const validations = [];

  // Add validations in order they should be run. They will be looped through and it'll wait for each
  // validation to finish before calling the next one. It will short circuit if the validation rejects.
  // These validations could be added/removed based on config/org/other factors
  if (configValidations.validateRecipientDetails) {
    validations.push(validateRecipientDetails);
  }

  if (configValidations.validateOrgPayment) {
    validations.push(validateOrgPayment);
  }

  if (configValidations.validateDocuments) {
    validations.push(validateDocuments);
  }

  if (configValidations.validateDocumentSignatures) {
    validations.push(validateDocumentSignatures);
  }

  if (configValidations.validateNSTCompliance) {
    validations.push(validateNSTCompliance);
  }

  if (configValidations.validateStateEligibility) {
    validations.push(validateStateEligibility);
  }

  if (configValidations.validateENote) {
    validations.push(validateENote);
  }

  validations.push(validateRecall);

  return validations;
}
