import { useCallback, useRef, type ReactNode } from "react";
import { useIntl, defineMessages, type IntlShape } from "react-intl";
import classnames from "classnames";
// eslint-disable-next-line no-restricted-imports
import get from "lodash/get";

import { validateEmail } from "common/core/warnings/email_typo";
import {
  useFormState,
  type FieldError,
  type FieldPath,
  type FieldValues,
  type Control,
  type ValidateResult,
  type ValidationRule,
} from "common/core/form";
import { useA11y } from "common/accessibility";

import Styles from "./index.module.scss";

// We want a11y components!
export type AriaInvalidPropValue = "true" | "false" | boolean;
export type AriaRequired<Orig> = Omit<Orig, "aria-invalid"> & {
  "aria-invalid": AriaInvalidPropValue;
};
const EMAIL_VALIDATION_DEBOUNCE_TIME = 500;
type FieldErrorMessageProps = {
  inputName: string;
  className?: string;
  message: ReactNode;
};
type FormattedFieldErrorProps = Omit<FieldErrorMessageProps, "message"> & {
  error?: FieldError;
  className?: string;
};

const VALIDATION_MESSAGES = defineMessages({
  default: {
    id: "d4b48a5f-4493-408e-851d-aff234c47d58",
    defaultMessage: "This doesn't look right",
  },
  required: {
    id: "cd701f9c-5c2b-4005-9611-2e2368270726",
    defaultMessage: "This is required",
  },
  atLeastOne: {
    id: "be4acf68-39cd-41dc-b2a9-b257001134f1",
    defaultMessage: "At least one of these is required",
  },
  emailPattern: {
    id: "d87ba5b2-b45d-45a1-8290-fb422293e987",
    defaultMessage: "This email doesn't look right",
  },
  domainPattern: {
    id: "69fabf51-844e-4595-972b-5993b65a6c9c",
    defaultMessage: "This domain doesn't look right",
  },
  invalidEmailDomain: {
    id: "59fbadad-66ee-46b3-967c-6f328c1fddd8",
    defaultMessage: "This email looks wrong, did you mean {didYouMean}?",
  },
});

export function defaultRequiredMessage(intl: IntlShape) {
  return intl.formatMessage(VALIDATION_MESSAGES.required);
}

export function atLeastOneRequiredMessage(intl: IntlShape) {
  return intl.formatMessage(VALIDATION_MESSAGES.atLeastOne);
}

export function emailPatternValidation(intl: IntlShape): ValidationRule<RegExp> {
  return {
    message: intl.formatMessage(VALIDATION_MESSAGES.emailPattern),
    // WARNING: This is purposefully simple. You cannot hope to validate emails correctly by characters alone.
    // Think deeply about expected value before making this more complex. Make sure your flow allows the user
    // to correct mistakes in mistyped emails (resend actions, edit email, etc) that _will_ happen regardless
    // of how fancy this regex is.
    value: /^.+@.+\..+$/,
  };
}

export function domainPatternValidation(intl: IntlShape): ValidationRule<RegExp> {
  // Domain name must start with an alphanumeric character.
  // Capture groups following start with - or . and then alphanumeric characters
  // Must have one period '.'
  // TLD must be at least 2 characters and only be letters
  // Each label has a limit of 63
  // A valid domain can be 253 chars. ! This is not validated here as it should be a different error msg !
  return {
    message: intl.formatMessage(VALIDATION_MESSAGES.domainPattern),
    value: /^[a-z0-9]{1,63}([-.]{1}[a-z0-9]{1,62})*\.[a-z]{2,63}$/i,
  };
}

// be wary of using string arguments - Boolean("false") --> true
export function isAriaInvalid(isInvalid: unknown): "true" | "false" {
  return Boolean(isInvalid).toString() as "true" | "false";
}

export function useEmailValidator(): (email: unknown) => Promise<ValidateResult> {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined);
  const emailRef = useRef<{ value: unknown; result: true | string }>({ value: "", result: true });
  const intl = useIntl();

  return useCallback((email: unknown) => {
    clearTimeout(timeoutRef.current);

    return new Promise((resolve) => {
      function resolveAndSaveResult(email: unknown, result: true | string) {
        emailRef.current = { value: email, result };
        resolve(result);
      }

      if (!email) {
        return resolve(true);
      }

      if (email === emailRef.current.value) {
        return resolve(emailRef.current.result);
      }

      timeoutRef.current = setTimeout(async () => {
        const response = await validateEmail(email);

        if (response === null) {
          return resolveAndSaveResult(email, true);
        }

        const { did_you_mean: didYouMean } = response;

        if (didYouMean) {
          return resolveAndSaveResult(
            email,
            intl.formatMessage(VALIDATION_MESSAGES.invalidEmailDomain, {
              didYouMean,
            }),
          );
        }

        resolveAndSaveResult(email, intl.formatMessage(VALIDATION_MESSAGES.emailPattern));
      }, EMAIL_VALIDATION_DEBOUNCE_TIME);
    });
  }, []);
}

/** Use this function to add describedby attrs to a field with FieldErrorMessage */
export function useAriaErrorDescribedId(inputProps: {
  name?: string;
  "aria-describedby"?: string;
}): string | undefined {
  const { useLabelledOrDescribedBy } = useA11y();
  return (
    classnames(useLabelledOrDescribedBy(inputProps.name), inputProps["aria-describedby"]) ||
    undefined
  );
}

export function FieldErrorMessage({ message, className, inputName }: FieldErrorMessageProps) {
  // This is always on display so as to not make the page jump when the message arrives.
  // All validation errors should be aria-describedby the associated input
  const { useRegisteredId } = useA11y();
  const hasMessage = Boolean(message);
  const fieldErrorId = useRegisteredId(hasMessage && inputName);
  return <ErrorMessage id={fieldErrorId} className={className} message={message} />;
}

export function ErrorMessage(props: { id?: string; message: ReactNode; className?: string }) {
  return (
    <span id={props.id} className={classnames(Styles.error, props.className)} role="alert">
      {props.message}
    </span>
  );
}

export function FormattedFieldError({ error, ...rest }: FormattedFieldErrorProps) {
  const intl = useIntl();
  return (
    <FieldErrorMessage
      {...rest}
      message={error ? error.message || intl.formatMessage(VALIDATION_MESSAGES.default) : null}
    />
  );
}

export function useNestedError<FormValues extends FieldValues, ErrorShape = FieldError>({
  control,
  name,
}: {
  control: Control<FormValues>;
  name: FieldPath<FormValues>;
}) {
  return get(useFormState({ control, name }).errors, name) as
    | undefined
    | (ErrorShape extends FieldError ? ErrorShape : { [key in keyof ErrorShape]?: FieldError });
}
