import { memo } from "react";
import { FormattedMessage } from "react-intl";

import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { useTxnDetailsRedesign } from "util/feature_detection";
import type { VideoProvider } from "graphql_globals";
import { DeprecatedDetailGridRow } from "common/details/grid/row";
import { DescriptionListItem } from "common/core/description_list";

type Props = {
  meeting: { video: null | { id: string; provider: VideoProvider } };
};

const UNAVAILABLE = (
  <FormattedMessage id="1d8b7a2f-8f38-453a-bc85-6144ab005db6" defaultMessage="Unavailable" />
);

function VideoSessionId({ meeting }: Props) {
  const { video } = meeting;
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  const serviceProviderLabel = (
    <FormattedMessage
      id="b2310ea2-634a-4691-8a67-ddda644c7b5e"
      defaultMessage="Video service provider"
    />
  );
  const videoSessionIdLabel = (
    <FormattedMessage id="cc3fb8ec-9ae4-445a-9293-ca9253ee2d76" defaultMessage="Video service ID" />
  );

  const serviceProviderContent = video?.provider ? video.provider : UNAVAILABLE;
  const videoSessionIdContent = video?.id ? video.id : UNAVAILABLE;

  return isTxnDetailsRedesign ? (
    <>
      <DescriptionListItem term={serviceProviderLabel} definition={serviceProviderContent} />
      <DescriptionListItem term={videoSessionIdLabel} definition={videoSessionIdContent} />
    </>
  ) : (
    <div>
      <DeprecatedDetailGridRow title={serviceProviderLabel}>
        <span className="DetailGrid--scrolling-row DetailGrid--scrolling-row--single">
          {serviceProviderContent}
        </span>
      </DeprecatedDetailGridRow>
      <DeprecatedDetailGridRow title={videoSessionIdLabel}>
        <span className="DetailGrid--scrolling-row DetailGrid--scrolling-row--single">
          {videoSessionIdContent}
        </span>
      </DeprecatedDetailGridRow>
    </div>
  );
}

export default memo(VideoSessionId);
