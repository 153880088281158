import { timer, map, switchMap, distinctUntilChanged, NEVER, merge } from "rxjs";
import { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";

import { useFeatureFlag } from "common/feature_gating";
import { TIMEOUT_MODAL } from "constants/feature_gates";
import { UNAUTHORIZED_ERROR } from "errors/server";
import { captureException } from "util/exception";
import request from "util/request";
import { useBehaviorSubject, useSubject } from "util/rxjs/hooks";

import { getEncodedLoginUrlWithSearchParams, useLogout } from "..";
import ReconnectModal from "./reconnect_modal";
import TimeoutModal from "./timeout_modal";

type TimeoutAction =
  | { action: "logout" }
  | { action: "timeout_warning" | "reconnect_warning"; seconds: number }
  | null;
type TimeoutResponse = {
  ttl: number;
  max_ttl: number;
};
type Props = {
  isAuthenticated: boolean;
};

const INTERVAL = 30_000; // 30 seconds
const TIMEOUT_MARK = 300; // 5 minutes
const RECONNECT_MARK = 14_400; // 4 hours
const LEEWAY = 10; // seconds

function makeRequest(): Promise<TimeoutResponse> {
  return request("get", "oauth/timeout").catch((err) => {
    if (err?.type === UNAUTHORIZED_ERROR) {
      return { ttl: 0, max_ttl: 0 };
    }
    console.error("timeout_modal error", err); // eslint-disable-line no-console
    return {};
  });
}

// Subtract LEEWAY to the TTLs in order to log the user out before his token
// actually expires (and the cookie deleted from the browser). This is to
// prevent a race condition between the logout timer and GraphQL requests.
// If the cookie gets deleted and a GraphQL request fires, it often results in a
// "Something went wrong" (null exception) page. If we log the user out preemptively,
// we can mitigate that.
function makeTimeoutAction(data: TimeoutResponse): TimeoutAction {
  console.info("timeout_modal", data); // eslint-disable-line no-console
  if (data.ttl === 0 || data.max_ttl === 0) {
    return { action: "logout" };
  } else if (data.max_ttl <= RECONNECT_MARK) {
    return { action: "reconnect_warning", seconds: Math.max(data.max_ttl - LEEWAY, 0) };
  } else if (data.ttl <= TIMEOUT_MARK) {
    return { action: "timeout_warning", seconds: Math.max(data.ttl - LEEWAY, 0) };
  }
  return null;
}

function refreshToken() {
  return request("post", "oauth/token", {
    grant_type: "refresh_token",
  });
}

export default function Timeout({ isAuthenticated }: Props) {
  const timeoutModalEnabled = useFeatureFlag(TIMEOUT_MODAL);
  const loginUrl = getEncodedLoginUrlWithSearchParams({
    redirectUrl: window.location.href.replace(window.location.origin, ""),
  });
  const isMeetingPath = useMatch({ path: "/meeting/:meetingId", end: true });
  const isNotaryMeetingPath = useMatch({ path: "/notary-meeting/:meetingId", end: true });
  const isRefereeMeetingPath = useMatch({ path: "/referee-meeting/:meetingId", end: true });
  const logout = useLogout({ redirectUrl: loginUrl });
  const refetch$ = useSubject();
  const isAuthenticated$ = useBehaviorSubject(isAuthenticated);
  const [timeoutAction, setTimeoutAction] = useState<TimeoutAction>(null);

  useEffect(() => {
    const timeouts$ = isAuthenticated$.pipe(
      distinctUntilChanged(),
      switchMap((isAuthenticated) => {
        return isAuthenticated
          ? merge(refetch$, timer(0, INTERVAL)).pipe(switchMap(makeRequest))
          : NEVER;
      }),
      map(makeTimeoutAction),
      distinctUntilChanged((previous, current) => previous?.action === current?.action),
    );
    if (timeoutModalEnabled) {
      const sub = timeouts$.subscribe((data) => {
        if (data?.action === "logout") {
          logout().catch(captureException);
        } else {
          setTimeoutAction(data);
        }
      });
      return () => sub.unsubscribe();
    }
  }, []);
  useEffect(() => {
    isAuthenticated$.next(isAuthenticated);
  });

  // If the user is in a meeting, we want to keep the timeout logic running
  // but we don't want to display any modals until the user leaves the meeting.
  if (isMeetingPath || isNotaryMeetingPath || isRefereeMeetingPath) {
    return null;
  } else if (timeoutAction?.action === "reconnect_warning") {
    return <ReconnectModal onLogout={() => logout().catch(captureException)} />;
  } else if (timeoutAction?.action === "timeout_warning") {
    return (
      <TimeoutModal
        onLogin={() =>
          refreshToken()
            .then(() => refetch$.next(null))
            .catch(captureException)
        }
        onLogout={() => logout().catch(captureException)}
        seconds={timeoutAction.seconds}
      />
    );
  }

  return null;
}
