import { type ReactNode, useState } from "react";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import classnames from "classnames";

import { DocumentAnchorStrategy } from "graphql_globals";
import Link from "common/core/link";
import Icon from "common/core/icon";
import { SUPPORT_HOST } from "constants/support";
import ActionButton from "common/core/action_button";
import Button from "common/core/button";
import { useForm } from "common/core/form";
import { TextInput } from "common/core/form/text";
import { useFeatureFlag } from "common/feature_gating";
import { useMutation } from "util/graphql";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { pushNotification } from "common/core/notification_center/actions";
import { Paragraph } from "common/core/typography";
import { useId } from "util/html";
import { IconButton } from "common/core/button/icon_button";
import AlertMessage from "common/core/alert_message";
import { isGraphQLError } from "util/graphql/query";
import { captureException } from "util/exception";
import { FieldErrorMessage } from "common/core/form/error";
import { FormRow } from "common/core/form/layout";

import Styles from "./index.module.scss";
import UpsertDocumentAnchorMutation from "./upsert_document_anchor_mutation.graphql";

type AnchorTextFormValues = {
  anchorTextValue: string;
};

type AdditionalMessage = {
  type: "error" | "warning";
  message: ReactNode;
};

type AnchorTextAnnotationOrDesignation = { id: string; documentAnchor?: { anchorText: string } };

const MESSAGES = defineMessages({
  anchorTextValue: {
    id: "5a6610df-cf1f-4412-89ed-7c989f911d18",
    defaultMessage: "anchor text value",
  },
  genericError: {
    id: "7b0f046a-3054-453d-bd9f-39229116cbcb",
    defaultMessage: "Sorry, an error occured. Please try again.",
  },
  documentAnchorTextNotFound: {
    id: "d294e21c-1cae-4f6e-96f4-3dd7bbe0ff85",
    defaultMessage: "This text does not exist within the document.",
  },
  success: {
    id: "21156ad3-7bba-4840-b2c1-bb19d4e26b1f",
    defaultMessage: "Anchor text updated",
  },
});

export function Section({
  disabled = false,
  children,
}: {
  disabled?: boolean;
  children: ReactNode;
}) {
  return (
    <section className={classnames(Styles.section, disabled && Styles.sectionDisabled)}>
      {children}
    </section>
  );
}

export function SectionHeader({
  title,
  automationId = "additional-tools-section-header",
  headerAction = null,
  isOpen,
  toggleSection,
  sectionId,
}:
  | {
      title: ReactNode;
      automationId?: string;
      headerAction: ReactNode;
      isOpen?: never;
      toggleSection?: never;
      sectionId?: never;
    }
  | {
      title: ReactNode;
      automationId?: string;
      headerAction?: never;
      isOpen: boolean;
      toggleSection: (newValue: boolean) => void;
      sectionId: string;
    }) {
  return headerAction ? (
    <div className={Styles.sectionHeader} data-automation-id={automationId}>
      <div className={Styles.sectionHeaderTitle}>{title}</div>
      {headerAction}
    </div>
  ) : (
    <ActionButton
      className={classnames(Styles.sectionHeader, {
        [Styles.sectionHeaderClickable]: Boolean(toggleSection),
      })}
      onClick={() => {
        toggleSection && toggleSection(!isOpen);
      }}
      color="dark"
      aria-expanded={isOpen}
      aria-controls={sectionId}
      automationId={automationId}
    >
      <div className={Styles.sectionHeaderTitle}>{title}</div>
      {Boolean(toggleSection) && <Icon name={isOpen ? "caret-up" : "caret-down"} />}
    </ActionButton>
  );
}

export function DeleteButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton
      name="delete"
      variant="secondary"
      buttonColor="danger"
      label={
        <FormattedMessage
          id="00907236-78ec-40d1-b85d-0db8495e8447"
          defaultMessage="Delete selected field"
        />
      }
      onClick={onClick}
    />
  );
}

export function Footer({ children }: { children: ReactNode }) {
  return (
    <div className={Styles.footer}>
      <div className={Styles.footerItems}>{children}</div>
    </div>
  );
}

export function AnchorText({
  selectedDesignation,
  selectedAnnotation,
}:
  | {
      selectedDesignation: AnchorTextAnnotationOrDesignation;
      selectedAnnotation?: never;
    }
  | {
      selectedDesignation?: never;
      selectedAnnotation: AnchorTextAnnotationOrDesignation;
    }) {
  const intl = useIntl();
  const sectionId = useId();
  const isFeatureEnabled = useFeatureFlag("anchor-text-fields");

  // we want to allow edit right away if no anchor text yet
  const [editMode, setEditMode] = useState(
    !selectedDesignation?.documentAnchor?.anchorText &&
      !selectedAnnotation?.documentAnchor?.anchorText,
  );
  const [showContent, setShowContent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);
  // const [warningMessage, setWarningMessage] = useState<ReactNode | null>(null);
  const [additionalMessage, setAdditionalMessage] = useState<AdditionalMessage | null>(null);
  const existingAnchorText = (selectedDesignation || selectedAnnotation).documentAnchor?.anchorText;
  const upsertDocumentAnchor = useMutation(UpsertDocumentAnchorMutation);
  const { register, watch, setValue } = useForm<AnchorTextFormValues>({
    defaultValues: {
      anchorTextValue: existingAnchorText || "",
    },
  });
  const anchorTextValue = watch("anchorTextValue");

  const pushErrorNotification = () => {
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      subtype: NOTIFICATION_SUBTYPES.ERROR,
      message: intl.formatMessage(MESSAGES.genericError),
      position: "topCenter",
    });
  };

  const onSave = async () => {
    setIsLoading(true);
    setAdditionalMessage(null);
    try {
      const result = await upsertDocumentAnchor({
        variables: {
          input: {
            annotationDesignationId: selectedDesignation?.id,
            annotationId: selectedAnnotation?.id,
            strategy: DocumentAnchorStrategy.FIRST,
            anchorData: { text: anchorTextValue },
          },
        },
      });
      if (result.data?.upsertDocumentAnchor?.message === "multiple_anchor_positions") {
        setAdditionalMessage({
          type: "warning",
          message: (
            <AlertMessage kind="warning">
              <FormattedMessage
                id="f72149c3-1bc9-48bf-a575-0a252f2980b9"
                defaultMessage="This text is not unique. The first instance of this text on the document will be used as the anchor."
              />
            </AlertMessage>
          ),
        });
      }
      pushNotification({
        type: NOTIFICATION_TYPES.DEFAULT,
        subtype: NOTIFICATION_SUBTYPES.SUCCESS,
        message: intl.formatMessage(MESSAGES.success),
        position: "topCenter",
      });
      setEditMode(false);
    } catch (error) {
      const isGraphError = isGraphQLError(error);
      if (isGraphError) {
        if (error.graphQLErrors[0].message === "document_anchor_text_not_found") {
          setAdditionalMessage({
            type: "error",
            message: intl.formatMessage(MESSAGES.documentAnchorTextNotFound),
          });
        } else {
          pushErrorNotification();
        }
      } else {
        captureException(error);
        pushErrorNotification();
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (!isFeatureEnabled) {
    return null;
  }

  return (
    <Section>
      <SectionHeader
        title={
          <FormattedMessage
            id="1ca7904e-7c42-444a-962c-ff132e6987d9"
            defaultMessage="Anchor text"
          />
        }
        isOpen={showContent}
        toggleSection={setShowContent}
        sectionId={sectionId}
        automationId="anchor-text-section-header"
      />
      {showContent && (
        <div className={Styles.anchorSection} id={sectionId}>
          <Paragraph textColor="subtle" size="small">
            <FormattedMessage
              id="da9a9a90-0530-4304-ae52-b2e08847df18"
              defaultMessage="The field will anchor to the first instance of matching text on the page. <link>Learn more</link>"
              values={{
                link: (text) => (
                  <Link href={`${SUPPORT_HOST}/hc/en-us/articles/24817258676375-Anchor-Tags`}>
                    {text}
                  </Link>
                ),
              }}
            />
          </Paragraph>
          {additionalMessage?.type === "warning" && additionalMessage.message}
          <FormRow fullWidth>
            <TextInput
              aria-invalid={Boolean(additionalMessage?.type === "error")}
              aria-label={intl.formatMessage(MESSAGES.anchorTextValue)}
              {...register("anchorTextValue")}
              disabled={!editMode || isLoading}
              data-automation-id="anchor-text-input"
            />
            {additionalMessage?.type === "error" && (
              <FieldErrorMessage
                className={Styles.formError}
                inputName="anchorTextValue"
                message={additionalMessage.message}
              />
            )}
          </FormRow>
          {editMode ? (
            <div className={Styles.anchorButtons}>
              {existingAnchorText && (
                <Button
                  buttonColor="dark"
                  variant="tertiary"
                  onClick={() => {
                    setValue("anchorTextValue", existingAnchorText || "");
                    setEditMode(false);
                  }}
                >
                  <FormattedMessage
                    id="c04b6d69-aba4-4b2b-bcc6-84b54503bd06"
                    defaultMessage="Cancel"
                  />
                </Button>
              )}
              <Button
                buttonColor="action"
                variant="secondary"
                onClick={onSave}
                disabled={(anchorTextValue.length === 0 && !existingAnchorText) || isLoading}
                data-automation-id="anchor-text-save-button"
              >
                <FormattedMessage id="6ee7a7fd-053b-411d-80c8-2fb525af7f1d" defaultMessage="Save" />
              </Button>
            </div>
          ) : (
            <Button
              buttonColor="action"
              variant="tertiary"
              onClick={() => {
                setEditMode(true);
              }}
              withIcon={{ name: "pencil-filled", placement: "left" }}
              data-automation-id="anchor-text-edit-button"
            >
              <FormattedMessage id="c7571295-9533-4ab9-b8be-20bba32624a0" defaultMessage="Edit" />
            </Button>
          )}
        </div>
      )}
    </Section>
  );
}
