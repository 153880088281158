// This function returns an abbreviated user name for display purposes.
// It will ensure the name is no longer than maxTotalLength and that each word is no longer than maxWordLength.
// It will:
// 1. Shorten any words if needed (each first/middle/last name can be multiple words)
// 2. Turn the middle name into a middle initial
// 3. Shorten the last name as much as needed, up to an initial
// 4. If still too long, shorten the first name as much as needed
export function abbreviatedUserName(
  user: { firstName: string; middleName?: string; lastName: string },
  maxTotalLength: number,
  maxWordLength?: number,
) {
  const wordLength = maxWordLength || maxTotalLength;

  const abbreviatedWord = (word: string): string =>
    word.length > wordLength ? `${word.slice(0, wordLength - 1)}.` : word;

  const abbreviatedName = (name: string): string => name.split(" ").map(abbreviatedWord).join(" ");

  const initial = (name: string): string => (name ? `${name[0].toUpperCase()}.` : "");

  let abbrFirst = abbreviatedName(user.firstName);
  let abbrMiddle = user.middleName ? abbreviatedName(user.middleName) : null;
  let abbrLast = abbreviatedName(user.lastName);

  // First, shorten words as needed and return if the result fits
  let result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
  if (result.length <= maxTotalLength) {
    return result;
  }

  // Next, initialize the middle name and return if the result fits
  abbrMiddle = user.middleName ? initial(user.middleName) : null;
  result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
  if (result.length <= maxTotalLength) {
    return result;
  }

  // Then, shorten the last name if doing so will make the result fit. If not, initialize the last name and continue
  let excess = result.length - maxTotalLength;
  if (excess < abbrLast.length - 1) {
    abbrLast = `${abbrLast.slice(0, abbrLast.length - (excess + 1))}.`;
    result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
    return result;
  }
  abbrLast = initial(user.lastName);

  // Finally, shorten the first name as much as needed
  result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
  excess = result.length - maxTotalLength;
  if (excess < abbrFirst.length - 1) {
    abbrFirst = `${abbrFirst.slice(0, abbrFirst.length - (excess + 1))}.`;
    result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
    return result;
  }
  abbrFirst = initial(user.firstName);
  result = [abbrFirst, abbrMiddle, abbrLast].filter(Boolean).join(" ");
  return result;
}
