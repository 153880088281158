import { useEffect, useState } from "react";
import { scan, takeWhile, timer } from "rxjs";
import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";
import { format } from "common/core/format/date";

import Styles from "./index.module.scss";

type Props = {
  onLogin: () => void;
  onLogout: () => void;
  seconds: number;
};

const INTERVAL = 1_000; // 1 second

function formatTime(seconds: number) {
  return format({ value: new Date(0, 0, 0, 0, 0, seconds), formatStyle: "mm:ss" });
}

export default function TimeoutModal({ onLogin, onLogout, seconds }: Props) {
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  useEffect(() => {
    // Wait 1 second before starting the timer, so that it doesn't blink.
    const timer$ = timer(INTERVAL, INTERVAL).pipe(
      scan((acc) => --acc, secondsLeft),
      takeWhile((x) => x >= 0),
    );
    const sub = timer$.subscribe({ complete: onLogout, next: setSecondsLeft });
    return () => sub.unsubscribe();
  }, []);

  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="f566e91e-32d0-44a2-bd37-ea4ac866fc47"
          defaultMessage="Are you still here?"
        />
      }
      buttons={[
        <Button key="logout" onClick={onLogout} variant="secondary" buttonColor="action">
          <FormattedMessage id="98813841-7ae8-4094-86ee-959170aa7c74" defaultMessage="Log out" />
        </Button>,
        <Button key="login" onClick={onLogin} variant="primary" buttonColor="action">
          <FormattedMessage
            id="231b4adb-8ca1-433c-b92b-1b1ef24cdad6"
            defaultMessage="Stay logged in"
          />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <div className={Styles.modalBody}>
        <FormattedMessage
          id="e793beca-c64d-40aa-aab8-5c74ae37b49a"
          defaultMessage="For account security, your session will expire in <b>{time}</b>."
          values={{ b: (word) => <strong>{word}</strong>, time: formatTime(secondsLeft) }}
          tagName="span"
        />

        <FormattedMessage
          id="fc229196-90d2-4ba9-970d-2d7b6e944cb7"
          defaultMessage="To stay logged in, click the button below."
          tagName="span"
        />
      </div>
    </WorkflowModal>
  );
}
