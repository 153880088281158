import { memo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

import VideoSessionId from "common/details/meeting/notary_details/items/video_session_id";
import Button from "common/core/button";
import { usePermissions } from "common/core/current_user_role";
import { ChoiceChip, RadioGroup, RadioInput } from "common/core/form/option";
import SROnly from "common/core/screen_reader";
import { Badge } from "common/core/badge";
import { MeetingAnalysisStatus } from "graphql_globals";
import { Card } from "common/core/card";
import { DescriptionList } from "common/core/description_list";
import { Heading, Paragraph } from "common/core/typography";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./video.module.scss";
import ProcessingVideo from "./processing_video";
import { DeepfakeAnalysis } from "./deepfake";
import type { DetailsVideosVideo as Meeting } from "./video_fragment.graphql";

type Props = {
  meeting: Meeting;
  userRole?: string;
};

type ContentTab = "recording" | "deepfake-analysis";

const noop = () => {};

// TODO: Don't export once `deprecated_video` is removed
export function EndedMeetingContent({ meeting }: { meeting: Meeting }) {
  const { video, meetingAnalysis } = meeting;
  const [tab, setTab] = useState<ContentTab>("recording");
  const deepfakeAnalysisEnabled = useFeatureFlag("deepfake-analysis");

  return (
    <>
      {meetingAnalysis?.status === MeetingAnalysisStatus.DONE && deepfakeAnalysisEnabled && (
        <div className={Styles.deepFakeTabs}>
          <RadioGroup
            label={
              <SROnly>
                <FormattedMessage
                  id="439addf1-6049-45c8-9283-59fa9aa30c42"
                  defaultMessage="Meeting video content"
                />
              </SROnly>
            }
            horizontal
          >
            <ChoiceChip
              label={
                <FormattedMessage
                  id="822d86ec-b137-412e-b27c-ca257f3c1f58"
                  defaultMessage="Recording"
                />
              }
              radio={
                <RadioInput<ContentTab>
                  value="recording"
                  checked={tab === "recording"}
                  onClick={() => setTab("recording")}
                  onChange={noop}
                />
              }
            />
            <ChoiceChip
              label={
                <div>
                  <FormattedMessage
                    id="19a89d67-666a-41cd-a961-36642464ba31"
                    defaultMessage="Deepfake analysis"
                  />
                  <Badge kind="infoBlue" className={Styles.betaBadge}>
                    <FormattedMessage
                      id="e6932435-9137-44ee-9352-bb8442543775"
                      defaultMessage="Beta"
                    />
                  </Badge>
                </div>
              }
              radio={
                <RadioInput<ContentTab>
                  value="deepfake-analysis"
                  checked={tab === "deepfake-analysis"}
                  onClick={() => setTab("deepfake-analysis")}
                  onChange={noop}
                />
              }
            />
          </RadioGroup>
        </div>
      )}
      <div className={Styles.videoDetails}>
        {tab === "recording" && (
          <ProcessingVideo
            src={video?.recording?.url}
            transcript={video?.transcript?.url}
            transactionName={meeting.organizationTransaction.name}
          />
        )}
        {tab === "deepfake-analysis" && <DeepfakeAnalysis meeting={meeting} />}
      </div>
    </>
  );
}

function JoinableMeetingContent({ meeting }: { meeting: Meeting }) {
  const navigate = useNavigate();

  return (
    <div className={Styles.ongoingMeeting}>
      <Heading level="h3" textStyle="subtitle">
        <FormattedMessage
          id="3e2f9540-eec1-4b45-adee-cdc064c351cd"
          defaultMessage="There is an ongoing meeting, do you want to join?"
        />
      </Heading>
      <Paragraph>
        <FormattedMessage
          id="2725bc82-2520-4087-86ca-5b9e97a446e6"
          defaultMessage="You will be able to see and hear all meeting participants, but they won't be able to see or hear you."
        />
      </Paragraph>
      <Button
        buttonColor="action"
        variant="primary"
        automationId="join-observer-meeting"
        onClick={() => navigate(`/meeting/${meeting.id}`)}
      >
        <FormattedMessage
          id="bb470a4c-786b-4e35-8ad9-fa02bd468d57"
          defaultMessage="Join as Observer"
        />
      </Button>
    </div>
  );
}

function OnGoingMeetingContent({ meeting: { meetingParticipants } }: { meeting: Meeting }) {
  const { hasPermissionFor } = usePermissions();
  const observerName = meetingParticipants.find(
    (p) => p.__typename === "SpectatorParticipant" && p.isConnected,
  )?.fullName;
  return (
    <div className={Styles.ongoingMeeting}>
      <Paragraph textColor="subtle" className={Styles.meetingRecordingIndicator}>
        <FormattedMessage id="007753c3-8d7d-4b6a-a8c4-ee4b7f572fa9" defaultMessage="Recording..." />
      </Paragraph>
      <Heading level="h3" textStyle="subtitle">
        <FormattedMessage
          id="82482831-c9c9-4061-97a9-61b0175e9d54"
          defaultMessage="There is an ongoing meeting"
        />
      </Heading>
      {hasPermissionFor("observeMeeting") && observerName && (
        <Paragraph>
          <FormattedMessage
            id="c91fd420-2fe8-4b15-b93a-2d64abfdeb51"
            defaultMessage="Meeting is currently observed by {observerName}"
            values={{ observerName }}
          />
        </Paragraph>
      )}
    </div>
  );
}

function Video({ meeting }: Props) {
  const { canJoin, video, timeFrame } = meeting;
  const { hasPermissionFor } = usePermissions();
  const meetingEnded = Boolean(timeFrame?.endedAt);

  const videoSessionIdGrid = video?.id && <VideoSessionId meeting={meeting} />;

  return (
    <Card noMargin fullWidth>
      {meetingEnded ? (
        <EndedMeetingContent meeting={meeting} />
      ) : canJoin ? (
        <JoinableMeetingContent meeting={meeting} />
      ) : (
        <OnGoingMeetingContent meeting={meeting} />
      )}
      {hasPermissionFor("videoSessionDetailedIds") && (
        <DescriptionList className={Styles.videoSessionGrid}>{videoSessionIdGrid}</DescriptionList>
      )}
    </Card>
  );
}

export default memo(Video);
