import { FormattedMessage } from "react-intl";

import WorkflowModal from "common/modals/workflow_modal";
import Button from "common/core/button";

import Styles from "./index.module.scss";

type Props = {
  onLogout: () => void;
};

export default function ReconnectModal({ onLogout }: Props) {
  return (
    <WorkflowModal
      title={
        <FormattedMessage
          id="c37c6517-74df-4dc7-8136-acbe164178ac"
          defaultMessage="Your session has expired."
        />
      }
      buttons={[
        <Button key="login" onClick={onLogout} variant="primary" buttonColor="action">
          <FormattedMessage id="c7fe86ba-06d8-427c-8c90-85e449e3a453" defaultMessage="Log in" />
        </Button>,
      ]}
      footerSeparator={false}
    >
      <div className={Styles.modalBody}>
        <FormattedMessage
          id="c142d0da-0a74-456e-aa8c-23957c71bba0"
          defaultMessage="For account security, your session has ended. Click the button below to log in again."
          tagName="span"
        />
      </div>
    </WorkflowModal>
  );
}
