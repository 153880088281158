import { FormattedMessage, useIntl } from "react-intl";
import { useEffect, useRef, type ReactNode } from "react";

import Meetings from "assets/images/mobile_nav/empty_states/meetings.svg";
import Settings from "assets/images/mobile_nav/empty_states/settings.svg";
import SendAndManage from "assets/images/mobile_nav/empty_states/send-manage.svg";
import TeamMembers from "assets/images/proof_people.svg";
import Svg from "common/core/svg";
import { Heading, Paragraph } from "common/core/typography";

import { meetingsLabel, orgSettingsLabel, sendAndManageLabel, usersLabel } from "../../common";
import Styles from "./index.module.scss";

const dashboard = {
  heading: sendAndManageLabel,
  description: (
    <FormattedMessage
      id="82840674-d75c-446b-8101-07e869bb14c5"
      defaultMessage="Visit Send & manage on a computer to send and view transactions and access completed documents."
    />
  ),
  svg: <Svg src={SendAndManage} alt="" />,
};

const meetings = {
  heading: meetingsLabel,
  description: (
    <FormattedMessage
      id="63af5977-45a2-4c7f-b262-79a54f199174"
      defaultMessage="Signers can join meetings from their mobile phones. If you plan to conduct or manage meetings, log in on a computer."
    />
  ),
  svg: <Svg src={Meetings} alt="" />,
};

const settings = {
  heading: orgSettingsLabel,
  description: (
    <FormattedMessage
      id="70752136-7f94-4f90-92af-05d67e5c8150"
      defaultMessage="View and manage your organization's settings from a computer."
    />
  ),
  svg: <Svg src={Settings} alt="" />,
};

const teamMembers = {
  heading: usersLabel,
  description: (
    <FormattedMessage
      id="87c9af97-3b47-4bba-9d80-69b9f082a453"
      defaultMessage="Visit your Users page from a computer to view and manage your organization's active, invited, and deactivated users."
    />
  ),
  svg: <Svg src={TeamMembers} alt="" />,
};

function EmptyState({
  heading,
  description,
  svg,
}: {
  heading: { id: string; defaultMessage: string };
  description: ReactNode;
  svg: ReactNode;
}) {
  const intl = useIntl();
  const wrapperDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    wrapperDiv.current?.scrollIntoView({ block: "start" });
  }, []);

  return (
    <div className={Styles.wrapper} ref={wrapperDiv}>
      <div className={Styles.image}>{svg}</div>
      <Heading className={Styles.heading} level="h1" textStyle="headingFive">
        {intl.formatMessage(heading)}
      </Heading>
      <Paragraph className={Styles.functionality} size="large">
        <FormattedMessage
          id="b66b0b6a-85ab-4545-b8b2-26a2d0652b94"
          defaultMessage="Functionality is limited on mobile devices."
        />
      </Paragraph>
      <Paragraph size="large">{description}</Paragraph>
    </div>
  );
}

export function BusinessDashboardMobileDevice() {
  return (
    <EmptyState
      heading={dashboard.heading}
      description={dashboard.description}
      svg={dashboard.svg}
    />
  );
}

export function BusinessMeetingsMobileDevice() {
  return (
    <EmptyState heading={meetings.heading} description={meetings.description} svg={meetings.svg} />
  );
}

export function BusinessSettingsMobileDevice() {
  return (
    <EmptyState heading={settings.heading} description={settings.description} svg={settings.svg} />
  );
}

export function BusinessMembershipManagementMobileDevice() {
  return (
    <EmptyState
      heading={teamMembers.heading}
      description={teamMembers.description}
      svg={teamMembers.svg}
    />
  );
}
