import { memo, type ReactElement } from "react";
import { FormattedMessage } from "react-intl";

import { Paragraph } from "common/core/typography";

import { NotaryItemWrapper } from "../../notary_item_wrapper";

type Props = {
  meeting: {
    failureDescription: string | null;
    failureAnecdote: string | null;
  };
};

const TERMINATION_DESCRIPTIONS: Record<string, undefined | ReactElement> = {
  TECH: (
    <FormattedMessage id="2ca1af2a-ddd7-42c0-a42a-e806bf84be02" defaultMessage="Technical Issue" />
  ),
  CUST: (
    <FormattedMessage id="f6d2e3d6-8a61-4fb0-a00a-7cf3f84ea712" defaultMessage="Customer Issue" />
  ),
  ID: (
    <FormattedMessage
      id="6fc4cc89-6683-4edf-8b0c-5b81c59462b4"
      defaultMessage="Identification issue (suspicious or illegible ID, etc)"
    />
  ),
  DOC: (
    <FormattedMessage
      id="d4c514af-357d-4142-9070-9a302e52c92c"
      defaultMessage="Unacceptable Document"
    />
  ),
  OTHER: <FormattedMessage id="dc4f5930-ab1f-43aa-ab3f-87d2be2d6653" defaultMessage="Other" />,
};

function LegacyInfo({ meeting }: Props) {
  const { failureDescription, failureAnecdote } = meeting;
  return (
    <div>
      <NotaryItemWrapper
        term={
          <FormattedMessage id="d25df7bc-cdc4-4d0e-be1d-dc81f4391a87" defaultMessage="Details" />
        }
        definition={
          <Paragraph>
            {TERMINATION_DESCRIPTIONS[failureDescription!] || failureDescription}
          </Paragraph>
        }
      />
      {failureAnecdote && (
        <NotaryItemWrapper
          term={
            <FormattedMessage id="d465cac4-25c5-4fba-a208-59e7233684c1" defaultMessage="Anecdote" />
          }
          definition={failureAnecdote}
        />
      )}
    </div>
  );
}

export default memo(LegacyInfo);
