import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { BulkActionBar } from "common/core/bulk_actions";
import Button from "common/core/button";
import { Substyle } from "common/core/typography";
import { BulkActionButton, BulkActionSeparator } from "common/core/bulk_actions/common";
import { useFeatureFlag } from "common/feature_gating";

import Styles from "./merge_documents_toolbar.module.scss";

type MergeDocumentsToolbarProps = {
  isTemplateEditing: boolean;
  numOfDocuments: number;
  numOfSelectedDocuments: number;
  isMergeToolActive: boolean;
  setIsMergeToolActive: (status: boolean) => void;
  setShowMergeDocumentsModal: (status: boolean) => void;
  setShowDeleteDocumentsModal: (status: boolean) => void;
};

const MergeDocumentsToolbar = ({
  isTemplateEditing,
  numOfDocuments,
  numOfSelectedDocuments,
  isMergeToolActive,
  setIsMergeToolActive,
  setShowMergeDocumentsModal,
  setShowDeleteDocumentsModal,
}: MergeDocumentsToolbarProps) => {
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const noneSelected = numOfSelectedDocuments === 0;
  const allSelected = numOfSelectedDocuments === numOfDocuments;
  // Templates must have at least 1 document
  const isTemplateDeleteDisabled =
    isTemplateEditing &&
    (numOfDocuments - numOfSelectedDocuments === 0 || noneSelected || allSelected);
  const isDocumentDeleteDisabled = isTemplateDeleteDisabled || noneSelected;
  const isMergeDocumentsDisabled = numOfSelectedDocuments < 2;

  const toggleMergeTool = () => {
    setIsMergeToolActive(!isMergeToolActive);
    setIsInitialLoad(false);
  };

  // [BIZ-6549] Clean up flag
  const bulkDeleteDocumentsEnabled = useFeatureFlag("bulk-delete-documents");

  const content = isMergeToolActive ? (
    <BulkActionBar itemCount={numOfSelectedDocuments}>
      <BulkActionButton
        automationSuffix="close"
        onClick={toggleMergeTool}
        withIcon={{ placement: "left", name: "x-filled" }}
        autoFocus
        label={
          <FormattedMessage id="18133eab-7c8d-4e98-a683-0117a68625d1" defaultMessage="Close" />
        }
      />
      <BulkActionSeparator />

      <BulkActionButton
        buttonColor="action"
        onClick={() => setShowMergeDocumentsModal(true)}
        disabled={isMergeDocumentsDisabled}
        disabledHint={
          <FormattedMessage
            id="282a88dd-6e62-4051-b773-8ece8c731f89"
            defaultMessage="Select two or more documents in the table to merge."
          />
        }
        disabledHintPlacement="bottom"
        withIcon={{ placement: "left", name: "merge" }}
        automationSuffix="merge-documents"
        label={
          <FormattedMessage
            id="41454830-a629-4a07-9e62-76dff5150657"
            defaultMessage="Merge documents"
          />
        }
      />

      {bulkDeleteDocumentsEnabled && (
        <BulkActionButton
          onClick={() => setShowDeleteDocumentsModal(true)}
          buttonColor="danger"
          withIcon={{ name: "delete", placement: "left" }}
          disabled={isDocumentDeleteDisabled}
          disabledHint={
            isTemplateDeleteDisabled ? (
              <FormattedMessage
                id="3e6c36e6-36d5-4d9e-babb-feec634f9a0e"
                defaultMessage="The template must contain at least one document."
              />
            ) : (
              <FormattedMessage
                id="3d0387b8-1e9f-4b75-b92e-7440eade3332"
                defaultMessage="Select one or more documents in the table to delete."
              />
            )
          }
          disabledHintPlacement="bottom"
          automationSuffix="delete"
          label={
            <FormattedMessage id="f948a0b0-fc83-4556-ab48-6ff4ae31ed54" defaultMessage="Delete" />
          }
        />
      )}
    </BulkActionBar>
  ) : (
    <div className={Styles.inactiveToolbar}>
      <Substyle size="defaultSize">{`Total Documents: ${numOfDocuments}`}</Substyle>
      <div className={Styles.defaultButtonContainer}>
        <Button
          className={Styles.defaultButton}
          variant="tertiary"
          buttonSize="condensed"
          buttonColor="action"
          onClick={() => setIsMergeToolActive(!isMergeToolActive)}
          automationId="open-merge-tool-button"
          autoFocus={!isInitialLoad}
        >
          {bulkDeleteDocumentsEnabled ? (
            <FormattedMessage
              id="6551b87d-9202-4d27-9dc7-da0256b000fd"
              defaultMessage="Document actions"
            />
          ) : (
            <FormattedMessage
              id="3115d90c-c866-4b47-a789-385bd59c27de"
              defaultMessage="Merge documents"
            />
          )}
        </Button>
      </div>
    </div>
  );

  return (
    <div className={Styles.mergeDocumentsToolbar} data-automation-id="merge-documents-toolbar">
      {content}
    </div>
  );
};

export default MergeDocumentsToolbar;
