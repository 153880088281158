import { memo, useEffect, useRef } from "react";
import { of, map, type Observable } from "rxjs";

import { isOptimistic } from "common/meeting/pdf/annotation";

import { usePDFContext } from "..";
import type {
  KitModule,
  KitInstance,
  KitAnnotation,
  KitAnnotationMixin,
  UserUpdateDecorationEvent,
} from "../util";
import type {
  PspdkitAnnotation as DrawableAnnotation,
  PspdkitAnnotation_TextAnnotation as TextAnnotation,
} from "./index_fragment.graphql";
import { createTextKitAnnotation, updateTextKitAnnotation, adjustYPositionForText } from "./text";
import { createCheckmarkKitAnnotation, createImageKitAnnotation } from "./image";
import { createWhiteboxKitAnnotation } from "./whitebox";

export type AnnotationCallbacks = {
  onUpdate?: (evt: UserUpdateDecorationEvent) => void;
  onDelete?: () => void;
  isLockedDocument?: boolean;
  canEditOverride?: boolean;
};
export type AnnotationUpdateArgs = AnnotationCallbacks & { annotation: DrawableAnnotation };
type CreateConfig = {
  module: KitModule;
  instance: KitInstance;
  options: AnnotationUpdateArgs & { isPreview?: boolean; canEditOverride?: boolean };
  checkmarkAttachmentId: string;
  mixin: KitAnnotationMixin;
};
type Props<A extends DrawableAnnotation> = {
  /** If undefined, not editable/selectable (nor deleteable) */
  onUpdate?: (annotation: A, evt: UserUpdateDecorationEvent) => void;
  /** If undefined, not deleteable */
  onDelete?: (annotation: A) => void;
  annotation: A;
  isPreview?: boolean;
  isPrimaryConditional?: boolean;
  isLockedDocument?: boolean;
  /** Override annotation canEdit value */
  canEditOverride?: boolean;
};

function identityAdjustY(y: number): number {
  return y;
}

export function getYOffsetAdjustFnForAnnotation(
  annotation: DrawableAnnotation,
): (originalY: number, size: { height: number }) => number {
  return annotation.__typename === "TextAnnotation" ? adjustYPositionForText : identityAdjustY;
}

export function buildAnnotationCallbackPresenceForTooltips(
  callbacks: (AnnotationCallbacks & { annotation: DrawableAnnotation }) | undefined,
) {
  // All the callback keys that change the presence of a tooltip button:
  const disableResizeAndEditText = callbacks ? !callbacks.annotation.canEdit : false;
  const disableResizeAndEditTextOverride =
    typeof callbacks?.canEditOverride === "boolean"
      ? !callbacks.canEditOverride
      : disableResizeAndEditText;
  return {
    hasDelete: Boolean(callbacks?.onDelete),
    isLockedDocument: Boolean(callbacks?.isLockedDocument),
    disableResizeAndEditText: disableResizeAndEditTextOverride,
  };
}

export function adjustMoveEventForAnnotation<E extends { newY: number }>(
  module: KitModule,
  pspAnnotation: KitAnnotation,
  moveEvent: E,
): E {
  return pspAnnotation instanceof module.Annotations.TextAnnotation
    ? { ...moveEvent, newY: adjustYPositionForText(moveEvent.newY, pspAnnotation.boundingBox) }
    : moveEvent;
}

function createKitAnnotationForNotarizeType(config: CreateConfig): Observable<KitAnnotation> {
  const { options, module, mixin } = config;
  const { annotation } = options;
  switch (annotation.__typename) {
    case "TextAnnotation":
      return of(createTextKitAnnotation(module, annotation, mixin));
    case "CheckmarkAnnotation":
      return of(createCheckmarkKitAnnotation(module, mixin, config.checkmarkAttachmentId));
    case "WhiteboxAnnotation":
      return of(
        createWhiteboxKitAnnotation(module, mixin, Boolean(options.isPreview || options.onUpdate)),
      );
    case "ImageAnnotation":
    case "VectorGraphicAnnotation":
      return createImageKitAnnotation(module, config.instance, annotation, mixin);
  }
}

export function createKitAnnotationForAnnotation(config: CreateConfig): Observable<KitAnnotation> {
  return createKitAnnotationForNotarizeType(config).pipe(
    map((pspAnnotation) => {
      return pspAnnotation.set("customData", {
        ...pspAnnotation.customData,
        ...buildAnnotationCallbackPresenceForTooltips(config.options),
      });
    }),
  );
}

export function updateKitAnnotationForAnnotation(
  current: KitAnnotation,
  annotation: DrawableAnnotation,
): KitAnnotation {
  switch (annotation.__typename) {
    case "TextAnnotation":
      return updateTextKitAnnotation(current, annotation);
    default:
      return current;
  }
}

function Annotation<A extends DrawableAnnotation>({
  annotation,
  onUpdate,
  onDelete,
  isPreview,
  isPrimaryConditional,
  isLockedDocument,
  canEditOverride,
}: Props<A>) {
  const { addAnnotation } = usePDFContext();
  const updateRef = useRef<null | ((args: AnnotationUpdateArgs) => void)>(null);
  // Never allow an optimistic element to be deleted/updated.
  const isOptimisticSafe: true | undefined = !isOptimistic(annotation) || undefined;
  const onDeleteCb = isOptimisticSafe && onDelete && (() => onDelete(annotation));
  const onUpdateCb =
    isOptimisticSafe &&
    onUpdate &&
    ((evt: UserUpdateDecorationEvent) => {
      // skip processing no-op pspdfkit events where the text hasn't actually changed
      if (evt.type !== "edittext" || evt.newText !== (annotation as TextAnnotation).text) {
        onUpdate(annotation, evt);
      }
    });
  useEffect(() => {
    // This effect is designed to only run once per unique Annotation. `addAnnotation` changes every time
    // we load a new PDF and we expect the __typename/subtype to stay static to a particular annotation.
    // Any of these things changing will cause a "flicker" as the annotation is removed and then added
    // again as the new type.
    if (addAnnotation) {
      const handlers = addAnnotation({
        annotation,
        onDelete: onDeleteCb,
        onUpdate: onUpdateCb,
        isPreview,
        isPrimaryConditional,
        isLockedDocument,
        canEditOverride,
      });
      updateRef.current = handlers.update;
      return handlers.destroy;
    }
    updateRef.current = null;
  }, [addAnnotation, annotation.__typename, annotation.subtype]);
  useEffect(() => {
    updateRef.current?.({
      annotation,
      onDelete: onDeleteCb,
      onUpdate: onUpdateCb,
      isLockedDocument,
      canEditOverride,
    });
  });

  return null;
}

const MemoizedAnnotation = memo(Annotation) as typeof Annotation;
export { MemoizedAnnotation as Annotation };
