import { useMemo, Fragment, useEffect, type FunctionComponent } from "react";
import { useParams, useSearchParams, Navigate } from "react-router-dom";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";

import { useForm, useFormState, useWatch, type UseFormReturn } from "common/core/form";
import { useQuery } from "util/graphql";
import { useId } from "util/html";
import LoadingIndicator from "common/core/loading_indicator";
import Button from "common/core/button";
import { useActiveOrganization } from "common/account/active_organization";
import { usePermissions } from "common/core/current_user_role";
import {
  TRANSACTION_CREATION_V3_OPT_OUT_TAG,
  optedOutOfTransactionCreationV3,
  useTransactionCreationV3,
} from "common/transaction_creation/v3/detection";
import {
  TRANSACTION_PATH,
  businessTransactionEditRouteV2,
  transactionEditRoute,
} from "util/routes";
import { CURRENT_PORTAL } from "constants/app_subdomains";
import {
  OrganizationTransactionVariant,
  Feature,
  OrganizationTransactionLabels,
} from "graphql_globals";
import { ButtonStyledLink } from "common/core/button/button_styled_link";
import { pushNotification } from "common/core/notification_center/actions";
import { NOTIFICATION_TYPES, NOTIFICATION_SUBTYPES } from "constants/notifications";
import { captureException } from "util/exception";
import AlertMessage from "common/core/alert_message";
import DuplicateTransactionButton from "common/transactions/actions/duplicate/button";
import SubStatusSection from "common/transactions/form/sub_status_section";
import { Checkbox, CheckboxLabel } from "common/core/form/option";
import Tooltip from "common/core/tooltip";
import Icon from "common/core/icon";

import {
  Page,
  PageContent,
  PageFooter,
  PageHeader,
  SectionDivider,
  showField,
  scrollAndFlashError,
} from "../common";
import {
  ESIGN_CONFIG,
  type Config,
  type ConfiguredField,
  type SectionConfig,
  PROOF_CONFIG,
  NOTARIZATION_CONFIG,
  REAL_ESTATE_ESIGN_CONFIG,
  REAL_ESTATE_PROOF_CONFIG,
} from "../config";
import { useOnSubmit, getConfig, type SubmitReturn } from "../util";
import {
  TRANSACTION_DETAILS_SECTION,
  type TransactionDetailsFormValues,
} from "../sections/transaction_details";
import TransactionCreationFormQuery, {
  type TransactionCreationForm_transaction_OrganizationTransaction as Transaction,
  type TransactionCreationForm_organization_Organization as Organization,
  type TransactionCreationForm_viewer as Viewer,
  type TransactionCreationForm_viewer_user as User,
} from "../query.graphql";
import {
  RECIPIENT_DETAILS_SECTION,
  type RecipientDetailsFormValues,
} from "../sections/recipient_details";
import {
  ADDITIONAL_CONTACTS_SECTION,
  type AdditionalContactsFormValues,
} from "../sections/recipient_details/additional_contacts";
import { CUSTOM_EMAIL_SECTION, type CustomEmailFormValues } from "../sections/custom_email";
import {
  SIGNING_DETAILS_SECTION,
  type SigningDetailsFormValues,
} from "../sections/signing_details";
import { ORG_PAYMENT_SECTION, type OrgPaymentFormValues } from "../sections/org_payment";
import { PROMISSORY_NOTE_SECTION } from "../sections/promissory_note";
import { NST_PAYMENT_SECTION, type NstPaymentFormValues } from "../sections/nst_payment";
import { DOCUMENTS_SECTION } from "../sections/documents";
import { TransactionCreationV3Banner } from "./banner";
import { RecalledBanner } from "./banner/recalled";
import Styles from "./index.module.scss";

export { type Transaction };
export { type Organization };
export { type Viewer };

export const TRANSACTION_CREATION_QUERY_USER_TAG_LIST = [TRANSACTION_CREATION_V3_OPT_OUT_TAG];

export const CONFIG_ID_QUERY_PARAM = "configId";

// array of sections in the order they appear on form
export const SECTIONS = [
  TRANSACTION_DETAILS_SECTION,
  RECIPIENT_DETAILS_SECTION,
  ADDITIONAL_CONTACTS_SECTION,
  PROMISSORY_NOTE_SECTION,
  DOCUMENTS_SECTION,
  SIGNING_DETAILS_SECTION,
  CUSTOM_EMAIL_SECTION,
  ORG_PAYMENT_SECTION,
  NST_PAYMENT_SECTION,
];
export type Section = (typeof SECTIONS)[number];

type Flatten<T> = { [k in keyof T]: T[k] };

export type FormValues = Flatten<
  TransactionDetailsFormValues &
    CustomEmailFormValues &
    OrgPaymentFormValues &
    SigningDetailsFormValues &
    RecipientDetailsFormValues &
    AdditionalContactsFormValues &
    NstPaymentFormValues
> & {
  placeOrder: boolean;
};

// force explicitly specifying all of a section's default form values (prevent undefined)
type Defined<T> = {
  [K in keyof T]-?: Exclude<T[K], undefined>;
};

export type SectionComponentProps<TransactionFragment, OrganizationFragment, UserFragment> = {
  config: Config;
  form: UseForm;
  transaction: TransactionFragment;
  organization: OrganizationFragment;
  user: UserFragment;
  onSave: SubmitReturn["onSave"];
  formId: string;
  /**
   * onSendValidationError - allows user to grab & surface specific error messages in form sections
   */
  onSendValidationError?: SubmitReturn["onSendValidationError"];
};
type SectionConfigsType = Record<string, ConfiguredField | SectionConfig>;
export type GetSectionConfig<SectionConfigs extends SectionConfigsType> = Pick<
  Config,
  SectionConfigs[keyof SectionConfigs]
>;
/**
 * @typeParam SectionFormValues - form values controlled by this section
 * @typeParam SectionSubmitData - the values that will be passed to form on submit
 * @typeParam TransactionFragment - the transaction data this section depends on
 * @typeParam OrganizationFragment - the active organization data this section depends on
 * @typeParam UserFragment - the active user data this section depends on
 * @typeParam SectionConfigs - object where the values are the config fields used in section
 */
export type SectionContract<
  SectionFormValues,
  SectionSubmitData,
  TransactionFragment,
  OrganizationFragment,
  UserFragment,
  SectionConfigs extends SectionConfigsType,
> = {
  /**
   * component that renders a section's content (header, card, etc)
   */
  Component: FunctionComponent<
    SectionComponentProps<TransactionFragment, OrganizationFragment, UserFragment>
  >;
  /**
   * parts of the config that are associated w/ this section (fields can only be associated w/ one section)
   */
  configs: SectionConfigs;
  /**
   * transforms fetched transaction data into initial form data
   */
  getDefaultFormValues?: (transaction: TransactionFragment) => Defined<SectionFormValues>;
  /**
   * transforms form data into data that will be used for transaction update mutations
   */
  getSubmitData?: (args: {
    sectionFormValues: Defined<SectionFormValues>;
    sectionConfig: GetSectionConfig<SectionConfigs>;
    transaction: TransactionFragment;
    organization: OrganizationFragment;
  }) => SectionSubmitData;
  /**
   * modifies the specified config to account for org features and user permissions
   * intent of reducing functionality based on a lack of features or permissions
   */
  modifyConfig?: (args: {
    sectionConfig: GetSectionConfig<SectionConfigs>;
    transaction: TransactionFragment;
    organization: OrganizationFragment;
    user: UserFragment;
    permissions: ReturnType<typeof usePermissions>;
  }) => GetSectionConfig<SectionConfigs>;
};

export type UseForm = Pick<
  UseFormReturn<FormValues>,
  | "register"
  | "unregister"
  | "handleSubmit"
  | "setValue"
  | "setError"
  | "setFocus"
  | "getValues"
  | "getFieldState"
  | "trigger"
  | "control"
  | "clearErrors"
  | "resetField"
>;

const MESSAGES = defineMessages({
  notFoundError: {
    id: "de2fe18d-ad69-45e3-9df1-548c5eafae17",
    defaultMessage: "The transaction could not be found or was deleted.",
  },
  defaultError: {
    id: "9dac2d97-a746-4476-ac4b-1578016f1c1d",
    defaultMessage:
      "The transaction could not be found, please contact support if this issue persists.",
  },
});

// the API can return null for transaction if its been deleted or if the user doesn't have access
export function TransactionErrorRedirect({ error }: { error?: Error }) {
  const intl = useIntl();
  useEffect(() => {
    let errorMessage = intl.formatMessage(MESSAGES.notFoundError);
    if (error && error.message !== "not_found") {
      captureException(new Error(`Unknown error in transaction creation v3: ${error.message}`));
      errorMessage = intl.formatMessage(MESSAGES.defaultError);
    }
    pushNotification({
      type: NOTIFICATION_TYPES.DEFAULT,
      message: errorMessage,
      subtype: NOTIFICATION_SUBTYPES.ERROR,
    });
  }, []);
  return <Navigate replace to="/" />;
}

function showSection(config: Config, section: Section) {
  return Object.values(section.configs).some((field) => {
    // ignores section config boolean/unknown values
    const fieldsConfig = config[field];
    return (
      typeof fieldsConfig === "object" &&
      Object.prototype.hasOwnProperty.call(fieldsConfig, "display") &&
      showField(config, field as ConfiguredField)
    );
  });
}
export function useShownSections(config: Config) {
  // config/shownSections don't change
  return useMemo(() => SECTIONS.filter((section) => showSection(config, section)), []);
}

function useTransactionCreationForm(transaction: Transaction, config: Config): UseForm {
  const defaultValues = SECTIONS.reduce((values, section) => {
    if ("getDefaultFormValues" in section) {
      return {
        ...values,
        ...section.getDefaultFormValues(transaction),
      };
    }

    return values;
  }, {} as FormValues); // eslint-disable-line @typescript-eslint/prefer-reduce-type-parameter
  defaultValues.placeOrder = isPlaceOrder({
    placeOrder: transaction.placeOrder,
    transaction,
    config,
  });

  const form = useForm<FormValues>({ defaultValues, shouldFocusError: false });

  // not all useForm props are exposed
  // watch/formState in particular are not returned for performance concerns, use
  // useWatch/useFormState w/ specific field name passed instead
  return useMemo(
    () => ({
      register: form.register,
      unregister: form.unregister,
      handleSubmit: form.handleSubmit,
      setValue: form.setValue,
      setError: form.setError,
      setFocus: form.setFocus,
      getValues: form.getValues,
      getFieldState: form.getFieldState,
      trigger: form.trigger,
      control: form.control,
      clearErrors: form.clearErrors,
      resetField: form.resetField,
    }),
    [],
  );
}

function uniqueKeyFromSection(section: Section) {
  return Object.values(section.configs).join();
}

/*
 * modifies a config to handle things like hiding/disabling fields based on org features or user permissions
 * */
function modifyConfig(
  config: Config,
  transaction: Transaction,
  organization: Organization,
  user: User,
  permissions: ReturnType<typeof usePermissions>,
): Config {
  const modifiedConfig = SECTIONS.reduce((values, section) => {
    if ("modifyConfig" in section) {
      return {
        ...values,
        ...section.modifyConfig({
          sectionConfig: values,
          transaction,
          organization,
          user,
          permissions,
        }),
      };
    }

    return values;
  }, config);

  if (permissions.hasPermissionFor("manageOpenOrders")) {
    modifiedConfig.placeOrderCapable = false;
  }

  return modifiedConfig;
}

function Form({
  id,
  form,
  config,
  transaction,
  organization,
  user,
  onSave,
  onSendValidationError,
}: {
  id: string;
  form: UseForm;
  config: Config;
  transaction: Transaction;
  organization: Organization;
  user: User;
  onSave: SubmitReturn["onSave"];
  onSendValidationError: SubmitReturn["onSendValidationError"];
}) {
  const shownSections = useShownSections(config);

  return (
    <form id={id}>
      {shownSections.map((section, index) => (
        <Fragment key={uniqueKeyFromSection(section)}>
          <section.Component
            formId={id}
            config={config}
            form={form}
            transaction={transaction}
            organization={organization}
            user={user}
            onSave={onSave}
            onSendValidationError={onSendValidationError}
          />
          {index < shownSections.length - 1 && <SectionDivider />}
        </Fragment>
      ))}
    </form>
  );
}

function sortErrors(errorList: HTMLElement[]) {
  return errorList.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);
}

export function scrollToFirstError(formId: string): Promise<"no-error" | void> {
  return new Promise((resolve) => {
    // need to allow fields to be rendered w/ aria-invalid before doing check
    setTimeout(() => {
      const formElement = document.getElementById(formId);
      if (!formElement) {
        return;
      }

      const formErrors = Array.from(
        formElement.querySelectorAll<HTMLElement>("[aria-invalid=true]"),
      );
      if (formErrors.length) {
        scrollAndFlashError(sortErrors(formErrors)[0]);
        return resolve();
      }

      // For sections that don't exist in the form, check for their manual error handling via
      // alert error messages (data-section-error)
      const sectionErrors = Array.from(
        formElement.querySelectorAll<HTMLElement>("[data-section-error]"),
      );
      if (sectionErrors.length) {
        scrollAndFlashError(sortErrors(sectionErrors)[0]);
        return resolve();
      }

      resolve("no-error");
    }, 0);
  });
}

function getVariantFromConfigId(configId: string | null) {
  switch (configId) {
    case ESIGN_CONFIG.id:
    case REAL_ESTATE_ESIGN_CONFIG.id:
      return OrganizationTransactionVariant.ESIGN;
    case PROOF_CONFIG.id:
    case REAL_ESTATE_PROOF_CONFIG.id:
      return OrganizationTransactionVariant.PROOF;
    case NOTARIZATION_CONFIG.id:
      return OrganizationTransactionVariant.NOTARIZATION;
    default:
      return undefined;
  }
}

function getV2Route(transaction: Transaction, configId: string | null) {
  const variant = getVariantFromConfigId(configId) || transaction.transactionVariant;

  if (CURRENT_PORTAL === "business") {
    return businessTransactionEditRouteV2({
      id: transaction.id,
      variant,
    });
  }

  return transactionEditRoute({
    id: transaction.id,
    type:
      variant === OrganizationTransactionVariant.PROOF
        ? "proof"
        : variant === OrganizationTransactionVariant.ESIGN
          ? "esign"
          : undefined,
  });
}

function LoadedTransactionCreationForm({
  transaction,
  organization,
  viewer,
}: {
  transaction: Transaction;
  organization: Organization;
  viewer: Viewer;
}) {
  const user = viewer.user!;
  const { duplicatedTransaction, orderProgress } = transaction;
  const permissions = usePermissions();
  const [queryParams] = useSearchParams();
  const configId = queryParams.get(CONFIG_ID_QUERY_PARAM);
  // should not change config after mount
  const config = useMemo(
    () =>
      modifyConfig(
        getConfig(configId, transaction, organization),
        transaction,
        organization,
        user,
        permissions,
      ),
    [],
  );
  const form = useTransactionCreationForm(transaction, config);
  const { isDirty, isSubmitting } = useFormState({ control: form.control });
  const { onSave, onSend, submitErrorModal, submitErrorMessage, onSendValidationError } =
    useOnSubmit(transaction, organization, viewer, config, isSubmitting);

  const placeOrder = useWatch({ control: form.control, name: "placeOrder" });
  const perTransactionPlaceOrderEnabled = isPerTransactionPlaceOrderEnabled({
    transaction,
    config,
  });

  const formId = useId();
  const intl = useIntl();
  const onSubmitError = () => {
    scrollToFirstError(formId);
  };
  const v3 = useTransactionCreationV3(user);

  const { hasPermissionFor } = usePermissions();

  if (!v3) {
    return <Navigate replace to={getV2Route(transaction, configId)} />;
  }

  function saveWithoutExit(): Promise<"saved" | "failed"> {
    // need to manually wrap in promise since handleSubmit just returns Promise<void>
    return new Promise((resolve) => {
      const fn = form.handleSubmit(
        async (formValues) => {
          const result = await onSave({ formValues, shouldExit: false });
          resolve(result?.status === "failed" ? "failed" : "saved");
        },
        () => {
          onSubmitError();
          resolve("failed");
        },
      );

      fn();
    });
  }

  const canDuplicate =
    hasPermissionFor("duplicateTransaction") &&
    transaction.organization.featureList.includes(Feature.DUPLICATE_TRANSACTIONS);
  const canSave = hasPermissionFor("editOrganizationTransactions");
  const canSend = hasPermissionFor("sendOrganizationTransactions") && !duplicatedTransaction;

  return (
    <Page>
      <TransactionCreationV3Banner
        transactionId={transaction.id}
        userId={viewer.user!.id}
        optedOut={optedOutOfTransactionCreationV3(user)}
        save={isDirty ? saveWithoutExit : false}
      />
      {duplicatedTransaction && (
        <AlertMessage kind="warning" centerText>
          <FormattedMessage
            id="968eb6a3-dcba-46a9-ad61-6bc29d10f11c"
            defaultMessage="This is a duplicate transaction."
          />
        </AlertMessage>
      )}

      <PageContent>
        <PageHeader subtitle={config.description && intl.formatMessage(config.description)}>
          {intl.formatMessage(config.name)}
        </PageHeader>

        <Form
          id={formId}
          form={form}
          config={config}
          transaction={transaction}
          organization={organization}
          user={user}
          onSave={onSave}
          onSendValidationError={onSendValidationError}
        />
      </PageContent>

      <PageFooter
        errorMessage={submitErrorMessage}
        warningMessage={<RecalledBanner transaction={transaction} />}
      >
        {hasPermissionFor("manageOpenOrders") && orderProgress && transaction.id && (
          <SubStatusSection
            availableTransactionLabels={organization.availableTransactionLabels}
            orderProgress={orderProgress}
            transactionId={transaction.id}
          />
        )}
        {perTransactionPlaceOrderEnabled && (
          <div className={Styles.placeOrderContainer}>
            <CheckboxLabel
              label={
                <FormattedMessage
                  id="a12a7a58-d35f-4673-9491-cc5020bcda65"
                  defaultMessage="Send to Proof Closing Concierge"
                />
              }
              checkbox={<Checkbox aria-invalid={false} {...form.register("placeOrder")} />}
            />
            <Tooltip target={<Icon name="info" />}>
              <FormattedMessage
                id="978be66d-7ab6-4b43-8a45-f33155d9b7a9"
                defaultMessage="Proof Closing Concierge prepares and reviews all documents and transaction details. If unchecked, please be sure to tag documents and review all details before sending to your recipients."
              />
            </Tooltip>
          </div>
        )}
        {canDuplicate && <DuplicateTransactionButton transactionId={transaction.id} />}
        {canSave && (
          <Button
            data-automation-id="save-and-close-transaction-button"
            buttonColor="action"
            variant="secondary"
            isLoading={isSubmitting}
            onClick={form.handleSubmit((formValues) => {
              return onSave({ formValues, shouldExit: true });
            }, onSubmitError)}
          >
            <FormattedMessage
              id="6aab5d05-a743-4614-a349-173aca0c9047"
              defaultMessage="Save & exit"
            />
          </Button>
        )}
        {canSend && (
          <Button
            data-automation-id="send-transaction-button"
            buttonColor="action"
            variant="primary"
            isLoading={isSubmitting}
            onClick={form.handleSubmit(async (formValues) => {
              const result = await scrollToFirstError(formId);
              if (result !== "no-error") {
                return;
              }
              return onSend({ formValues });
            }, onSubmitError)}
          >
            {placeOrder && !perTransactionPlaceOrderEnabled ? (
              <FormattedMessage
                id="07d291ac-9c9a-441f-b705-1366d39ee32f"
                defaultMessage="Place order"
              />
            ) : (
              <FormattedMessage
                id="7c34d075-1cab-49e0-9c12-957cc40bd035"
                defaultMessage="Send transaction"
              />
            )}
          </Button>
        )}
        {!(canSave || canSend) && (
          <ButtonStyledLink
            data-automation-id="close-transaction-button"
            buttonColor="action"
            variant="secondary"
            to={TRANSACTION_PATH}
          >
            <FormattedMessage id="4a0fe87a-77b3-40f6-9470-89093bc2ce20" defaultMessage="Exit" />
          </ButtonStyledLink>
        )}
      </PageFooter>
      {submitErrorModal}
    </Page>
  );
}

export default function TransactionCreationForm() {
  const [activeOrganizationId] = useActiveOrganization();
  const transactionId = useParams().transactionId!;
  const { data, loading, previousData, error } = useQuery(TransactionCreationFormQuery, {
    variables: {
      transactionId,
      organizationId: activeOrganizationId!,
      userTagList: TRANSACTION_CREATION_QUERY_USER_TAG_LIST,
    },
  });

  // only show loading indicator on initial load to prevent form from unmounting when there is a refetch
  if (loading && !previousData) {
    return <LoadingIndicator />;
  } else if (error || !data?.transaction) {
    return <TransactionErrorRedirect error={error} />;
  }

  return (
    <LoadedTransactionCreationForm
      transaction={data.transaction as Transaction}
      organization={data.organization as Organization}
      viewer={data.viewer}
    />
  );
}

export function isPerTransactionPlaceOrderEnabled({
  transaction,
  config,
}: {
  transaction: {
    perTransactionPlaceOrderEnabled: boolean;
    orderProgress: { label: OrganizationTransactionLabels } | null;
  };
  config: { placeOrderCapable: boolean };
}) {
  return (
    isPlaceOrderEligible({ transaction, config }) && transaction.perTransactionPlaceOrderEnabled
  );
}

export function isPlaceOrderEligible({
  transaction,
  config,
}: {
  transaction: { orderProgress: { label: OrganizationTransactionLabels } | null };
  config: { placeOrderCapable: boolean };
}) {
  return (
    config.placeOrderCapable &&
    // this label means that closing ops is done and its now back in the hands of the lender
    // so at this point the special place order logic should be turned off
    // the lender is the one to send the transaction to signers so all regular validation should be done
    transaction.orderProgress?.label !== OrganizationTransactionLabels.AWAITING_LENDER_APPROVAL
  );
}

export function isPlaceOrder({
  placeOrder,
  transaction,
  config,
}: {
  placeOrder: boolean;
  transaction: { orderProgress: { label: OrganizationTransactionLabels } | null };
  config: { placeOrderCapable: boolean };
}) {
  return isPlaceOrderEligible({ transaction, config }) && placeOrder;
}
