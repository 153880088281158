import { useCallback, useEffect, useMemo, useRef, type ComponentProps } from "react";
import { v4 } from "uuid";
import { concatMap, debounceTime } from "rxjs";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";

import {
  AnnotationSubtype,
  CoordinateSystem,
  AnnotationDesignationType,
  AnnotationDesignationModifier,
  type DocumentBundleMembershipRole,
  type PageTypes,
} from "graphql_globals";
import { usePDFContext } from "common/pdf/pspdfkit";
import type { Annotation as AnnotationComp } from "common/pdf/pspdfkit/annotation";
import type { AnnotationDesignation } from "common/pdf/pspdfkit/designation";
import {
  textAnnotationContent,
  subDesignationsForCompoundType,
  annotationDefaultPdfPointSize,
  designationDefaultPdfPointSize,
  annotationPdfPointSizeFromText,
} from "common/pdf/util";
import { createOptimisticId } from "common/meeting/pdf/annotation";
import { usePagePress, type CurrentToolData, type PagePressTool } from "common/pdf/interaction";
import {
  INTER_DESIGNATION_PADDING,
  COMPOUND_ANNOTATION_LOCATIONS,
  ANNOTATION_SUBTYPES,
} from "constants/annotations";
import { type ApolloCache, useMutation } from "util/graphql";
import type { DocumentForTransactionDetailsPDF_designations_edges_node as GroupDrawableDesignation } from "common/details/bundle/pspdfkit/index_fragment.graphql";
import { useSubject } from "util/rxjs/hooks";
import { captureException } from "util/exception";
import { pushNotification } from "common/core/notification_center/actions";
import { useFeatureFlag } from "common/feature_gating";
import { toolClicked } from "redux/actions/pdf_menu";
import { MULTI_PAGE_GROUPED_DESIGNATIONS } from "constants/feature_gates";
import { NOTIFICATION_SUBTYPES, NOTIFICATION_TYPES } from "constants/notifications";

import PDFDocumentFragment, {
  type PrepPdfDocument as Document,
} from "./pdf_document_fragment.graphql";
import type { PrepPdfAnnotation as Annotation } from "./pdf_annotation_fragment.graphql";
import type { PrepPdfDesignation as Designation } from "./pdf_designation_fragment.graphql";
import type { PrepPdfDesignationGroup as DesignationGroup } from "./pdf_designation_group_fragment.graphql";
import AddCheckmarkAnnotationMutation from "./add_checkmark_annotation_mutation.graphql";
import AddTextAnnotationMutation from "./add_text_annotation_mutation.graphql";
import AddWhiteboxAnnotationMutation from "./add_whitebox_annotation_mutation.graphql";
import UpdateAnnotationLocationMutation from "./update_annotation_location_mutation.graphql";
import UpdateAnnotationSizeMutation from "./update_annotation_size_mutation.graphql";
import UpdateAnnotationTextMutation from "./update_annotation_text_mutation.graphql";
import RemoveAnnotationMutation from "./remove_annotation_mutation.graphql";
import CreateAnnotationDesignationsMutation from "./create_annotation_designations_mutation.graphql";
import UpdateAnnotationDesignationLocationMutation from "./update_annotation_designation_location_mutation.graphql";
import UpdateAnnotationDesignationDimensionMutation from "./update_annotation_designation_dimension_mutation.graphql";
import UpdateAnnotationDesignationOptionalityMutation from "./update_annotation_designation_optionality_mutation.graphql";
import UpdateAnnotationDesignationSignerTypeMutation from "./update_annotation_designation_signer_type_mutation.graphql";
import DeleteAnnotationDesignationMutation from "./delete_annotation_designation_mutation.graphql";
import UpdateDesignationGroupDesigneeMutation from "./update_designation_group_designee_mutation.graphql";
import UpdateDesignationGroupRequirementsMutation from "./update_designation_group_requirements_mutation.graphql";
import DeleteDesignationGroupMutation from "./delete_designation_group_mutation.graphql";

function getDesignationsInGroup(designations: Designation[], groupId: string) {
  return designations.filter((d) => d.designationGroupId === groupId);
}

function addNewAnnotationToDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  documentId: string,
  newAnnotation: Annotation,
) {
  const cacheId = cacheProxy.identify({ __typename: "Document", id: documentId })!;
  const cachedDocument = cacheProxy.readFragment<Document>({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
  })!;
  cacheProxy.writeFragment({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
    data: {
      ...cachedDocument,
      annotations: {
        ...cachedDocument.annotations,
        edges: [
          ...cachedDocument.annotations.edges,
          { __typename: "AnnotationEdge", node: newAnnotation },
        ],
      },
    },
  });
}

function removeAnnotationFromDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  documentId: string,
  deletedAnnotationId: string,
) {
  const cacheId = cacheProxy.identify({ __typename: "Document", id: documentId })!;
  const cachedDocument = cacheProxy.readFragment<Document>({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
  })!;
  cacheProxy.writeFragment({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
    data: {
      ...cachedDocument,
      annotations: {
        ...cachedDocument.annotations,
        edges: [
          ...cachedDocument.annotations.edges.filter(
            (annotationEdge) => annotationEdge.node.id !== deletedAnnotationId,
          ),
        ],
      },
    },
  });
}

function addConditionalRuleToCache(
  currentConditionalRules: Record<string, undefined | string[]>,
  primaryDesignationId: string,
  designations: Designation[],
) {
  const designationIds = designations.map((designation) => designation.id);
  return {
    ...currentConditionalRules,
    [primaryDesignationId]: (currentConditionalRules[primaryDesignationId] || []).concat(
      designationIds,
    ),
  };
}

function addNewDesignationsToDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  documentId: string,
  designations: Designation[],
  additionalDesignationCount: number,
  designationGroup?: DesignationGroup | null,
  primaryDesignationId?: string | null,
) {
  const cacheId = cacheProxy.identify({ __typename: "Document", id: documentId })!;
  const cachedDocument = cacheProxy.readFragment<Document>({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
  })!;

  const currentConditionalRules = cachedDocument.conditionalRules as Record<string, string[]>;

  // Only add the new group if it does not already exist
  const newDesignationGroups =
    designationGroup && !cachedDocument.designationGroups.some((g) => g.id === designationGroup.id)
      ? cachedDocument.designationGroups.concat(designationGroup)
      : cachedDocument.designationGroups;

  // If the designation is in a group, we find an the id of an already cached group member
  const firstGroupMemberNodeId =
    designationGroup &&
    cachedDocument.designations.edges.find(({ node }) => {
      return node.designationGroupId === designationGroup.id;
    })?.node.id;

  // Iterate through the current conditional rules to see if the group is dependnt
  const derivedPrimaryPair =
    firstGroupMemberNodeId &&
    Object.entries(currentConditionalRules).find(([, rules]) => {
      return rules.includes(firstGroupMemberNodeId);
    });

  // If a primary id was passed or other group members are dependents, we'll need to add conditonal rules
  const newPrimaryId = primaryDesignationId || derivedPrimaryPair?.[0];

  const newConditionalRules = newPrimaryId
    ? addConditionalRuleToCache(currentConditionalRules, newPrimaryId, designations)
    : currentConditionalRules;

  cacheProxy.writeFragment({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
    data: {
      ...cachedDocument,
      conditionalRules: newConditionalRules,
      designations: {
        ...cachedDocument.designations,
        totalCount: cachedDocument.designations.totalCount + additionalDesignationCount,
        edges: [
          ...cachedDocument.designations.edges,
          ...designations.map((d) => ({
            __typename: "AnnotationDesignationEdge",
            node: d,
          })),
        ],
      },
      designationGroups: newDesignationGroups,
    },
  });
}

function removeConditionalRuleFromCache(
  currentConditionalRules: Record<string, string[]>,
  deletedDesignationId: string,
) {
  const newEntries = Object.entries(currentConditionalRules).flatMap(
    ([primaryDesignationId, currentConditionalRules]) => {
      // Designation was a primary so we remove the entire rule
      if (primaryDesignationId === deletedDesignationId) {
        return [];
      }
      // Designation was a dependent so we remove it from the dependent array
      const newConditionalRules: string[] = currentConditionalRules.filter(
        (id) => id !== deletedDesignationId,
      );
      // Return filtered rules, unless no dependents remain, delete the rule entirely
      return newConditionalRules.length ? [[primaryDesignationId, newConditionalRules]] : [];
    },
  );
  return Object.fromEntries(newEntries);
}

function hasConditionalRule(
  currentConditionalRules: Record<string, string[]>,
  designationId: string,
): boolean {
  const isPrimaryDesignation = Boolean(currentConditionalRules[designationId]);
  const isDependentDesignation = Object.values(currentConditionalRules).some((rules) =>
    rules.some((dependentId) => dependentId === designationId),
  );

  return isPrimaryDesignation || isDependentDesignation;
}

function removeDesignationFromDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  documentId: string,
  deletedDesignationId: string,
  newDesignationCount: number,
  groupId: string | null,
) {
  const cacheId = cacheProxy.identify({ __typename: "Document", id: documentId })!;
  const cachedDocument = cacheProxy.readFragment<Document>({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
  })!;
  const newDesignationEdges = cachedDocument.designations.edges.filter(
    (designationEdge) => designationEdge.node.id !== deletedDesignationId,
  );

  const currentConditionalRules = cachedDocument.conditionalRules as Record<string, string[]>;
  const newConditionalRules = hasConditionalRule(currentConditionalRules, deletedDesignationId)
    ? removeConditionalRuleFromCache(currentConditionalRules, deletedDesignationId)
    : currentConditionalRules;

  cacheProxy.writeFragment({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
    data: {
      ...cachedDocument,
      conditionalRules: newConditionalRules,
      designations: {
        ...cachedDocument.designations,
        totalCount: newDesignationCount,
        edges: newDesignationEdges,
      },
      designationGroups:
        // remove group if no remaining designations in it
        groupId && !newDesignationEdges.some((edge) => edge.node.designationGroupId === groupId)
          ? cachedDocument.designationGroups.filter((group) => group.id !== groupId)
          : cachedDocument.designationGroups,
    },
  });
}

function removeDesignationGroupFromDocumentCache(
  cacheProxy: ApolloCache<unknown>,
  documentId: string,
  deletedDesignationGroupId: string,
  deletedDesignationIds: string[],
  newDesignationCount: number,
) {
  const cacheId = cacheProxy.identify({ __typename: "Document", id: documentId })!;
  const cachedDocument = cacheProxy.readFragment<Document>({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
  })!;

  const currentConditionalRules = cachedDocument.conditionalRules as Record<string, string[]>;
  const designationsWithRules = deletedDesignationIds.filter((deletedDesignationId) => {
    return hasConditionalRule(currentConditionalRules, deletedDesignationId);
  });

  const newConditionalRules = designationsWithRules.reduce(
    removeConditionalRuleFromCache,
    currentConditionalRules,
  );

  cacheProxy.writeFragment({
    id: cacheId,
    fragment: PDFDocumentFragment,
    fragmentName: "PrepPdfDocument",
    data: {
      ...cachedDocument,
      conditionalRules: newConditionalRules,
      designations: {
        ...cachedDocument.designations,
        totalCount: newDesignationCount,
        edges: [
          ...cachedDocument.designations.edges.filter(
            (designationEdge) => !deletedDesignationIds.includes(designationEdge.node.id),
          ),
        ],
      },
      designationGroups: cachedDocument.designationGroups.filter(
        (group) => group.id !== deletedDesignationGroupId,
      ),
    },
  });
}

function useAnnotationUpdate(authorId: string, isLockedDocument?: boolean) {
  const updateAnnotationLocationMutateFn = useMutation(UpdateAnnotationLocationMutation);
  const updateAnnotationSizeMutateFn = useMutation(UpdateAnnotationSizeMutation);
  const updateAnnotationTextMutateFn = useMutation(UpdateAnnotationTextMutation);

  return useCallback(
    (
      annotation: Annotation,
      evt: Parameters<Exclude<ComponentProps<typeof AnnotationComp>["onUpdate"], undefined>>[1],
    ) => {
      switch (evt.type) {
        case "move": {
          if (isLockedDocument) {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.ERROR,
              duration: 4000,
              message: (
                <FormattedMessage
                  id="8f676b8e-5c44-4dc6-b4fc-fc6fb8e130f9"
                  defaultMessage="You do not have permission to move this element."
                />
              ),
            });
          }
          const point = { x: evt.newX, y: evt.newY };
          return updateAnnotationLocationMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId,
                location: {
                  point,
                  pageType: annotation.location.pageType,
                  page: annotation.location.page,
                  coordinateSystem: annotation.location.coordinateSystem,
                },
              },
            },
            optimisticResponse: {
              updateAnnotationLocation: {
                __typename: "UpdateAnnotationLocationPayload",
                annotation: {
                  ...annotation,
                  location: {
                    ...annotation.location,
                    point: { ...annotation.location.point, ...point },
                    coordinateSystem: annotation.location.coordinateSystem,
                  },
                },
              },
            },
          });
        }
        case "edittext": {
          const text = evt.newText.replace(/\n/g, " ");
          const width = evt.newWidth;
          return updateAnnotationTextMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId,
                text,
                width,
              },
            },
            optimisticResponse: {
              updateAnnotationText: {
                __typename: "UpdateAnnotationTextPayload",
                annotation: {
                  ...annotation,
                  text,
                  size: { ...annotation.size, width: evt.newWidth },
                } as any, // eslint-disable-line @typescript-eslint/no-explicit-any
              },
            },
          });
        }
        case "resize": {
          if (isLockedDocument) {
            pushNotification({
              type: NOTIFICATION_TYPES.DEFAULT,
              subtype: NOTIFICATION_SUBTYPES.ERROR,
              duration: 4000,
              message: (
                <FormattedMessage
                  id="9b40a790-bdb8-4a50-a3ad-6666bce465e4"
                  defaultMessage="You do not have permission to resize this element."
                />
              ),
            });
          }
          const size = { width: evt.newWidth, height: evt.newHeight };
          return updateAnnotationSizeMutateFn({
            variables: {
              input: {
                id: annotation.id,
                authorId,
                size,
              },
            },
            optimisticResponse: {
              updateAnnotationSize: {
                __typename: "UpdateAnnotationSizePayload",
                annotation: {
                  ...annotation,
                  size: { ...annotation.size, ...size },
                } as any, // eslint-disable-line @typescript-eslint/no-explicit-any
              },
            },
          });
        }
      }
    },
    [authorId],
  );
}

function useAnnotationDelete(
  authorId: string,
  documentId: string,
  isLockedDocument?: boolean | null,
) {
  const removeAnnotationMutateFn = useMutation(RemoveAnnotationMutation);
  return useCallback(
    (annotation: { id: string }) => {
      if (isLockedDocument) {
        pushNotification({
          type: NOTIFICATION_TYPES.DEFAULT,
          subtype: NOTIFICATION_SUBTYPES.ERROR,
          duration: 4000,
          message: (
            <FormattedMessage
              id="0aab43a7-f92a-4af6-ad36-ce8f35a450b2"
              defaultMessage="You do not have permission to delete this element."
            />
          ),
        });
      }

      return removeAnnotationMutateFn({
        variables: { input: { id: annotation.id, authorId } },
        optimisticResponse: {
          removeAnnotation: {
            __typename: "RemoveAnnotationPayload",
            deletedAnnotationId: annotation.id,
          },
        },
        update(cacheProxy, { data }) {
          removeAnnotationFromDocumentCache(
            cacheProxy,
            documentId,
            data!.removeAnnotation!.deletedAnnotationId!,
          );
        },
      });
    },
    [authorId, documentId],
  );
}

function useDesignationUpdate() {
  const updateDesignationLocationMutateFn = useMutation(
    UpdateAnnotationDesignationLocationMutation,
  );
  const updateDesignationDimensionMutateFn = useMutation(
    UpdateAnnotationDesignationDimensionMutation,
  );

  return useCallback(
    (
      designation: Designation,
      evt: Parameters<
        Exclude<ComponentProps<typeof AnnotationDesignation>["onUpdate"], undefined>
      >[1],
    ) => {
      switch (evt.type) {
        case "move": {
          const point = {
            x: evt.newX,
            y: evt.newY,
          };
          return updateDesignationLocationMutateFn({
            variables: {
              input: {
                id: designation.id,
                coordinateSystem: designation.location.coordinateSystem,
                page: designation.location.page,
                point: [point.x, point.y],
              },
            },
            optimisticResponse: {
              updateAnnotationDesignationLocation: {
                __typename: "UpdateAnnotationDesignationLocationPayload",
                annotationDesignation: {
                  ...designation,
                  location: {
                    ...designation.location,
                    point: {
                      ...designation.location.point,
                      ...point,
                    },
                  },
                },
              },
            },
          });
        }
        case "resize": {
          const size = { width: evt.newWidth, height: evt.newHeight };
          return updateDesignationDimensionMutateFn({
            variables: {
              input: {
                id: designation.id,
                size: { width: evt.newWidth, height: evt.newHeight },
              },
            },
            optimisticResponse: {
              updateAnnotationDesignationDimension: {
                __typename: "UpdateAnnotationDesignationDimensionPayload",
                annotationDesignation: {
                  ...designation,
                  size: { ...designation.size, ...size },
                },
              },
            },
          });
        }
      }
    },
    [],
  );
}

function useDesignationReassign() {
  const updateDesignationSignerTypeMutateFn = useMutation(
    UpdateAnnotationDesignationSignerTypeMutation,
  );
  return useCallback(
    (
      signerRole: {
        role: DocumentBundleMembershipRole;
        index: string;
      },
      designation: Designation,
    ) =>
      updateDesignationSignerTypeMutateFn({
        variables: {
          input: {
            annotationDesignationId: designation.id,
            signerRole: { index: signerRole.index, role: signerRole.role },
          },
        },
        optimisticResponse: {
          updateAnnotationDesignationSignerType: {
            __typename: "UpdateAnnotationDesignationSignerTypePayload",
            annotationDesignation: {
              ...designation,
              signerRole: {
                ...signerRole,
                __typename: "SignerRole",
              },
            },
          },
        },
      }),
    [],
  );
}

function useDesignationGroupReassign(designations: Designation[]) {
  const updateDesignationGroupDesigneeMutateFn = useMutation(
    UpdateDesignationGroupDesigneeMutation,
  );
  return useCallback(
    (
      signerRole: {
        role: DocumentBundleMembershipRole;
        index: string;
      },
      designationGroup: DesignationGroup,
    ) =>
      updateDesignationGroupDesigneeMutateFn({
        variables: {
          input: {
            designationGroupId: designationGroup.id,
            signerRole: { index: signerRole.index, role: signerRole.role },
          },
        },
        optimisticResponse: {
          updateDesignationGroupDesignee: {
            __typename: "UpdateDesignationGroupDesigneePayload",
            designations: getDesignationsInGroup(designations, designationGroup.id).map((d) => ({
              ...d,
              signerRole: {
                ...signerRole,
                __typename: "SignerRole",
              },
            })),
          },
        },
      }),
    [],
  );
}

function useDesignationGroupUpdateRequirements() {
  const updateDesignationGroupRequirementsMutateFn = useMutation(
    UpdateDesignationGroupRequirementsMutation,
  );
  return useCallback(
    (designationGroup: DesignationGroup) =>
      updateDesignationGroupRequirementsMutateFn({
        variables: {
          input: {
            designationGroupId: designationGroup.id,
            requirements: {
              minimumFulfilled: designationGroup.minimumFulfilled,
              maximumFulfilled: designationGroup.maximumFulfilled,
            },
          },
        },
        optimisticResponse: {
          updateDesignationGroupRequirements: {
            __typename: "UpdateDesignationGroupRequirementsPayload",
            designationGroup,
            designations: [],
          },
        },
      }),
    [],
  );
}

function getGroupInput(designationType: AnnotationDesignationType) {
  switch (designationType) {
    case AnnotationDesignationType.CHECKMARK:
      return { newGroup: {} };
    case AnnotationDesignationType.RADIO_CHECKMARK:
      return { newGroup: { minimumFulfilled: 1, maximumFulfilled: 1 } };
  }
}

function useCreateDesignations(documentId: string) {
  const { setFocused } = usePDFContext();
  const createDesignationsMutateFn = useMutation(CreateAnnotationDesignationsMutation);

  return useCallback(
    (
      type: AnnotationDesignationType,
      signerRole: NonNullable<CurrentToolData["signerRole"]>,
      points: { x: number; y: number }[],
      { height, width }: { height: number; width: number },
      modifier: AnnotationDesignationModifier | null,
      pageIndex: number,
      pageType: PageTypes,
      groupId: string | null = null,
      primaryDesignationId: string | null = null,
    ) => {
      const groupInput = groupId ? { designationGroupId: groupId } : getGroupInput(type);
      // Always switch focus back to primary designation while in Conditional Edit Mode
      if (primaryDesignationId) {
        setFocused?.(primaryDesignationId);
      }
      return createDesignationsMutateFn({
        variables: {
          input: {
            documentId,
            type,
            locations: points.map(({ x, y }) => ({
              page: pageIndex,
              coordinateSystem: CoordinateSystem.ABSOLUTE,
              point: { x, y },
              size: {
                height,
                width,
              },
            })),
            modifier,
            signerRole: {
              index: signerRole.index,
              role: signerRole.role,
            },
            groupMembership: groupInput,
            primaryDesignationId,
          },
        },
        optimisticResponse: {
          createAnnotationDesignations: {
            __typename: "CreateAnnotationDesignationsPayload",
            designations: points.map(({ x, y }) => ({
              __typename: "AnnotationDesignation",
              id: `signer-designation-preview-${v4()}`,
              fulfilled: false,
              inProgress: false,
              optional: false,
              required: true,
              hint: null,
              active: true,
              dependentDesignationIds: null,
              location: {
                __typename: "AnnotationLocation",
                page: pageIndex,
                pageType,
                point: {
                  __typename: "Point",
                  x,
                  y,
                },
                coordinateSystem: CoordinateSystem.ABSOLUTE,
              },
              signerRole: {
                ...signerRole,
                __typename: "SignerRole",
              },
              type,
              size: {
                __typename: "Size",
                height,
                width,
              },
              designationGroupId: null,
              designeeId: null,
              documentAnchor: null,
            })),
            designationGroup: groupInput
              ? {
                  __typename: "DesignationGroup",
                  id: `signer-designation-group-preview-${v4()}`,
                  minimumFulfilled: 0,
                  maximumFulfilled: null,
                }
              : null,
          },
        },
        update(cacheProxy, { data }) {
          addNewDesignationsToDocumentCache(
            cacheProxy,
            documentId,
            data!.createAnnotationDesignations!.designations,
            data!.createAnnotationDesignations!.designations.length,
            data!.createAnnotationDesignations!.designationGroup,
            primaryDesignationId,
          );
        },
      });
    },
    [documentId, setFocused],
  );
}

function getNextPosition(designations: Designation[], designationGroupId: string) {
  const [lowDesignation] = designations
    .filter((d) => d.designationGroupId === designationGroupId)
    .sort((a, b) => {
      // lower designations at beginning of array
      return a.location.point.y - b.location.point.y;
    });
  const xSortedDesignations = designations
    .filter((d) => d.designationGroupId === designationGroupId)
    .sort((a, b) => {
      // left designations at beginning of array
      return a.location.point.x - b.location.point.x;
    });
  return {
    x:
      (xSortedDesignations[0].location.point.x +
        xSortedDesignations[xSortedDesignations.length - 1].location.point.x) /
      2,
    y: lowDesignation.location.point.y - lowDesignation.size.height - INTER_DESIGNATION_PADDING,
  };
}

const INIT_ADD_TO_GROUP: (sourceDesignation: Designation) => Promise<unknown> = () =>
  Promise.resolve();

function useDesignationAddToGroup(documentId: string, designations: Designation[]) {
  const createDesignations = useCreateDesignations(documentId);
  const addToGroupClicks$ = useSubject<Designation>();
  const addToGroup = useRef(INIT_ADD_TO_GROUP);
  const nextPosition = useRef<{ groupId: string; position: { x: number; y: number } } | null>(null);
  const { setFocused } = usePDFContext();

  useEffect(() => {
    addToGroup.current = (sourceDesignation) => {
      return (
        createDesignations(
          sourceDesignation.type,
          sourceDesignation.signerRole,
          [
            nextPosition.current?.groupId === sourceDesignation.designationGroupId
              ? nextPosition.current.position
              : getNextPosition(designations, sourceDesignation.designationGroupId!),
          ],
          sourceDesignation.size,
          null,
          sourceDesignation.location.page,
          sourceDesignation.location.pageType,
          sourceDesignation.designationGroupId,
        )
          .then(({ data }) => {
            nextPosition.current = {
              groupId: sourceDesignation.designationGroupId!,
              position: getNextPosition(
                data!.createAnnotationDesignations!.designations,
                sourceDesignation.designationGroupId!,
              ),
            };
            setFocused?.(sourceDesignation.id);
          })
          // catch errors so we don't prematurely end observable subscriptions
          // don't update nextPosition so next request will pick up where we left off
          .catch(captureException)
      );
    };
  });

  useEffect(() => {
    const sub = addToGroupClicks$
      .pipe(
        // collect `add to group` requests to be handled sequentially
        concatMap((sourceDesignation) => addToGroup.current(sourceDesignation)),
        // after requests are handled clear nextPosition so can recalculate in case designations are moved
        debounceTime(500),
      )
      .subscribe({
        next: () => {
          nextPosition.current = null;
        },
      });
    return () => sub.unsubscribe();
  }, []);

  return useCallback(
    (sourceDesignation: Designation) => {
      if (!sourceDesignation.designationGroupId) {
        throw new Error("source designation must be in a group");
      }
      addToGroupClicks$.next(sourceDesignation);
    },
    [documentId, designations],
  );
}

export type PageDataType = {
  point: { x: number; y: number };
  pageType: PageTypes;
  pageIndex: number;
};
type AddToGroupClickType = {
  sourceDesignation: Designation;
  pageData: PageDataType;
};

const INIT_ADD_TO_GROUP_V2: ({
  sourceDesignation,
  pageData,
}: AddToGroupClickType) => Promise<unknown> = () => Promise.resolve();

function useDesignationAddToGroupV2(documentId: string) {
  const createDesignations = useCreateDesignations(documentId);
  const addToGroupClicks$ = useSubject<AddToGroupClickType>();
  const addToGroup = useRef(INIT_ADD_TO_GROUP_V2);
  const nextPosition = useRef<{ groupId: string; position: { x: number; y: number } } | null>(null);

  useEffect(() => {
    addToGroup.current = ({ sourceDesignation, pageData }) => {
      const { point, pageIndex, pageType } = pageData;
      return (
        createDesignations(
          sourceDesignation.type,
          sourceDesignation.signerRole,
          [point],
          sourceDesignation.size,
          null,
          pageIndex,
          pageType,
          sourceDesignation.designationGroupId,
        )
          .then(() => {
            nextPosition.current = {
              groupId: sourceDesignation.designationGroupId!,
              position: point,
            };
          })
          // catch errors so we don't prematurely end observable subscriptions
          // don't update nextPosition so next request will pick up where we left off
          .catch(captureException)
      );
    };
  });

  useEffect(() => {
    const sub = addToGroupClicks$
      .pipe(
        // collect `add to group` requests to be handled sequentially
        concatMap((props) => {
          return addToGroup.current(props);
        }),
        // after requests are handled clear nextPosition so can recalculate in case designations are moved
        debounceTime(500),
      )
      .subscribe({
        next: () => {
          nextPosition.current = null;
        },
      });
    return () => sub.unsubscribe();
  }, []);

  return useCallback(
    (sourceDesignation: Designation, pageData: PageDataType) => {
      if (!sourceDesignation.designationGroupId) {
        throw new Error("source designation must be in a group");
      }
      addToGroupClicks$.next({ sourceDesignation, pageData });
    },
    [documentId],
  );
}

function isGroupableType(type: string) {
  const groupableTypes = ["CHECKMARK", "RADIO_CHECKMARK"];
  return groupableTypes.includes(type);
}

function useDesignationAddToGroupTool(authorId: string, documentId: string): PagePressTool {
  const showMultiPageGroupedDesignations = useFeatureFlag(MULTI_PAGE_GROUPED_DESIGNATIONS);
  const createDesignations = useCreateDesignations(documentId);
  const handleDesignationAddToGroup = useDesignationAddToGroupV2(documentId);

  return useMemo(
    () => ({
      canHandle: ({ placementType, subtype, sourceDesignation }) => {
        if (showMultiPageGroupedDesignations) {
          return (
            placementType === "designation" &&
            Boolean(subtype && isGroupableType(subtype)) &&
            Boolean(sourceDesignation)
          );
        }
        return false;
      },
      handler: ({ pageIndex, point, pageType, currentToolData }) => {
        const pageData = { pageIndex, point, pageType };
        const { sourceDesignation } = currentToolData;
        return sourceDesignation
          ? handleDesignationAddToGroup(sourceDesignation, pageData)
          : undefined;
      },
    }),
    [authorId, documentId, createDesignations],
  );
}

function useDesignationAddToGroupToolSelect(sourceDesignation: GroupDrawableDesignation) {
  const dispatch = useDispatch();
  if (!isGroupableType(sourceDesignation.type)) {
    return undefined;
  }

  const { type, signerRole } = sourceDesignation;
  const toolData = {
    type,
    subtype: type,
    placementType: "designation",
    signerRole,
  };
  return () =>
    dispatch(
      toolClicked({
        data: { ...toolData, sourceDesignation },
      }),
    );
}

function useDesignationDelete(documentId: string, documentDesignationCount: number) {
  const deleteDesignationMutateFn = useMutation(DeleteAnnotationDesignationMutation);
  return useCallback(
    (designation: Designation) =>
      deleteDesignationMutateFn({
        variables: { input: { id: designation.id } },
        optimisticResponse: {
          deleteAnnotationDesignation: {
            __typename: "DeleteAnnotationDesignationPayload",
            deletedAnnotationDesignationId: designation.id,
          },
        },
        update(cacheProxy, { data }) {
          removeDesignationFromDocumentCache(
            cacheProxy,
            documentId,
            data!.deleteAnnotationDesignation!.deletedAnnotationDesignationId!,
            documentDesignationCount - 1,
            designation.designationGroupId,
          );
        },
      }),
    [documentId, documentDesignationCount],
  );
}

function useDesignationGroupDelete(documentId: string, designations: Designation[]) {
  const deleteDesignationGroupMutateFn = useMutation(DeleteDesignationGroupMutation);
  return useCallback(
    (designationGroup: DesignationGroup) => {
      const deletedDesignationIds = getDesignationsInGroup(designations, designationGroup.id).map(
        (d) => d.id,
      );
      return deleteDesignationGroupMutateFn({
        variables: { input: { designationGroupId: designationGroup.id } },
        optimisticResponse: {
          deleteDesignationGroup: {
            __typename: "DeleteDesignationGroupPayload",
            deletedDesignationGroupId: designationGroup.id,
            deletedDesignationIds,
          },
        },
        update(cacheProxy, { data }) {
          removeDesignationGroupFromDocumentCache(
            cacheProxy,
            documentId,
            data!.deleteDesignationGroup!.deletedDesignationGroupId,
            data!.deleteDesignationGroup!.deletedDesignationIds,
            designations.length - deletedDesignationIds.length,
          );
        },
      });
    },
    [documentId, designations],
  );
}

function useCheckmarkAnnotationTool(authorId: string, documentId: string): PagePressTool {
  const addCheckmarkAnnotationMutateFn = useMutation(AddCheckmarkAnnotationMutation);

  return useMemo(
    () => ({
      canHandle: ({ placementType, type }) =>
        placementType === "annotation" && type === "CHECKMARK",
      handler: ({ pageIndex, point, pageType }) => {
        const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.CHECKMARK });
        return addCheckmarkAnnotationMutateFn({
          variables: {
            input: {
              authorId,
              documentId,
              location: {
                page: pageIndex,
                pageType,
                point: {
                  x: point.x,
                  y: point.y,
                },
                coordinateSystem: CoordinateSystem.ABSOLUTE,
              },
              size,
            },
          },
          optimisticResponse: {
            addCheckmarkAnnotation: {
              __typename: "AddCheckmarkAnnotationPayload",
              annotation: {
                __typename: "CheckmarkAnnotation",
                id: createOptimisticId(),
                annotationDesignationId: null,
                authorId,
                location: {
                  __typename: "AnnotationLocation" as const,
                  page: pageIndex,
                  pageType,
                  point: {
                    __typename: "Point" as const,
                    x: point.x,
                    y: point.y,
                  },
                  coordinateSystem: CoordinateSystem.ABSOLUTE,
                },
                subtype: AnnotationSubtype.CHECKMARK,
                size: {
                  ...size,
                  __typename: "Size",
                },
                documentAnchor: null,
                canEdit: true,
              },
              annotationDesignation: null,
            },
          },
          update(cacheProxy, { data }) {
            addNewAnnotationToDocumentCache(
              cacheProxy,
              documentId,
              data!.addCheckmarkAnnotation!.annotation!,
            );
          },
        });
      },
    }),
    [authorId, documentId],
  );
}

function useTextAnnotationTool(authorId: string, documentId: string): PagePressTool {
  const { setFocused } = usePDFContext();
  const addTextAnnotationMutateFn = useMutation(AddTextAnnotationMutation);

  return useMemo(
    () => ({
      canHandle: ({ placementType, type }) => placementType === "annotation" && type === "TEXT",
      handler: ({ pageIndex, point, pageType, currentToolData }) => {
        const text = textAnnotationContent(currentToolData.subtype!, {
          contact: currentToolData.contactInformation,
        });
        const size = annotationPdfPointSizeFromText(text);
        return addTextAnnotationMutateFn({
          variables: {
            input: {
              authorId,
              documentId,
              location: {
                page: pageIndex,
                pageType,
                point: {
                  x: point.x,
                  y: point.y,
                },
                coordinateSystem: CoordinateSystem.ABSOLUTE,
              },
              size,
              text,
            },
          },
          optimisticResponse: {
            addTextAnnotation: {
              __typename: "AddTextAnnotationPayload",
              annotation: {
                __typename: "TextAnnotation",
                id: createOptimisticId(),
                annotationDesignationId: null,
                authorId,
                location: {
                  __typename: "AnnotationLocation" as const,
                  page: pageIndex,
                  pageType,
                  point: {
                    __typename: "Point" as const,
                    x: point.x,
                    y: point.y,
                  },
                  coordinateSystem: CoordinateSystem.ABSOLUTE,
                },
                subtype: AnnotationSubtype.CHECKMARK,
                size: {
                  ...size,
                  __typename: "Size",
                },
                text,
                documentAnchor: null,
                canEdit: true,
              },
              annotationDesignation: null,
            },
          },
          update(cacheProxy, { data }) {
            addNewAnnotationToDocumentCache(
              cacheProxy,
              documentId,
              data!.addTextAnnotation!.annotation!,
            );
          },
        }).then(({ data }) => {
          if (currentToolData.subtype === AnnotationSubtype.FREE_TEXT) {
            const annotationId = data?.addTextAnnotation?.annotation?.id;
            if (annotationId) {
              setFocused?.(annotationId);
            }
          }
        });
      },
    }),
    [authorId, documentId, setFocused],
  );
}

function useWhiteboxAnnotationTool(authorId: string, documentId: string): PagePressTool {
  const addWhiteboxAnnotationMutateFn = useMutation(AddWhiteboxAnnotationMutation);
  return useMemo(
    () => ({
      canHandle: ({ placementType, type }) => placementType === "annotation" && type === "WHITEBOX",
      handler: ({ pageIndex, point, pageType }) => {
        const size = annotationDefaultPdfPointSize({ type: AnnotationSubtype.WHITEBOX });

        return addWhiteboxAnnotationMutateFn({
          variables: {
            input: {
              authorId,
              documentId,
              location: {
                page: pageIndex,
                pageType,
                point: {
                  x: point.x,
                  y: point.y,
                },
                coordinateSystem: CoordinateSystem.ABSOLUTE,
              },
              size,
            },
          },
          optimisticResponse: {
            addWhiteboxAnnotation: {
              __typename: "AddWhiteboxAnnotationPayload",
              annotation: {
                __typename: "WhiteboxAnnotation",
                id: createOptimisticId(),
                annotationDesignationId: null,
                authorId,
                location: {
                  __typename: "AnnotationLocation" as const,
                  page: pageIndex,
                  pageType,
                  point: {
                    __typename: "Point" as const,
                    x: point.x,
                    y: point.y,
                  },
                  coordinateSystem: CoordinateSystem.ABSOLUTE,
                },
                subtype: AnnotationSubtype.WHITEBOX,
                size: {
                  ...size,
                  __typename: "Size",
                },
                documentAnchor: null,
                canEdit: true,
              },
            },
          },
          update(cacheProxy: ApolloCache<unknown>, { data }) {
            addNewAnnotationToDocumentCache(
              cacheProxy,
              documentId,
              data!.addWhiteboxAnnotation!.annotation!,
            );
          },
        });
      },
    }),
    [authorId, documentId],
  );
}

function useDesignationTool(authorId: string, documentId: string): PagePressTool {
  const showMultiPageGroupedDesignations = useFeatureFlag(MULTI_PAGE_GROUPED_DESIGNATIONS);
  const createDesignations = useCreateDesignations(documentId);
  return useMemo(
    () => ({
      canHandle: ({ placementType, type, subtype, sourceDesignation }) => {
        if (showMultiPageGroupedDesignations) {
          return (
            placementType === "designation" &&
            type !== "COMPOUND" &&
            subtype !== ANNOTATION_SUBTYPES.RADIO_CHECKMARK &&
            Boolean(!sourceDesignation)
          );
        }
        return (
          placementType === "designation" &&
          type !== "COMPOUND" &&
          subtype !== ANNOTATION_SUBTYPES.RADIO_CHECKMARK
        );
      },
      handler: ({ pageIndex, point, pageType, currentToolData }) => {
        // This is used for document tagging templating where users can apply a multisigner_initials designation
        // and this would let the API know to mark this document as a special type where we do special handling
        const isMultisigner = currentToolData.subtype === ANNOTATION_SUBTYPES.MULTISIGNER_INITIALS;
        const coercedSubtype = (
          isMultisigner ? ANNOTATION_SUBTYPES.INITIALS : currentToolData.subtype
        ) as AnnotationDesignationType;
        const modifier = isMultisigner ? AnnotationDesignationModifier.MULTISIGNER_ONLY : null;
        const primaryDesignationId = currentToolData.primaryDesignationId;

        return createDesignations(
          coercedSubtype,
          currentToolData.signerRole!,
          [point],
          designationDefaultPdfPointSize(currentToolData.subtype!),
          modifier,
          pageIndex,
          pageType,
          null,
          primaryDesignationId,
        );
      },
    }),
    [authorId, documentId, createDesignations],
  );
}

function useRadioDesignationTool(authorId: string, documentId: string): PagePressTool {
  const showMultiPageGroupedDesignations = useFeatureFlag(MULTI_PAGE_GROUPED_DESIGNATIONS);
  const createDesignations = useCreateDesignations(documentId);
  return useMemo(
    () => ({
      canHandle: ({ placementType, subtype, sourceDesignation }) => {
        if (showMultiPageGroupedDesignations) {
          return (
            placementType === "designation" &&
            subtype === ANNOTATION_SUBTYPES.RADIO_CHECKMARK &&
            Boolean(!sourceDesignation)
          );
        }
        return placementType === "designation" && subtype === ANNOTATION_SUBTYPES.RADIO_CHECKMARK;
      },
      handler: ({ pageIndex, point, pageType, currentToolData }) => {
        const size = designationDefaultPdfPointSize(AnnotationDesignationType.RADIO_CHECKMARK);
        return createDesignations(
          AnnotationDesignationType.RADIO_CHECKMARK,
          currentToolData.signerRole!,
          [point, { x: point.x, y: point.y - size.height - INTER_DESIGNATION_PADDING }],
          size,
          null,
          pageIndex,
          pageType,
          null,
          currentToolData.primaryDesignationId,
        );
      },
    }),
    [authorId, documentId, createDesignations],
  );
}

function useCompoundDesignationTool(authorId: string, documentId: string): PagePressTool {
  const createDesignations = useCreateDesignations(documentId);
  return useMemo(
    () => ({
      canHandle: ({ placementType, type }) =>
        placementType === "designation" && type === "COMPOUND",
      handler: ({ pageIndex, point, pageType, currentToolData }) => {
        const { subDesignations, nextDesignationLocations } = subDesignationsForCompoundType({
          subtype: currentToolData.subtype!,
          signerRole: currentToolData.signerRole,
        });

        const currentPoint = { ...point };
        let prevSize = { height: 0, width: 0 };
        return Promise.all(
          subDesignations.map(
            (
              subDesignation: {
                subtype: string;
                signerRole: NonNullable<CurrentToolData["signerRole"]>;
              },
              index: number,
            ) => {
              const size = designationDefaultPdfPointSize(subDesignation.subtype);
              const mutate = createDesignations(
                subDesignation.subtype as AnnotationDesignationType,
                subDesignation.signerRole,
                [currentPoint],
                size,
                null,
                pageIndex,
                pageType,
                null,
                currentToolData.primaryDesignationId,
              );

              const nextLocation = nextDesignationLocations[index];
              switch (nextLocation) {
                case COMPOUND_ANNOTATION_LOCATIONS.RIGHT:
                  currentPoint.x += size.width + INTER_DESIGNATION_PADDING;
                  break;
                case COMPOUND_ANNOTATION_LOCATIONS.BELOW_LEFT:
                  currentPoint.x -= prevSize.width + INTER_DESIGNATION_PADDING;
                  currentPoint.y -= prevSize.height + INTER_DESIGNATION_PADDING;
                  break;
                case COMPOUND_ANNOTATION_LOCATIONS.BELOW:
                  currentPoint.y -= size.height + INTER_DESIGNATION_PADDING;
                  break;
              }
              prevSize = size;

              return mutate;
            },
          ),
        );
      },
    }),
    [authorId, documentId, createDesignations],
  );
}

function useUpdateDesignationOptionality() {
  const optionalDesignationMutateFn = useMutation(UpdateAnnotationDesignationOptionalityMutation);
  return useCallback(
    (designation: Designation, optional: boolean) =>
      optionalDesignationMutateFn({
        variables: { input: { id: designation.id, optional } },
        optimisticResponse: {
          updateAnnotationDesignationOptionality: {
            __typename: "UpdateAnnotationDesignationOptionalityPayload",
            annotationDesignation: {
              ...designation,
            },
          },
        },
      }),
    [],
  );
}

export {
  useAnnotationUpdate,
  useAnnotationDelete,
  useDesignationUpdate,
  useDesignationReassign,
  useDesignationAddToGroup,
  useDesignationAddToGroupTool,
  useDesignationAddToGroupToolSelect,
  useDesignationDelete,
  useDesignationGroupReassign,
  useDesignationGroupUpdateRequirements,
  useDesignationGroupDelete,
  useCheckmarkAnnotationTool,
  useTextAnnotationTool,
  useWhiteboxAnnotationTool,
  useDesignationTool,
  useRadioDesignationTool,
  useCompoundDesignationTool,
  usePagePress,
  useUpdateDesignationOptionality,
};
