import { type ReactNode, useState } from "react";

import { type PresendValidationOrganizationTransaction as Transaction } from "./organization_transaction_fragment.graphql";
import { type PresendValidationOrganization as Organization } from "./organization_fragment.graphql";
import { type PresendValidationViewer as Viewer } from "./viewer_fragment.graphql";
import { type Config } from "../config";
import { constructValidations, type Validation } from "./util";

type ValidationResultAdditions = {
  recallReason?: string;
};

export function useValidateBeforeSend(config: Config) {
  const [submitErrorModal, setSubmitErrorModal] = useState<ReactNode>(null);
  const validations = constructValidations(config.validations);

  const validateBeforeSend = async ({
    transaction,
    organization,
    viewer,
  }: {
    transaction: Transaction;
    organization: Organization;
    viewer: Viewer;
  }): ReturnType<Validation> => {
    const options = {} as ValidationResultAdditions;
    for await (const validation of validations) {
      const validationResult = await validation({
        transaction,
        organization,
        viewer,
        config,
        setSubmitErrorModal,
      });
      if (validationResult.status === "failed") {
        return { status: "failed", error: validationResult.error || null };
      }
      if (validationResult.recallReason) {
        options.recallReason = validationResult.recallReason;
      }
    }
    return { status: "passed", ...options };
  };

  return {
    validateBeforeSend,
    presendErrorModal: submitErrorModal,
  };
}
