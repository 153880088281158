import { useState } from "react";
import { isPast } from "date-fns";
import { FormattedMessage } from "react-intl";

import { useMutation, useQuery } from "util/graphql";
import { Card, CardText } from "common/core/card";
import LoadingIndicator from "common/core/loading_indicator";
import CertificateCard from "common/notary/certificate/card";
import { CustomFormattedDateTime, FormattedDate } from "common/core/format/date";
import AlertMessage from "common/core/alert_message";
import PopoutMenu from "common/core/popout_menu";
import Button from "common/core/button";
import Icon from "common/core/icon";
import PopoutMenuItem from "common/core/popout_menu/item";
import RevokeCertModal from "common/notary/certificate_management/revoke_cert_modal";

import CertificateQuery from "./certificate_query.graphql";
import RevokeCertificateMutation from "./revoke_certificate_mutation.graphql";
import Styles from "./index.module.scss";

export function Certificate() {
  const [showRevocationModal, setShowRevocationModal] = useState(false);
  const { data, loading } = useQuery(CertificateQuery);
  const revokeCertificateMutation = useMutation(RevokeCertificateMutation);
  if (loading || !data?.viewer.user) {
    return <LoadingIndicator />;
  }
  const user = data.viewer.user;
  const onRevoke = () => {
    return revokeCertificateMutation({
      variables: { input: { userId: user.id } },
    }).then(() => {
      setShowRevocationModal(false);
    });
  };
  if (!user.certificate) {
    return (
      <Card>
        <CardText>
          <FormattedMessage
            id="bdad9746-27c4-4378-98d5-4dbfe20669a2"
            defaultMessage="You do not have a Proof certificate yet."
          />
        </CardText>
      </Card>
    );
  }
  const certificate = user.certificate;
  const isCertificateExpired = isPast(certificate.validTo);
  const isCertificateRevoked = Boolean(certificate.revokedAt);
  const certificateSubject = certificate.subject.split("=").pop();
  return (
    <Card>
      <CardText>
        <CertificateCard
          user={{ firstName: certificateSubject }}
          disabled={isCertificateRevoked || isCertificateExpired}
        />
        {isCertificateRevoked && (
          <AlertMessage className={Styles.certificateAlert}>
            <FormattedMessage
              id="f10b4cef-33b0-4609-9c74-c1d9e8a9785d"
              defaultMessage="This certificate was revoked on {date}"
              values={{
                date: <CustomFormattedDateTime formatStyle="PPP" value={certificate.revokedAt} />,
              }}
            />
          </AlertMessage>
        )}
        {!isCertificateRevoked && isCertificateExpired && (
          <AlertMessage className={Styles.certificateAlert}>
            <FormattedMessage
              id="a5cf8d36-4c08-41f3-ad8e-6cee29f8faae"
              defaultMessage="This certificate expired on {date}"
              values={{
                date: <CustomFormattedDateTime formatStyle="PPPppp" value={certificate.validTo} />,
              }}
            />
          </AlertMessage>
        )}
        <div className={Styles.certificateInfo}>
          <div className={Styles.infoHeading}>
            <FormattedMessage
              id="528319c7-532a-467d-90ae-ae1f3c141fef"
              defaultMessage="Full name"
            />
            <div>{certificateSubject}</div>
          </div>

          <div className={Styles.infoHeading}>
            <FormattedMessage
              id="8ec971a9-6786-4c3b-85c2-1e5599fb534b"
              defaultMessage="Expiration date"
            />
            <div>
              <FormattedDate value={certificate.validTo} />
            </div>
          </div>
        </div>
        <div className={Styles.certificateManagementSection}>
          <PopoutMenu
            placement="bottomLeft"
            target={(open) => (
              <Button
                buttonColor="action"
                variant="secondary"
                withIcon={{ placement: "left", name: "settings" }}
                disabled={isCertificateRevoked}
              >
                <FormattedMessage
                  id="5da1e400-80bf-4bcd-91f1-8f1caba724b5"
                  defaultMessage="Settings {open}"
                  values={{
                    open: <Icon name={open ? "caret-up" : "caret-down"} className={Styles.icon} />,
                  }}
                />
              </Button>
            )}
            className={Styles.popoutMenu}
          >
            {({ close }) => (
              <div className={Styles.popoutMenu}>
                <PopoutMenuItem
                  onClick={() => {
                    close();
                    setShowRevocationModal(true);
                  }}
                  danger
                >
                  <FormattedMessage
                    id="37d3b7d6-dfb9-4596-84b4-00c937a22717"
                    defaultMessage="{icon} Revoke Certificate"
                    values={{
                      icon: <Icon name="x-filled" size="large" className={Styles.menuIcon} />,
                    }}
                  />
                </PopoutMenuItem>
              </div>
            )}
          </PopoutMenu>
          {showRevocationModal && (
            <RevokeCertModal
              proofCert={certificate}
              onCancel={() => setShowRevocationModal(false)}
              onRevoke={onRevoke}
              certBelongsToCurrentUser
            />
          )}
        </div>
      </CardText>
    </Card>
  );
}
