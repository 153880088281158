import { memo } from "react";
import { FormattedMessage } from "react-intl";

import { NotaryItemWrapper } from "../notary_item_wrapper";

type Props = {
  meeting: {
    publicNotaryDetails: null | { notaryId: string | null };
  };
};

function AgentRegistration({ meeting }: Props) {
  return (
    <NotaryItemWrapper
      term={
        <FormattedMessage
          id="bd46acc1-c879-4648-9604-fb8263b7332c"
          defaultMessage="Notary Commission Number"
        />
      }
      definition={meeting.publicNotaryDetails?.notaryId}
    />
  );
}

export default memo(AgentRegistration);
