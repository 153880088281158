import { format } from "common/core/format/date";
import calculatePixelSize from "util/pixel_size";
import { userFullName } from "util/user";
import ReduxStore from "redux/store";
import {
  DESIGNATED_AGENT_TEXT,
  ANNOTATION_TYPES,
  ANNOTATION_SUBTYPES,
  COMPOUND_ANNOTATION_LOCATIONS,
  VECTOR_GRAPHIC_TYPES,
  INTER_DESIGNATION_PADDING,
} from "constants/annotations";
import {
  TEXT_ANNOTATION_PLACEHOLDER,
  CHECKMARK_SIZE,
  TEXT_ANNOTATION_FONT_SIZE,
} from "constants/globals";
import { browserTimeZone } from "util/date";

const LONG_DESIGNATION_WIDTH = 175;
const DESIGNATION_WIDTH = 155;
const SHORT_DESIGNATION_WIDTH = 125;
const SEAL_DIMENSION = 200;
const STANDARD_HEIGHT = 35;
const PX_TO_PT = 0.68;

const { TEXT, VECTOR_GRAPHIC, CHECKMARK, WHITEBOX, IMAGE } = ANNOTATION_TYPES;
const {
  DATE,
  DATE_SIGNED,
  DAY_SIGNED,
  MONTH_SIGNED,
  YEAR_SIGNED,
  COSIGNER_NAME,
  SIGNER_NAME,
  AGENT_NAME,
  NOTARY_NAME,
  NAME,
  FIRST_NAME,
  LAST_NAME,
  NA,
  ADDRESS,
  ZIP,
  EMAIL,
  DOB,
  SEAL,
  DISCLAIMER,
  COMMISSION_EXPIRY,
  NOTARY_ID,
  DESIGNATED_AGENT,
  STATE,
  COUNTY,
  DISCLOSURE,
  FREE_TEXT,
  REPRESENTATIVE_CAPACITY_TYPE,
  DAY_MONTH_YEAR,
} = ANNOTATION_SUBTYPES;

export const MIN_CHECKMARK_SIZE_PT = 12 * PX_TO_PT;
export const MAX_CHECKMARK_SIZE_PT = 24 * PX_TO_PT;

// Static annotations text + properties

const GENERAL_TEXT_ANNOTATIONS = [
  DATE,
  DATE_SIGNED,
  DAY_SIGNED,
  MONTH_SIGNED,
  YEAR_SIGNED,
  COSIGNER_NAME,
  SIGNER_NAME,
  AGENT_NAME,
  NAME,
  FIRST_NAME,
  LAST_NAME,
  NA,
  REPRESENTATIVE_CAPACITY_TYPE,
  DAY_MONTH_YEAR,
];
const SIGNER_TEXT_ANNOTATIONS = [ADDRESS, ZIP, DOB, EMAIL];
const NOTARY_TEXT_ANNOTATIONS = [
  DISCLAIMER,
  STATE,
  COUNTY,
  COMMISSION_EXPIRY,
  NOTARY_ID,
  DESIGNATED_AGENT,
  DISCLOSURE,
  NOTARY_NAME,
];
const ordinalize = (number) => {
  if (number < 1 || number > 31) {
    return number.toString();
  }

  const suffixes = ["st", "nd", "rd"];
  const remainder = number % 10;

  if (number >= 11 && number <= 13) {
    return `${number.toString()}th`;
  }

  const suffix = remainder <= 3 && remainder !== 0 ? suffixes[remainder - 1] : "th";
  return number.toString() + suffix;
};

export const getDayMonthYear = () => {
  const currentDay = new Date().toLocaleDateString(undefined, {
    day: "numeric",
  });
  const currentMonth = new Date().toLocaleDateString(undefined, {
    month: "long",
  });
  const currentYear = new Date().toLocaleDateString(undefined, {
    year: "numeric",
  });

  return [ordinalize(currentDay), currentMonth, currentYear];
};

const generalTextAnnotationContent = (annotationSubtype, userProfile, timezone) => {
  switch (annotationSubtype) {
    case DATE:
    case DATE_SIGNED:
      return format({
        value: new Date(),
        asTimeZone: timezone || browserTimeZone(),
        formatStyle: "P",
      });
    case DAY_MONTH_YEAR:
      return getDayMonthYear().join(" ");
    case DAY_SIGNED:
      return ordinalize(new Date().getDate());
    case MONTH_SIGNED:
      return new Date().toLocaleString("default", { month: "long" });
    case YEAR_SIGNED:
      return new Date().getFullYear().toString();
    case COSIGNER_NAME:
    case SIGNER_NAME:
    case AGENT_NAME:
    case NAME:
      return [userProfile.firstName, userProfile.middleName, userProfile.lastName]
        .filter(Boolean)
        .join(" ");
    case FIRST_NAME:
      return userProfile.firstName;
    case LAST_NAME:
      return userProfile.lastName;
    case NA:
      return "N/A";
    case REPRESENTATIVE_CAPACITY_TYPE:
      return userProfile.capacity;
    case FREE_TEXT:
    default:
      return "";
  }
};

const notaryTextAnnotationContent = (annotationSubtype, userProfile, notaryProfile) => {
  switch (annotationSubtype) {
    case DISCLAIMER:
      return notaryProfile.notaryDisclaimerText;
    case STATE:
      return notaryProfile.usState.abbreviation;
    case COUNTY:
      return notaryProfile.county;
    case COMMISSION_EXPIRY:
      return format({ value: notaryProfile.licenseExpiry, formatStyle: "P" });
    case NOTARY_ID:
      return notaryProfile.notaryId;
    case DESIGNATED_AGENT:
      return DESIGNATED_AGENT_TEXT;
    case NOTARY_NAME:
      return userFullName(userProfile);
    default:
      return "";
  }
};

const signerTextAnnotationContent = (annotationSubtype, customerProfile) => {
  switch (annotationSubtype) {
    case ADDRESS: {
      const { line1, line2, postal, city, state } = customerProfile.address;
      const statePostal = [state, postal].join(" ").trim();
      const trimmedLine2 = typeof line2 === "string" ? line2.trim() : line2;
      return trimmedLine2
        ? [line1, line2, city, statePostal].join(", ")
        : [line1, city, statePostal].join(", ");
    }
    case ZIP:
      return customerProfile.address.postal;
    case DOB:
      return format({ value: customerProfile.dob, formatStyle: "P" });
    case EMAIL:
      return customerProfile.email;
    default:
      return "";
  }
};

/**
 * Get text annotation content for a given text annotation subtype.
 * @param {string} annotationSubtype The subtype of text annotation to get content for | type ∈ ANNOTATION_SUBTYPES
 * @param {object} param1 Optional named params
 * - @param contact The contact information related to the annotation being placed (signer info, cosigner info, etc.)
 * - @param notaryProfile The notary's profile information
 */
export function textAnnotationContent(annotationSubtype, { contact, notaryProfile, timezone }) {
  if (GENERAL_TEXT_ANNOTATIONS.includes(annotationSubtype)) {
    return generalTextAnnotationContent(annotationSubtype, contact, timezone);
  } else if (SIGNER_TEXT_ANNOTATIONS.includes(annotationSubtype)) {
    return signerTextAnnotationContent(annotationSubtype, contact);
  } else if (NOTARY_TEXT_ANNOTATIONS.includes(annotationSubtype)) {
    return notaryTextAnnotationContent(annotationSubtype, contact, notaryProfile);
  }
  return "";
}

/** @type {(opts: { text: string; width: number }) => number} */
export function scaleFontSize({ text, width }) {
  // it turns out width is the driving motivation for how font size scales
  // so we don't really need height: but if we were OK with being more stretchy
  // it could theoretically be used

  const initialWidth = calculatePixelSize(text, { fontSize: TEXT_ANNOTATION_FONT_SIZE }).width;
  return Math.round(parseFloat(TEXT_ANNOTATION_FONT_SIZE) * (Number(width) / initialWidth));
}

// Image annotations sources
function imageAnnotationSrc(subtype, { notaryProfile }) {
  if (!notaryProfile) {
    return null;
  }

  switch (subtype) {
    case ANNOTATION_SUBTYPES.SIGNATURE:
    case ANNOTATION_SUBTYPES.NOTARY_SIGNATURE:
      return notaryProfile.signature.asset.key;
    case ANNOTATION_SUBTYPES.INITIALS:
      return notaryProfile.initials.asset.key;
    default:
      return null;
  }
}

function fixAssetSize(size, { maxHeight, maxWidth }) {
  let [width, height] = size;

  if (width > maxWidth) {
    height = (height / width) * maxWidth;
    width = maxWidth;
  }

  if (height > maxHeight) {
    width = (width / height) * maxHeight;
    height = maxHeight;
  }

  return { width, height };
}

function maxAssetSize(type) {
  const maxValues = {};

  if (type === SEAL) {
    maxValues.maxWidth = SEAL_DIMENSION;
    maxValues.maxHeight = SEAL_DIMENSION;
  } else {
    maxValues.maxWidth = 500;
    maxValues.maxHeight = 65;
  }
  return maxValues;
}

function imageAnnotationSize(type, { notaryProfile, maxHeight, authorId }) {
  if (!notaryProfile) {
    let size;
    if (authorId) {
      const vectorGraphicType =
        type === ANNOTATION_SUBTYPES.SIGNATURE
          ? VECTOR_GRAPHIC_TYPES.SIGNATURE
          : VECTOR_GRAPHIC_TYPES.INITIALS;
      size = cachedVectorGraphicSize({ type: vectorGraphicType, authorId }); // can return null
    }

    // fallback to use designation size
    return size || newAnnotationDesignationDefaults(type);
  }

  // Since we had to introduce notary signature as a special annotation type,
  // and notary signatures are stored under `notaryProfile.signature`, we have to
  // do a special check
  if (type === ANNOTATION_SUBTYPES.NOTARY_SIGNATURE) {
    type = ANNOTATION_SUBTYPES.SIGNATURE;
  }

  const { width, height } = notaryProfile[type.toLowerCase()];
  const maxValues = maxAssetSize(type);
  if (maxHeight) {
    maxValues.maxHeight = maxHeight;
  }
  return fixAssetSize([width, height], maxValues);
}

function cachedVectorGraphicSize({ type, authorId }) {
  const store = getReduxStoreFromVectorGraphicType({ type });
  const cachedVectorGraphic = store && store.find((object) => object.signerId === authorId);

  if (cachedVectorGraphic && cachedVectorGraphic.size) {
    return {
      height: cachedVectorGraphic.size.height,
      width: cachedVectorGraphic.size.width,
    };
  }

  return null;
}

function getReduxStoreFromVectorGraphicType({ type }) {
  const meetingStore = ReduxStore.getState().meeting;
  switch (type) {
    case VECTOR_GRAPHIC_TYPES.SIGNATURE:
      return meetingStore.signatures;
    case VECTOR_GRAPHIC_TYPES.INITIALS:
      return meetingStore.initialses;
    default:
  }
}

// Annotation defaults

/**
 * Generates defaults for a new annotation to be created.
 * @param {{ type: string, subtype: string }} annotationMetadata
 *  Object containing data about the type, subtype, kind, maxHeight, and annotationFontSize of the new annotation.
 * @param {{ contact?: Object, notaryProfile?: Object, authorId?: string }} contentData
 *  Data used to help provide the new defaults, if necessary.
 */
// TODO: this bullshit method is a black box for defaults, and is the antithesis of self-documenting
// remove it wherever it's called, and write good code that doesn't require investigating
// what's going on in here everytime someone wants to know what happens in a new annotation
function newAnnotationDefaults({ type, subtype }, { contact, notaryProfile, authorId }) {
  switch (type) {
    case TEXT: {
      const editable = subtype === ANNOTATION_SUBTYPES.FREE_TEXT;
      const text = textAnnotationContent(subtype, { contact, notaryProfile });
      const size = calculatePixelSize(editable ? TEXT_ANNOTATION_PLACEHOLDER : text);

      return {
        size,
        text,
        editable,
      };
    }
    case WHITEBOX:
      return {
        size: {
          width: 200,
          height: 150,
        },
        editable: true,
      };

    case CHECKMARK:
      return {
        size: {
          width: CHECKMARK_SIZE,
          height: CHECKMARK_SIZE,
        },
      };

    case IMAGE:
    case VECTOR_GRAPHIC:
      return {
        key: imageAnnotationSrc(subtype, { notaryProfile }),
        size: imageAnnotationSize(subtype, { notaryProfile, authorId }),
        editable: true,
      };

    default:
      return {};
  }
}

/** @type {(opts: { type: string; subtype?: string }, extra?: Record<string, unknown>) => { height: number; width: number }} */
export function annotationDefaultPdfPointSize(
  { type, subtype = null },
  { contact = null, notaryProfile = null, authorId = null } = {},
) {
  return scaleWidthHeight(
    newAnnotationDefaults({ type, subtype }, { contact, notaryProfile, authorId }).size,
    PX_TO_PT,
  );
}

export function annotationPdfPointSizeFromText(text) {
  return scaleWidthHeight(calculatePixelSize(text || TEXT_ANNOTATION_PLACEHOLDER), PX_TO_PT);
}

// Annotation Designation defaults

/**
 * Get defaults (namely - height & width) for a new annotation designation that's being
 * created.
 * @param {string} type The type of annotaiton to create on fulfillment.
 * Member of ANNOTATION_SUBTYPES.
 */
function newAnnotationDesignationDefaults(type) {
  switch (type) {
    case ANNOTATION_SUBTYPES.SIGNATURE:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.INITIALS:
      return { width: SHORT_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.DATE_SIGNED:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.DAY_SIGNED:
      return { width: SHORT_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.MONTH_SIGNED:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.YEAR_SIGNED:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.FREE_TEXT:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.CHECKMARK:
    case ANNOTATION_SUBTYPES.RADIO_CHECKMARK:
      return { width: 16, height: 16 };
    case ANNOTATION_SUBTYPES.SEAL:
      return { width: SEAL_DIMENSION, height: SEAL_DIMENSION };
    case ANNOTATION_SUBTYPES.STATE:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.COUNTY:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.NAME:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.NOTARY_ID:
      return { width: DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.COMMISSION_EXPIRY:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.NOTARY_SIGNATURE:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.NOTARY_NAME:
      return { width: LONG_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.SIGNATURE_DATE:
      // Signature designation + padding + date signed designation
      return {
        width: LONG_DESIGNATION_WIDTH + INTER_DESIGNATION_PADDING + 155,
        height: STANDARD_HEIGHT,
      };
    case ANNOTATION_SUBTYPES.DAY_MONTH_YEAR:
      // 3 Text designations + padding between
      return { width: 3 * DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
    case ANNOTATION_SUBTYPES.NOTARY_BULK:
      // width: Text designation + padding + seal designation
      // height: seal designation
      return {
        width: LONG_DESIGNATION_WIDTH + INTER_DESIGNATION_PADDING + SEAL_DIMENSION,
        height: SEAL_DIMENSION,
      };
    default:
      return { width: SHORT_DESIGNATION_WIDTH, height: STANDARD_HEIGHT };
  }
}

/**
 * Get default height & width for a new annotation designation and properly scale its size
 * @param {string} type The type of annotaiton to create on fulfillment.
 * @param {number} scale The scale you want to change the size of designation
 */
function newAnnotationDesignationScaledDefaults(type, scale) {
  const defaultSize = newAnnotationDesignationDefaults(type);
  return scaleWidthHeight(defaultSize, scale);
}

export function designationDefaultPdfPointSize(type) {
  return newAnnotationDesignationScaledDefaults(type, PX_TO_PT);
}

function scaleWidthHeight({ width, height, ...other }, scale) {
  return {
    width: width * scale,
    height: height * scale,
    ...other,
  };
}

export function annotationDesignationTextDefaults(type) {
  switch (type) {
    case ANNOTATION_SUBTYPES.SIGNATURE:
      return "Signature";
    case ANNOTATION_SUBTYPES.NAME:
      return "Full name";
    case ANNOTATION_SUBTYPES.INITIALS:
      return "Initials";
    case ANNOTATION_SUBTYPES.DATE_SIGNED:
      return "Date of Signing";
    case ANNOTATION_SUBTYPES.FREE_TEXT:
      return "Fill In Here";
    case ANNOTATION_SUBTYPES.CHECKMARK:
    case ANNOTATION_SUBTYPES.RADIO_CHECKMARK:
      return "Checkmark";
    case ANNOTATION_SUBTYPES.SEAL:
      return "Notary Seal";
    case ANNOTATION_SUBTYPES.COUNTY:
      return "Notary County";
    case ANNOTATION_SUBTYPES.STATE:
      return "Notary State";
    case ANNOTATION_SUBTYPES.NOTARY_ID:
      return "Notary ID";
    case ANNOTATION_SUBTYPES.COMMISSION_EXPIRY:
      return "Notary Commission Expiry";
    case ANNOTATION_SUBTYPES.NOTARY_SIGNATURE:
      return "Notary Signature";
    case ANNOTATION_SUBTYPES.NOTARY_NAME:
      return "Notary Name";
    case ANNOTATION_SUBTYPES.NOTARY_BULK:
      return "Bulk";
    case ANNOTATION_SUBTYPES.SIGNATURE_DATE:
      return "Signature + Date";
    case ANNOTATION_SUBTYPES.DAY_MONTH_YEAR:
      return "Day + Month + Year";
    case ANNOTATION_SUBTYPES.DAY_SIGNED:
      return "Day of Signing";
    case ANNOTATION_SUBTYPES.YEAR_SIGNED:
      return "Year of Signing";
    case ANNOTATION_SUBTYPES.MONTH_SIGNED:
      return "Month of Signing";
    case ANNOTATION_SUBTYPES.MULTISIGNER_INITIALS:
      // Used in document template tagging to signify that this should only be used if there are mulitple signers
      return "Initials";
    case ANNOTATION_SUBTYPES.DISCLOSURE:
      return "Notary Disclosure";
    default:
      return `Not Implemented (${type})`;
  }
}

export function subDesignationsForCompoundType({ subtype, signerRole }) {
  switch (subtype) {
    case ANNOTATION_SUBTYPES.SIGNATURE_DATE:
      return {
        subDesignations: [
          {
            signerRole,
            type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
            subtype: ANNOTATION_SUBTYPES.SIGNATURE,
            maxHeight: 16,
          },
          {
            signerRole,
            type: ANNOTATION_TYPES.TEXT,
            subtype: ANNOTATION_SUBTYPES.DATE_SIGNED,
            annotationFontSize: "12px",
          },
        ],
        nextDesignationLocations: [
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
        ],
      };
    case ANNOTATION_SUBTYPES.DAY_MONTH_YEAR:
      return {
        subDesignations: [
          {
            signerRole,
            type: ANNOTATION_TYPES.TEXT,
            subtype: ANNOTATION_SUBTYPES.DAY_SIGNED,
            maxHeight: 16,
          },
          {
            signerRole,
            type: ANNOTATION_TYPES.TEXT,
            subtype: ANNOTATION_SUBTYPES.MONTH_SIGNED,
            annotationFontSize: "12px",
          },
          {
            signerRole,
            type: ANNOTATION_TYPES.TEXT,
            subtype: ANNOTATION_SUBTYPES.YEAR_SIGNED,
            annotationFontSize: "12px",
          },
        ],
        nextDesignationLocations: [
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
        ],
      };
    case ANNOTATION_SUBTYPES.NOTARY_BULK:
      return {
        subDesignations: [
          { signerRole, type: ANNOTATION_TYPES.TEXT, subtype: ANNOTATION_SUBTYPES.STATE },
          { signerRole, type: ANNOTATION_TYPES.IMAGE, subtype: ANNOTATION_SUBTYPES.SEAL },
          { signerRole, type: ANNOTATION_TYPES.TEXT, subtype: ANNOTATION_SUBTYPES.COUNTY },
          {
            signerRole,
            type: ANNOTATION_TYPES.VECTOR_GRAPHIC,
            subtype: ANNOTATION_SUBTYPES.NOTARY_SIGNATURE,
          },
          {
            signerRole,
            type: ANNOTATION_TYPES.TEXT,
            subtype: ANNOTATION_SUBTYPES.COMMISSION_EXPIRY,
          },
          { signerRole, type: ANNOTATION_TYPES.TEXT, subtype: ANNOTATION_SUBTYPES.DISCLOSURE },
        ],
        nextDesignationLocations: [
          COMPOUND_ANNOTATION_LOCATIONS.RIGHT,
          COMPOUND_ANNOTATION_LOCATIONS.BELOW_LEFT,
          COMPOUND_ANNOTATION_LOCATIONS.BELOW,
          COMPOUND_ANNOTATION_LOCATIONS.BELOW,
          COMPOUND_ANNOTATION_LOCATIONS.BELOW,
        ],
      };
    default:
      return { subDesignations: [], nextDesignationLocations: [] };
  }
}

const SORT_Y_LINE_PROXIMITY = 4;

/**
 * Sort annotations / designations based on their location on the page,
 * from page to page, top to bottom, left to right.
 *
 * @type {(itemA: { location: { point: { x: number, y: number }, page: number }, size: {height: number } | null, __typename: string }, itemB: { location: { point: { x: number, y: number }, page: number }, size: {height: number} | null, __typename: string }) => number}
 */
export function sortByPageAndLocation(itemA, itemB) {
  if (itemA.__typename === "WhiteboxAnnotation" && itemB.__typename === "WhiteboxAnnotation") {
    return 0;
  }
  if (itemA.__typename === "WhiteboxAnnotation") {
    return -1;
  }
  if (itemB.__typename === "WhiteboxAnnotation") {
    return 1;
  }
  const locationA =
    itemA.__typename === "TextAnnotation"
      ? { ...itemA.location, point: { y: itemA.location.point.y + (itemA.size?.height || 0) } }
      : itemA.location;

  const locationB =
    itemB.__typename === "TextAnnotation"
      ? { ...itemB.location, point: { y: itemB.location.point.y + (itemB.size?.height || 0) } }
      : itemB.location;

  // We subtract the height of the item and round to the nearest whole number
  // so be are sorting by the bottom left corner of the item.
  const adjustedLocationAY = Math.round(locationA.point.y - itemA.size.height);
  const adjustedLocationBY = Math.round(locationB.point.y - itemB.size.height);
  if (locationA.page === locationB.page) {
    // if 2 items are within SORT_Y_LINE_PROXIMITY pixels of each other, we consider them to be on the same 'vertical line',
    // so that we sort and navigate left to right more often.
    if (Math.abs(adjustedLocationAY - adjustedLocationBY) <= SORT_Y_LINE_PROXIMITY) {
      return locationA.point.x - locationB.point.x;
    }
    return adjustedLocationBY - adjustedLocationAY;
  }

  return locationA.page - locationB.page;
}
