import "./index.scss";

import { Fragment, useState, type ReactNode } from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { useTxnDetailsRedesign } from "util/feature_detection";
import AppSubdomains, { CURRENT_PORTAL } from "constants/app_subdomains";
import { DeprecatedExpandableRow } from "common/details/grid/expandable_row";
import FormattedPrice from "common/core/format/formatted_price";
import Link from "common/core/link";
import { ChargeStatuses, Payer } from "graphql_globals";
import { SECTIONS } from "constants/details/summary";
import FormattedChargeState from "common/core/format/formatted_charge_state";
import PayerType from "common/transactions/payer_type";
import WorkflowModal from "common/modals/workflow_modal";
import { LinkStyledButton } from "common/core/link/link_styled_button";
import Button from "common/core/button";
import { DescriptionListItem } from "common/core/description_list";
import { NotaryItemWrapper } from "common/details/meeting/notary_details/notary_item_wrapper";

import type {
  TransactionPaymentStatus as Bundle,
  TransactionPaymentStatus_charges as Charge,
} from "./index_fragment.graphql";

type Props = {
  bundle: Bundle;
  hideIfUnpaid?: boolean;
  adminCapabilities?: boolean;
  canShowPaymentInTransactionForInvoicing?: boolean;
  filterCharges?: (charge: Charge) => boolean;
  renderPriceBreakdown?: (charge: Charge) => ReactNode;
  onUpdate?: () => void;
  customTitle?: ReactNode;
};

function PaymentStatus(props: Props) {
  const {
    bundle,
    hideIfUnpaid,
    adminCapabilities,
    canShowPaymentInTransactionForInvoicing,
    filterCharges,
    renderPriceBreakdown,
    onUpdate,
    customTitle,
  } = props;
  const intl = useIntl();
  const [showPaymentDetails, setShowPaymentDetails] = useState(false);

  const charges = bundle.charges as Charge[];
  const rowTitle = customTitle || intl.formatMessage(SECTIONS.payment);
  const isTxnDetailsRedesign = useTxnDetailsRedesign(AppSubdomains[CURRENT_PORTAL]);

  if (charges.length === 0 && !hideIfUnpaid) {
    return (
      <NotaryItemWrapper
        term={rowTitle}
        definition={
          <span data-automation-id="payment-value">
            <FormattedMessage id="44424883-f0dc-4c6b-8d26-e912d9780a5c" defaultMessage="Not Paid" />
          </span>
        }
      />
    );
  }

  const chargesFiltered = filterCharges ? charges.filter(filterCharges) : charges;
  const chargesVisible = chargesFiltered
    .filter((charge) => {
      const isInvoiced = charge.payerSource === Payer.NOTARIZE;
      const isPaid =
        charge.state === ChargeStatuses.CHARGING || charge.state === ChargeStatuses.PAID;

      if (adminCapabilities) {
        return true;
      }
      const isPaidOrNotRequiredToBePaid = !hideIfUnpaid || isPaid;
      const isNotInvoicedOrAllowedToShowInvoiced =
        canShowPaymentInTransactionForInvoicing || !isInvoiced;
      return isPaidOrNotRequiredToBePaid && isNotInvoicedOrAllowedToShowInvoiced;
    })
    .sort((a, b) => (a.id > b.id ? 1 : -1));

  const renderPaymentBreakdownDetails = (charge: Charge) => {
    return (
      <div className="PaymentStatus--border-row">
        {adminCapabilities && (
          <>
            <div className="PaymentStatus--ids">
              <div>
                <p className="PaymentStatus--header">
                  <FormattedMessage
                    id="6b0ad538-6c6b-4750-8fcb-14374cf9d1c1"
                    defaultMessage="Charge ID"
                  />
                </p>
                <p>{charge.id}</p>
              </div>
              <div>
                <p className="PaymentStatus--header">
                  <FormattedMessage
                    id="9540fbed-132f-45c4-bbc3-0f8d29f6f86e"
                    defaultMessage="Payer ID"
                  />
                </p>
                <p>{charge.payerId}</p>
              </div>
              {charge.providerPaymentId && charge.providerPaymentUrl && (
                <div>
                  <p className="PaymentStatus--header">
                    <FormattedMessage
                      id="2ca7239e-f507-450c-89c4-dba74754c06f"
                      defaultMessage="Provider Charge ID"
                    />
                  </p>
                  <p>
                    <Link underlined={false} href={charge.providerPaymentUrl}>
                      {charge.providerPaymentId}
                    </Link>
                  </p>
                </div>
              )}
              {charge.providerPaymentFailureMessage && (
                <div>
                  <p className="PaymentStatus--header">
                    <FormattedMessage
                      id="0f3d0b7f-908b-40a0-aded-5a329f67b700"
                      defaultMessage="Provider Payment Failure Message"
                    />
                  </p>
                  <div className="PaymentStatus--list--item">
                    <p> {charge.providerPaymentFailureMessage} </p>
                  </div>
                  <p className="PaymentStatus--list--item--secondary">
                    <FormattedMessage
                      id="6bef9c43-a96b-44fd-bb1b-781ac960ecd0"
                      defaultMessage='The above status can be used to determine if the payment method provided is declined by our payment processor. You may confirm that a payment has failed but please do not use the word "fraud" in any customer communications. Please do not disclose any private financial statuses, financial guidance, or recommendations beyond the words used in the status above'
                    />
                  </p>
                </div>
              )}
              {charge.providerInvoiceId && charge.providerInvoiceUrl && (
                <div>
                  <p className="PaymentStatus--header">
                    <FormattedMessage
                      id="b9de5888-d1e3-4158-a368-572ffc5ba8eb"
                      defaultMessage="Provider Invoice ID"
                    />
                  </p>
                  <p>
                    <Link underlined={false} href={charge.providerInvoiceUrl}>
                      {charge.providerInvoiceId}
                    </Link>
                  </p>
                </div>
              )}
            </div>
            <p className="PaymentStatus--header">
              <FormattedMessage
                id="44b412ab-0f0b-4dec-890d-37111199e67b"
                defaultMessage="Payer Type"
              />
            </p>
            <PayerType
              canUpdate={Boolean(bundle.transaction)}
              bundle={bundle}
              payerType={charge.payerSource}
              chargeId={charge.id}
              onUpdate={onUpdate}
            />
            <p className="PaymentStatus--header">
              <FormattedMessage
                id="0df426a5-bebe-49ea-9fa9-68306114e010"
                defaultMessage="Breakdown"
              />
            </p>
          </>
        )}
        {renderPriceBreakdown?.(charge)}
      </div>
    );
  };

  const renderPaymentBreakdownLabel = (idx: number) => {
    return idx === 0 ? rowTitle : "";
  };

  const renderPaymentBreakdownContent = (charge: Charge) => {
    return (
      <span data-automation-id="payment-value">
        <FormattedMessage
          id="a8a7901d-bebb-4f35-bf36-58e04bb26817"
          defaultMessage="{cost} ({chargeState} - {payerName})"
          values={{
            cost: <FormattedPrice cents={charge.cost} />,
            chargeState: <FormattedChargeState charge={charge} />,
            payerName: charge.payerName,
          }}
        />
      </span>
    );
  };

  return (
    <>
      {chargesVisible.map((charge, idx) => {
        return isTxnDetailsRedesign ? (
          <Fragment key={charge.id}>
            <DescriptionListItem
              term={
                <>
                  {renderPaymentBreakdownLabel(idx)}{" "}
                  <LinkStyledButton
                    onClick={() => setShowPaymentDetails(true)}
                    data-automation-id={`charge-details-toggle-${idx}`}
                    disabled={!renderPriceBreakdown && !adminCapabilities}
                  >
                    <FormattedMessage
                      id="fbd6adf1-1d5a-42f6-a003-610a3695ef5e"
                      defaultMessage="View details"
                    />
                  </LinkStyledButton>
                </>
              }
              definition={renderPaymentBreakdownContent(charge)}
            />

            {showPaymentDetails && (
              <WorkflowModal
                title={
                  <FormattedMessage
                    id="49e9d834-6429-4e14-b332-ecbfa7c7a528"
                    defaultMessage="Notary payment details"
                  />
                }
                closeBehavior={{
                  tag: "with-button",
                  onClose: () => setShowPaymentDetails(false),
                }}
                buttons={[
                  <Button
                    key="close"
                    variant="primary"
                    buttonColor="action"
                    onClick={() => setShowPaymentDetails(false)}
                  >
                    <FormattedMessage
                      id="a17418c8-86d8-41eb-963d-24627b05644a"
                      defaultMessage="Close"
                    />
                  </Button>,
                ]}
              >
                {renderPaymentBreakdownDetails(charge)}
              </WorkflowModal>
            )}
          </Fragment>
        ) : (
          <DeprecatedExpandableRow
            toggleAutomationId={`charge-details-toggle-${idx}`}
            key={charge.id}
            title={renderPaymentBreakdownLabel(idx)}
            header={renderPaymentBreakdownContent(charge)}
            disabled={!renderPriceBreakdown && !adminCapabilities}
          >
            {renderPaymentBreakdownDetails(charge)}
          </DeprecatedExpandableRow>
        );
      })}
    </>
  );
}

export default PaymentStatus;
