import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useEffect, useState } from "react";

import { useWatch } from "common/core/form/";
import {
  ConfiguredField,
  ConfiguredStyledSelectField,
  readonlyField,
  requiredField,
  showField,
} from "common/transaction_creation/v3/common";
import type { UseForm } from "common/transaction_creation/v3/form";
import type { Config } from "common/transaction_creation/v3/config";
import Button from "common/core/button";
import { formattedPropertyAddress } from "util/mortgage/transaction";
import { type TitleAgencyOption } from "common/mortgage/transactions/edit/title_collaborator/title_agency_autocomplete_service";
import { TextInput } from "common/core/form/text";
import { AddressInput, validateAddressIsUS } from "common/core/form/address";

import { CONFIGS, FORM_FIELDS } from "..";
import { COLLABORATOR_MESSAGES, type CollaboratorOrganizationInputReturn } from ".";
import Styles from "./organization_selection.module.scss";

type Props = {
  config: Config;
  form: UseForm;
  organizationInteraction: CollaboratorOrganizationInputReturn;
};

const MESSAGES = defineMessages({
  loading: {
    id: "7741a3fc-4ae4-4045-9ade-a8b7d4443576",
    defaultMessage: "Loading...",
  },
});

export function OrganizationSelection({ config, form, organizationInteraction }: Props) {
  const intl = useIntl();
  const fieldNamePrefix: "contacts.0" = `${FORM_FIELDS.contacts}.0`;
  const { control, setValue, unregister } = form;
  const [titleAgencyName, setTitleAgencyName] = useState<string | null>(null);
  const [nonOnboardedTitleAgency, setNonOnboardedTitleAgency] = useState(false);
  const [showOrganizationAddress, setShowOrganizationAddress] = useState(false);

  const collaboratorEmail = useWatch({
    control,
    name: `${fieldNamePrefix}.email`,
  });

  const {
    autocompleteLoading,
    autocompleteOptions,
    handleAutocompleteChange,
    handleTitleAgencyChange,
    selectedTitleAgency,
    setAutocompleteInput,
    setSelectedTitleAgency,
  } = organizationInteraction;

  function resetOrganizationName() {
    setTitleAgencyName(null);
    unregister(`${fieldNamePrefix}.organizationName`);
    unregister(`${fieldNamePrefix}.organizationAddress`);
    handleTitleAgencyChange(null);
    setNonOnboardedTitleAgency(false);
  }

  useEffect(() => {
    // if user is creating a new title agency, and user changes collab email, we must reset the new org
    // as changes to collaborator email may prove collaborator is part of another title agency
    if (nonOnboardedTitleAgency) {
      resetOrganizationName();
    }
  }, [collaboratorEmail]);

  const newOrg = {
    name: titleAgencyName,
    id: "new",
    address: { line1: "", city: "", state: "", country: "US" },
    isSetup: { eligibilityComplete: false },
  };
  const unformattedItems = [...(nonOnboardedTitleAgency ? [newOrg] : []), ...autocompleteOptions];
  const items = unformattedItems.map((option) => ({
    value: option.id,
    label: option.name,
    address: option.address,
  }));

  function addNewOrganization() {
    setValue(`${fieldNamePrefix}.organizationName`, newOrg.name);
    setValue(`${fieldNamePrefix}.organizationAddress`, newOrg.address);
    setSelectedTitleAgency(newOrg);
    setNonOnboardedTitleAgency(true);
  }

  const handleNoResultsText = () => {
    /**
     * Only show if:
     * - the title agency name does not exist and currently there is no selected one OR
     * - the selected title agency name is different than the name on the input
     */
    const noTitleAgencyExists = !autocompleteOptions.length && !selectedTitleAgency;
    const differentTitleAgencyName = selectedTitleAgency?.name !== titleAgencyName;
    const showNoResults = noTitleAgencyExists || differentTitleAgencyName;

    return (
      showNoResults && (
        <>
          <div className={Styles.noResultFound}>
            <FormattedMessage
              id="82aac4b8-f4d5-46da-b9c5-40511ad92826"
              defaultMessage="No results found."
            />
          </div>
          {collaboratorEmail && (
            <div className={Styles.addNewOrganization}>
              <Button
                variant="tertiary"
                buttonColor="action"
                automationId="add-new-organization"
                onClick={addNewOrganization}
                withIcon={{ name: "add-1", placement: "left" }}
              >
                <FormattedMessage
                  id="10605e42-3f77-485f-b3ee-c066cbb60fe8"
                  defaultMessage="Add new organization"
                />
              </Button>
            </div>
          )}
        </>
      )
    );
  };

  const handleInputChange = (titleAgencyName: string) => {
    /**
     *  The back-end only search for search term that is greater than or equal to 3 letters
     *  We should only make change if search term is greater than 2 letters, which is equivalent
     */
    if (titleAgencyName.length > 2) {
      setTitleAgencyName(titleAgencyName);
      handleAutocompleteChange(titleAgencyName);
    }
  };

  const handleOptionChange = (option?: TitleAgencyOption | null) => {
    if (option?.label && option.value) {
      handleTitleAgencyChange(option.value);
      setTitleAgencyName(option.label);
    } else {
      handleTitleAgencyChange(null);
    }
    setNonOnboardedTitleAgency(false);
  };

  function optionRenderer(option: TitleAgencyOption) {
    return (
      <div className={Styles.selectFieldRow}>
        <span className={Styles.selectFieldLabel}>{option.label}</span>
        <span className={Styles.selectFieldAddress}>
          {formattedPropertyAddress(option.address)}
        </span>
      </div>
    );
  }

  return !nonOnboardedTitleAgency ? (
    <ConfiguredStyledSelectField
      config={config}
      configField={CONFIGS.titleAgency}
      form={form}
      name={FORM_FIELDS.titleAgencyId}
      data-automation-id="title-agency-field"
      label={intl.formatMessage(COLLABORATOR_MESSAGES.organizationName)}
      clearable={!nonOnboardedTitleAgency}
      searchable
      useStyledInput
      placeholderAsLabel
      closeOnSelect={false}
      items={items}
      onInputChange={handleInputChange}
      noResultsText={
        autocompleteLoading ? intl.formatMessage(MESSAGES.loading) : handleNoResultsText()
      }
      onClose={() => {
        setAutocompleteInput("");
        setTitleAgencyName(selectedTitleAgency?.name || null);
        form.setValue(FORM_FIELDS.titleAgencyName, selectedTitleAgency?.name || null);
      }}
      optionRenderer={optionRenderer}
      valueRenderer={optionRenderer}
      onOptionChange={handleOptionChange}
      fullWidth
      filterOptions={(options: TitleAgencyOption[]) => {
        // this is to offload responsibility for filtering from react-select to our BE.
        // Typically, we'd rely on ReactSelect's async module, but we need finer control
        // over the options list which Async does not provide.
        if (selectedTitleAgency) {
          return options.filter(({ value }) => value !== selectedTitleAgency.id);
        }
        return options;
      }}
    />
  ) : (
    <>
      <ConfiguredField
        config={config}
        configField={CONFIGS.contactOrganizationName}
        form={form}
        name={`${fieldNamePrefix}.organizationName`}
        label={intl.formatMessage(COLLABORATOR_MESSAGES.organizationName)}
        rowButton={
          <Button
            variant="tertiary"
            buttonColor="action"
            className={Styles.resetOrganizationNameButton}
            automationId="reset-business-name"
            onClick={resetOrganizationName}
            withIcon={{ name: "rotate", placement: "left" }}
          >
            <FormattedMessage id="9a781541-ffb6-4dc6-b402-bb1ea0b931f0" defaultMessage="Reset" />
          </Button>
        }
        as={TextInput}
      />
      {!showOrganizationAddress && (
        <Button
          className={Styles.showOrganizationAddressButton}
          variant="tertiary"
          buttonColor="action"
          onClick={() => setShowOrganizationAddress(true)}
          withIcon={{ name: "add-1", placement: "left" }}
        >
          <FormattedMessage
            id="4cb8dcd6-af33-4dee-bb46-87428fb08ee8"
            defaultMessage="Add address"
          />
        </Button>
      )}
      {showOrganizationAddress && showField(config, CONFIGS.contactOrganizationAddress) && (
        <>
          <AddressInput
            form={form}
            name={`${fieldNamePrefix}.organizationAddress`}
            disabled={readonlyField(config, CONFIGS.contactOrganizationAddress)}
            countryValidation={validateAddressIsUS}
            hideCountry
            required={requiredField(config, CONFIGS.contactOrganizationAddress)}
            showAddressLookup
          />
          <Button
            variant="tertiary"
            buttonColor="action"
            onClick={() => {
              setShowOrganizationAddress(false);
              unregister(`${fieldNamePrefix}.organizationAddress`);
            }}
            withIcon={{ name: "remove", placement: "left" }}
          >
            <FormattedMessage
              id="ff087e80-32d6-48ea-91ff-0758e292d2ed"
              defaultMessage="Remove address"
            />
          </Button>
        </>
      )}
    </>
  );
}
