import { FormattedMessage } from "react-intl";

import { OrganizationMembershipActivationState, UserRole } from "graphql_globals";

export function statusLabel(activationState: OrganizationMembershipActivationState) {
  switch (activationState) {
    case OrganizationMembershipActivationState.ACTIVE:
      return <FormattedMessage id="217c2157-2b96-4397-94bb-9d0419fcb98a" defaultMessage="Active" />;
    case OrganizationMembershipActivationState.PENDING:
      return (
        <FormattedMessage id="2808c68c-ba60-42f2-99d3-80ab6c16a0b9" defaultMessage="Pending" />
      );
    case OrganizationMembershipActivationState.AWAITING_APPROVAL:
      return (
        <FormattedMessage
          id="ddaa50b8-9f2a-4c5a-95e2-fe478fd617e9"
          defaultMessage="Awaiting owner/admin approval"
        />
      );
    case OrganizationMembershipActivationState.DISABLED:
      return (
        <FormattedMessage id="7f1d0a5b-d00a-4480-af87-99f2377a315c" defaultMessage="Deactivated" />
      );
    default:
      throw new Error(`Unexpected activationState ${activationState}`);
  }
}

function isOrgAdmin(role: UserRole) {
  switch (role) {
    case UserRole.ORGANIZATION_MEMBER_OWNER:
    case UserRole.ORGANIZATION_MEMBER_ADMIN:
      return true;
    default:
      return false;
  }
}

export function getUsersRole(roles: ({ id: string; name: string } | null)[] | null) {
  return roles?.find((role) => !role?.name.includes("command_center"));
}

export function includesOrgAdminRole(roles: (UserRole | null)[] | null) {
  return Boolean(roles?.some((role) => role && isOrgAdmin(role)));
}
