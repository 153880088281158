import { memo } from "react";
import { FormattedMessage } from "react-intl";
import classnames from "classnames";

import Link from "common/core/link";

import Styles from "./index.module.scss";

const STEP_CONTENT = Object.freeze({
  PersonalInfo: (
    <FormattedMessage
      id="17822465-af46-43a7-ad76-569e8e5f14ef"
      defaultMessage="Personal Information"
    />
  ),
  CommissionAndRonInfo: (
    <FormattedMessage
      id="80a613bb-e593-4ab7-976e-a9f95f51ca4c"
      defaultMessage="Commission Details"
    />
  ),
  InsuranceDetails: (
    <FormattedMessage
      id="085697e9-c9e9-4406-b994-4b80fa1552db"
      defaultMessage="Insurance Details"
    />
  ),
  NNAInfo: <FormattedMessage id="582b84d3-9112-43e5-bbb0-ea42327039f2" defaultMessage="NNA Info" />,
  DigitalCertificate: (
    <FormattedMessage
      id="e969494f-c874-4aa2-b817-7cb56c841cae"
      defaultMessage="Digital Certificate"
    />
  ),
  SignatureAndSeal: (
    <FormattedMessage
      id="cb19ff58-06aa-4b47-a068-d7db1e46a847"
      defaultMessage="Signature and Seal"
    />
  ),
  StateEducation: (
    <FormattedMessage
      id="ad924c22-b107-4614-8bcf-b9cb11427301"
      defaultMessage="State Required Education"
    />
  ),
  PayoutOptions: (
    <FormattedMessage
      id="7ef9ff7e-f735-46ad-a366-3b3bcacac6d0"
      defaultMessage="Payment Information"
    />
  ),
  TermsOfUse: (
    <FormattedMessage
      id="2e9f8f1f-a8c0-4435-b526-407a6fab906b"
      defaultMessage={"Terms & Conditions"}
    />
  ),
  ProofCertificate: (
    <FormattedMessage
      id="dcdaf0f1-7857-4c89-a3d5-0948523e3356"
      defaultMessage="Proof Certificate"
    />
  ),
  IdentityVerification: (
    <FormattedMessage id="21ba663d-33a7-48a0-a39d-06fbbebf04a1" defaultMessage="Verify Identity" />
  ),
});

type Props = {
  sections: readonly Readonly<{
    id: keyof typeof STEP_CONTENT;
    isActive?: boolean;
    completed?: boolean;
  }>[];
};

function Sidebar({ sections }: Props) {
  return (
    <div className={Styles.profileSidebar}>
      <FormattedMessage
        id="dace5d11-61ee-46a6-afb1-5398cb4f4767"
        defaultMessage="Settings"
        tagName="h1"
      />
      {sections.map(({ id, isActive, completed }) => {
        const cx = classnames({
          [Styles.active]: isActive,
          [Styles.actionRequired]: !completed,
        });
        return (
          <p key={id} className={cx}>
            <Link
              className={Styles.link}
              automationId={`${id}-tab`}
              to={`/settings/notary/profile/${id}`}
              underlined={false}
            >
              {STEP_CONTENT[id]}
            </Link>
          </p>
        );
      })}
    </div>
  );
}

export default memo(Sidebar);
