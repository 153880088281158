import { type ReactNode } from "react";
import classnames from "classnames";

import { Substyle } from "common/core/typography";

import Styles from "./index.module.scss";

type DescriptionListProps = {
  children: ReactNode;
  direction?: "horizontal" | "vertical";
  itemGap?: "small" | "defaultGap" | "large" | "extraLarge";
  className?: string;
};

type DescriptionListItemProps = {
  term: ReactNode;
  definition: ReactNode;
};

export function DescriptionList({
  children,
  itemGap = "defaultGap",
  direction = "horizontal",
  className,
}: DescriptionListProps) {
  const cx = classnames(Styles.descriptionList, Styles[direction], Styles[itemGap], className);

  return <dl className={cx}>{children}</dl>;
}

export function DescriptionListItem({ term, definition }: DescriptionListItemProps) {
  return (
    <div className={Styles.descriptionListItem}>
      <dt>
        <Substyle size="small" textColor="subtle">
          {term}
        </Substyle>
      </dt>
      <dd>
        <Substyle size="defaultSize">{definition}</Substyle>
      </dd>
    </div>
  );
}
