import { useCallback, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import classnames from "classnames";

import { Heading } from "common/core/typography";
import { IconButton } from "common/core/button/icon_button";
import { format } from "common/core/format/date";

import { Frame } from "./frame";
import type { DeepfakeFrameViewerMeetingAnalysisResult } from "./index.fragment.graphql";
import Styles from "./index.module.scss";

const MESSAGES = defineMessages({
  previousLabel: {
    id: "e9ec64be-5dfc-4b5a-804b-532b08ebe0f2",
    defaultMessage: "Previous frame",
  },
  nextLabel: {
    id: "b0a67f8a-111b-4145-92f2-f26140c36101",
    defaultMessage: "Next frame",
  },
  thumbnailAlt: {
    id: "a389f709-0c97-4aa7-876e-91cdbd1a1d4d",
    defaultMessage: "Frame thumbnail {frameNumber}",
  },
});

type FrameViewerProps = { result: DeepfakeFrameViewerMeetingAnalysisResult };
type FrameSelectorProps = {
  frames: FrameViewerProps["result"]["frames"];
  frameIndex: number;
  setFrameIndex: (frameIndex: number) => void;
};

function FrameSelector({ frames, frameIndex, setFrameIndex }: FrameSelectorProps) {
  const intl = useIntl();
  const thumnailContainerRef = useRef<HTMLDivElement | null>(null);
  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);
  const currentFrame = frames[frameIndex];

  const handleThumbnailClick = (index: number) => {
    setFrameIndex(index);
  };

  const handlePreviousClick = useCallback(() => {
    if (frameIndex > 0) {
      setFrameIndex(frameIndex - 1);
    }
  }, [frameIndex]);

  const handleNextClick = useCallback(() => {
    if (frameIndex < frames.length - 1) {
      setFrameIndex(frameIndex + 1);
    }
  }, [frameIndex]);

  useEffect(() => {
    const parent = thumnailContainerRef.current;
    const target = thumbnailRefs.current[frameIndex];

    if (parent && target) {
      parent.scrollTo({
        left: target.offsetLeft - parent.offsetLeft,
        behavior: "smooth",
      });
    }
  }, [frameIndex]);

  return (
    <div className={Styles.frameSelector}>
      <div className={Styles.frames} ref={thumnailContainerRef}>
        {frames.map((frame, index) => (
          <div
            key={`frame-${index}`}
            className={classnames(frameIndex === index && Styles.frameBorder)}
            ref={(el) => (thumbnailRefs.current[index] = el)}
            onClick={() => handleThumbnailClick(index)}
          >
            <img
              src={frame.url}
              alt={intl.formatMessage(MESSAGES.thumbnailAlt, { frameNumber: index + 1 })}
            />
          </div>
        ))}
      </div>
      <div className={Styles.controls}>
        <IconButton
          buttonColor="dark"
          label={intl.formatMessage(MESSAGES.previousLabel)}
          name="caret-left"
          variant="tertiary"
          onClick={handlePreviousClick}
          disabled={frameIndex === 0}
        />
        <span>{format({ value: currentFrame.timePosition * 1000, formatStyle: "m:ss" })}</span>
        <IconButton
          buttonColor="dark"
          label={intl.formatMessage(MESSAGES.nextLabel)}
          name="caret-right"
          variant="tertiary"
          onClick={handleNextClick}
          disabled={frameIndex === frames.length - 1}
        />
      </div>
    </div>
  );
}

export function FrameViewer({ result: { frames, riskLevel } }: FrameViewerProps) {
  const [frameIndex, setFrameIndex] = useState(0);
  const currentFrame = frames[frameIndex];

  if (frames.length === 0) {
    return null;
  }

  return (
    <div className={Styles.container}>
      <Heading textStyle="headingSix" level="h2">
        <FormattedMessage
          id="5ffdd50a-25ee-4a98-b18d-69ee76b8830e"
          defaultMessage="Meeting frames analyzed ({frameCount})"
          values={{ frameCount: frames.length }}
        />
      </Heading>
      <Frame url={currentFrame.url} frameNumber={frameIndex + 1} riskLevel={riskLevel} />
      <FrameSelector frames={frames} frameIndex={frameIndex} setFrameIndex={setFrameIndex} />
    </div>
  );
}
